// SECTION :: START

.dashboard {
	background-color: #f9f9f9;

	&__wrapper {
		display: flex;
		//min-height: 600px;
		min-height: 100vh;
		padding: 30px;
		@include adaptive(1023) {
			flex-direction: column;
			padding: 0;
		}

		&-left {
			display: flex;
			flex-direction: column;
			width: 100%;
			max-width: 300px;
			min-height: 100%;
			//max-height: calc(100vh - 60px);
			padding: 50px;
			border-radius: 20px;
			background: linear-gradient(179.92deg, #4097D9 -23.94%, #30C4A0 53.12%, #00B94F 120.74%);
			@include adaptive(1279) {
				max-width: 250px;
				padding: 30px;
			}
			@include adaptive(1023) {
				max-width: 100%;
				height: 80px;
				padding: 15px 20px;
				border-radius: 0;
			}

			&-top {
				display: flex;
				justify-content: center;
				padding-bottom: 50px;
				@include adaptive(1279) {
					padding-bottom: 30px;
				}
				@include adaptive(1023) {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding-bottom: 0;
				}

				.hamburger {
					display: none;
					@include adaptive(1023) {
						display: flex;
					}
				}
			}

			&-middle {
				flex: 1;
				min-height: 1px;
				max-height: 100%;
				padding-top: 50px;
				padding-bottom: 50px;
				@include adaptive(1279) {
					padding-top: 30px;
					padding-bottom: 30px;
				}
				@include adaptive(1023) {
					display: none;
				}
			}

			&-bottom {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 160px;
				padding-top: 50px;
				@include adaptive(1279) {
					padding-top: 30px;
				}
				@include adaptive(1023) {
					display: none;
				}

				&:after {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 1px;
					background-color: rgba(#fff, 0.2);
				}

				a {
					display: flex;
					align-items: center;
					justify-content: center;

					.icon-font,
					span {
						color: #ffffff;
					}

					.icon-font {
						font-size: 22px;

						& + span {
							padding-left: 10px;
						}
					}

					span {
						@extend %fontOpenSans;
						font-weight: bold;
						font-size: 14px;
						line-height: 19px;
						color: #FFFFFF;
					}
				}
			}
		}

		&-right {
			display: flex;
			flex-direction: column;
			flex: 1;
			min-width: 1px;
			max-width: 100%;
			min-height: 100%;
			//max-height: calc(100vh - 60px);
			padding-left: 30px;
			@include adaptive(1023) {
				padding: 20px 15px;
			}
		}

		&-top {
			padding-bottom: 50px;
			@include adaptive(1023) {
				padding-bottom: 30px;
			}
		}

		&-middle {
			flex: 1;
			min-height: 1px;
			max-height: 100%;
			@include adaptive(767) {
				flex: unset;
				max-height: unset;
			}
		}

		&-bottom {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-top: 50px;
			@include adaptive(1023) {
				padding-top: 30px;
			}
		}
	}

	&__header {
		display: flex;
		justify-content: space-between;
		min-height: 60px;
		padding: 10px 20px;
		box-shadow: 0 10px 80px 0 rgba(18, 30, 49, 0.03);
		border-radius: 10px;
		background: #FFFFFF;
		@include adaptive(1023) {
			> div {
				&:nth-of-type(1) {
					width: 100%;
				}
				&:nth-of-type(2) {
					display: none;
				}
			}
		}
		@include adaptive(767) {
			height: 50px;
			padding: 10px 15px;
		}

		> div {
			min-height: 100%;

			&:nth-of-type(2) {
				padding-left: 20px;
			}
		}
	}

	&__nav {
		display: flex;
		flex-direction: column;

		a {
			position: relative;
			display: flex;
			align-items: center;
			padding-left: 30px;

			&:after {
				content: '';
				opacity: 0;
				visibility: hidden;
				transform: translateX(100%);
				position: absolute;
				top: -40px;
				left: 1px;
				width: 250px;
				height: 102px;
				background-image: url('../img/img-dashboard-overlay.svg');
				background-repeat: no-repeat;
				background-position: left center;
				background-size: cover;
				@include transition(opacity, visibility, transform);
				@include adaptive(1279) {
					width: 220px;
					background-size: contain;
				}
			}

			&.is-active {
				&:after {
					opacity: 1;
					visibility: visible;
					transform: translateX(0);
					@include transition(opacity, visibility, transform);
				}

				.icon-font,
				span {
					color: #4097d9;
					@include transition(color);
				}
			}

			&:not(:last-of-type) {
				margin-bottom: 50px;
			}

			.icon-font,
			span {
				z-index: 2;
				position: relative;
				color: #ffffff;
				@include transition(color);
			}

			.icon-font {
				font-size: 20px;

				& + span {
					padding-left: 10px;
				}
			}

			span {
				@extend %fontOpenSans;
				font-weight: 600;
				font-size: 14px;
				line-height: 22px;
			}
		}
	}

	&__user {
		display: flex;
		align-items: center;

		> div {
			&:nth-of-type(1) {
				overflow: hidden;
				position: relative;
				width: 40px;
				height: 40px;
				border: 2px solid #FFFFFF;
				border-radius: 50%;

				img {
					@extend %imageResCoverAbs;
				}
			}
			&:nth-of-type(2) {
				padding-left: 10px;
			}
		}

		p {
			font-weight: bold;
			font-size: 14px;
			line-height: 21px;
			color: #121E31;
		}
	}

	&__indicator {
		display: flex;
		min-height: 100%;

		> div {
			display: flex;
			align-items: center;
			justify-content: center;
			min-height: 100%;
			@include adaptive(767) {
				&:nth-of-type(2),
				&:nth-of-type(3) {
					flex: 1;
					min-width: 1px;
					max-width: 100%;
				}
			}

			&:not(:last-of-type) {
				padding-right: 20px;
				margin-right: 20px;
				border-right: 1px solid rgba(#636B73, 0.2);
				@include adaptive(767) {
					padding-right: 10px;
					margin-right: 10px;
				}
			}
		}

		&-badge {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100px;
			height: 40px;
			background-color: rgba(#10BD6A, 0.1);
			border-radius: 30px;
			@include adaptive(767) {
				width: auto;
				height: 30px;
				padding-left: 15px;
				padding-right: 15px;
			}

			span {
				@extend %fontOpenSans;
				font-weight: 600;
				font-size: 14px;
				line-height: 19px;
				text-align: center;
				color: #10BD6A;
			}
		}

		&-project {
			display: flex;
			align-items: center;

			.icon-font {
				color: #121e31;
				font-size: 12px;

				& + p {
					padding-left: 6px;
				}
			}

			p {
				@extend %fontOpenSans;
				font-size: 14px;
				font-weight: 600;
				line-height: 19px;
				color: #121e31;
				@include adaptive(767) {
					font-size: 12px;
				}

				span {
					color: #4097d9;
				}
			}
		}

		&-email {
			display: flex;
			align-items: center;

			.icon-font {
				color: #121e31;
				font-size: 12px;

				& + span {
					padding-left: 6px;
				}
			}

			span {
				@extend %fontOpenSans;
				font-size: 14px;
				line-height: 19px;
				color: #636B73;
				@include adaptive(767) {
					font-size: 12px;
				}
			}
		}
	}

	&__box {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 202px;
		padding: 20px;
		background: #FFFFFF;
		border-radius: 10px;
		box-shadow: 0 4px 50px 0 rgba(18, 30, 49, 0.05);
		@include transition(background-color, box-shadow);
		@include adaptive(767) {
			height: 80px;
			padding: 10px;
		}

		&:hover {
			background-color: rgba(#4097D9, 0.8);
			box-shadow: 0 4px 50px 0 rgba(18, 30, 49, 0.05);
			@include transition(background-color, box-shadow);

			> div {
				p,
				span {
					color: #ffffff;
					@include transition(color);
				}
			}
		}

		&.dashboard__box--add {
			box-shadow: none;
			border: 1px dashed rgba(#636B73, 0.5);
			background-color: transparent;
			@include adaptive(767) {
				height: 57px;
			}

			> div {
				@include adaptive(767) {
					align-items: center;
					flex-direction: row;
				}

				u {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 50px;
					height: 50px;
					margin-left: auto;
					margin-right: auto;
					margin-bottom: 10px;
					border-radius: 50%;
					background-color: #FFFFFF;
					text-decoration: none;
					@include adaptive(767) {
						width: 34px;
						height: 34px;
						margin-bottom: 0;
						margin-right: 10px;
					}

					.icon-font {
						font-size: 15px;
						color: #262f56;
						@include adaptive(767) {
							font-size: 13px;
						}
					}
				}

				p {
					@extend %fontOpenSans;
					font-weight: 600;
					font-size: 14px;
					line-height: 19px;
					text-align: center;
					color: #33325C;
				}
			}
		}

		> .icon-font {
			z-index: 5;
			position: absolute;
			top: 20px;
			right: 20px;
			font-size: 14px;
			color: #ffffff;
			transform: rotate(-45deg);
		}

		> div {
			display: flex;
			flex-direction: column;
			text-align: center;
			@extend %fontOpenSans;

			p,
			span {
				@include transition(color);
			}

			p {
				font-weight: bold;
				font-size: 18px;
				line-height: 25px;
				color: #33325C;
				@include adaptive(767) {
					font-size: 14px;
					line-height: 19px;
				}
			}

			span {
				font-size: 14px;
				line-height: 19px;
				color: #33325C;
				@include adaptive(767) {
					font-size: 12px;
					line-height: 16px;
				}
			}
		}

		&-wrapper {
			position: relative;
			left: -10px;
			display: flex;
			flex-wrap: wrap;
			//flex: 1;
			width: calc(100% + 10px);
			//min-height: 1px;
			//max-height: 100%;
			@include adaptive(767) {
				left: -8px;
				width: calc(100% + 8px);
			}

			> div {
				width: 100%;
				max-width: calc(100% / 5);
				padding-left: 10px;
				padding-bottom: 10px;
				@include adaptive(1535) {
					max-width: calc(100% / 4);
				}
				@include adaptive(1279) {
					max-width: calc(100% / 3);
				}
				@include adaptive(767) {
					max-width: calc(100% / 2);
					padding-left: 8px;
					padding-bottom: 8px;

					&:nth-of-type(1) {
						max-width: 100%;
					}
				}
			}
		}
	}

	&__pagination {
		display: flex;
		align-items: center;
		justify-content: center;

		a {
			overflow: hidden;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 44px;
			height: 44px;
			border-radius: 50%;
			font-weight: 600;
			font-size: 16px;
			line-height: 22px;
			text-align: center;
			color: #636B73;
			@include adaptive(767) {
				width: 34px;
				height: 34px;
			}

			&:after {
				content: '';
				z-index: 0;
				opacity: 0;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: linear-gradient(180.59deg, #4097D9 -45.56%, #30C4A0 62.2%, #00B94F 156.76%), #EF2127;
			}

			&.is-active {
				color: #ffffff;

				&:after {
					opacity: 1;
				}
			}

			span {
				z-index: 2;
				position: relative;
			}
		}
	}

	&__btn {
		&--prev {}

		&--next {
			.icon-font {
				transform: scaleX(-1);
			}
		}

		.c-btn {
			min-width: 157px;
			height: 57px;
			padding-left: 10px;
			padding-right: 10px;
			border-radius: 10px;
			@include adaptive(767) {
				min-width: 57px;
				max-width: 57px;
			}
		}

		&.is-disabled {
			.c-btn {
				pointer-events: none;
				background-color: transparent;
				border: 1px solid rgba(#636B73, 0.3);

				&:after,
				&:before {
					display: none;
				}

				.icon-font,
				span {
					color: rgba(#636B73, 0.3);
				}
			}
		}

		&:after {
			content: '';
			position: absolute;
		}

		.icon-font {
			z-index: 2;
			position: relative;
			display: none;
			color: #ffffff;
			@include adaptive(767) {
				display: flex;
			}
		}

		span {
			font-weight: 600;
			font-size: 17px;
			line-height: 23px;
			text-align: center;
			@include adaptive(767) {
				display: none;
			}
		}
	}

	&__row {
		display: none;
		flex-direction: column;
		min-height: 100%;

		.c-grid {
			@include adaptive(767) {
				padding-left: 0;
				padding-right: 0;
			}
		}
		
		&-1 {
			justify-content: space-between;
		}
	}

	&__account {
		display: flex;
		width: 100%;
		padding: 20px;
		border-radius: 10px;
		background-color: #F9F9F9;
		@include adaptive(767) {
			flex-direction: column;
		}

		> div {
			&:nth-of-type(1) {
				overflow: hidden;
				position: relative;
				width: 100px;
				height: 100px;
				margin-left: auto;
				margin-right: auto;
				border-radius: 50%;
				border: 2px solid #FFFFFF;

				img {
					@extend %imageResCoverAbs;
				}
			}
			&:nth-of-type(2) {
				display: flex;
				align-items: center;
				flex: 1;
				min-width: 1px;
				max-width: 100%;
				padding-left: 20px;
				margin-left: 20px;
				border-left: 1px solid #E8E8E8;
				@include adaptive(767) {
					padding-left: 0;
					margin-left: 0;
					border-left: none;
					padding-top: 20px;
					margin-top: 20px;
					border-top: 1px solid #E8E8E8;
				}
			}
		}

		&-wrapper {
			display: flex;
			width: 100%;
			max-width: 720px;
			margin-left: auto;
			margin-right: auto;
			padding: 20px;
			box-shadow: 0px 4px 50px rgba(18, 30, 49, 0.05);
			border-radius: 10px;
			background-color: #FFFFFF;
			@include adaptive(767) {
				padding: 10px;
			}
		}

		&-details {
			display: flex;
			flex-wrap: wrap;
			width: 100%;
			@extend %fontOpenSans;

			> div {
				width: 100%;
				max-width: calc(100% / 3);
				@include adaptive(767) {
					max-width: calc(100% / 2);

					&:not(:nth-last-of-type(1)):not(:nth-last-of-type(2))  {
						padding-bottom: 20px;
					}
				}

				&:nth-of-type(1),
				&:nth-of-type(2),
				&:nth-of-type(3) {
					padding-bottom: 20px;
				}
			}

			span {
				display: block;
				padding-bottom: 5px;
				text-transform: uppercase;
				font-weight: 600;
				font-size: 10px;
				line-height: 14px;
				color: #636B73;
			}

			p {
				font-weight: bold;
				font-size: 14px;
				line-height: 19px;
				color: #33325C;
			}
		}
	}

	&__switch {
		width: 100%;
		max-width: 720px;
		padding: 40px 50px;
		margin-left: auto;
		margin-right: auto;
		box-shadow: 0 4px 50px 0 rgba(18, 30, 49, 0.05);
		border-radius: 10px;
		background-color: #FFFFFF;
		@extend %fontOpenSans;
		@include adaptive(767) {
			padding: 30px 20px;
		}

		&-title {
			padding-bottom: 45px;
			font-weight: bold;
			font-size: 24px;
			line-height: 33px;
			color: #121E31;
			@include adaptive(767) {
				padding-bottom: 20px;
				font-size: 20px;
			}
		}
	}
}

// SECTION :: END
