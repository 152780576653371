// SECTION :: START

.sign {
	padding-top: 235px;
	padding-bottom: 100px;
	@include adaptive(1439) {
		padding-top: 200px;
		padding-bottom: 50px;
	}
	@include adaptive(767) {
		padding-top: 120px;
		padding-bottom: 15px;
	}

	&__wrapper {
		width: 100%;
		max-width: 600px;
		margin-left: auto;
		margin-right: auto;
	}

	&__title {
		padding-bottom: 40px;
		font-weight: bold;
		font-size: 48px;
		line-height: 73px;
		text-align: center;
		color: #121E31;
		@include adaptive(1439) {
			font-size: 40px;
			padding-bottom: 20px;
		}
		@include adaptive(767) {
			font-size: 24px;
			line-height: normal;
		}

		&.sign__title--pb0 {
			padding-bottom: 0;
		}
	}

	&__desc {
		padding-bottom: 40px;
		font-size: 16px;
		line-height: 22px;
		text-align: center;
		color: #636B73;
		@include adaptive(1439) {
			padding-bottom: 20px;
		}
		@include adaptive(767) {
			font-size: 14px;
		}
	}
	
	&__form {
		display: flex;
		flex-direction: column;
		
		&-group {
			display: flex;
			justify-content: space-between;
			@include adaptive(767) {
				flex-wrap: wrap;
			}

			&--action {
				align-items: center;
				padding-top: 25px;
			}
			
			> div {
				width: 100%;
				max-width: calc((100% - 10px) / 2);
				@include adaptive(767) {
					max-width: 100%;
				}
			}
		}
		
		&-field {
			position: relative;
			display: flex;
			flex-direction: column;
			padding-bottom: 15px;

			&--action {
				padding-top: 25px;
			}

			label {
				display: flex;
				padding-bottom: 10px;
				@extend %fontOpenSans;
				font-size: 14px;
				line-height: 19px;
				color: #636B73;
			}

			input {
				outline: none;
				position: relative;
				width: 100%;
				height: 57px;
				padding: 0 65px 0 30px;
				border-radius: 10px;
				border: 1px solid #CBDBE3;
				background-color: #FAFAFA;
				@extend %fontOpenSans;
				font-size: 14px;
				line-height: 19px;
				color: #313731;
				@include inputPlaceholder {
					color: rgba(#313731, 0.5);
				}
			}
		}

		&-link {
			@extend %fontOpenSans;
			font-weight: 600;
			font-size: 14px;
			line-height: 19px;
			text-decoration: underline;
			text-align: right;
			color: #4097D9;
		}

		&-info {
			@extend %fontOpenSans;
			text-align: right;
			font-size: 14px;
			line-height: 19px;
			color: #3C3C3C;
			@include adaptive(767) {
				text-align: center;
			}

			a  {
				margin-left: 5px;
				font-weight: 600;
				font-size: 14px;
				line-height: 19px;
				text-decoration: underline;
				text-align: right;
				color: #4097D9;
			}
		}

		&-pass {
			z-index: 5;
			position: absolute;
			display: flex;
			align-items: center;
			justify-content: center;
			top: 29px;
			right: 0;
			width: 65px;
			height: calc(100% - (15px + 29px));
			@include transition(opacity);

			&.is-active {
				opacity: 0.55;
				@include transition(opacity);
			}

			.icon-font {
				font-size: 16px;
				color: #182135;
			}
		}

		&-confirm {
			@extend %fontOpenSans;
			font-weight: 600;
			font-size: 14px;
			line-height: 19px;
			text-align: center;
			color: #4097D9;
		}

		&-back {
			margin-top: 35px;
			@extend %fontOpenSans;
			font-weight: 600;
			font-size: 14px;
			line-height: 19px;
			text-align: center;
			color: rgba(#636B73, 0.8);
		}

		.c-btn {
			width: 100%;
			max-width: 250px;
			@include adaptive(767) {
				max-width: 100%;

				&__wrapper {
					justify-content: center;
				}
			}
		}
	}
}

// SECTION :: END
