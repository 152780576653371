// COMMON :: START

#container {
	z-index: 999;
	position: relative;
	padding-top: 0;
	box-shadow: 0 -8px 30px 0 rgba(#000, 0.1);
	background-color: #ffffff;
	@include adaptive(767) {
		box-shadow: none;
		padding-top: 0;
	}
}

.copyright {
	display: flex;
	justify-content: center;

	&__text {
		@extend %fontOpenSans;
		font-size: 12px;
		line-height: 16px;
		text-align: center;
		color: #FFFFFF;
	}
}

// COMMON :: END
