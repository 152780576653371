// SECTION :: START

.nfound {
	position: relative;

	&__wrapper {
		&-1 {
			position: absolute;
			top: 0;
			left: 0;
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			padding: 30px 50px;
			@include adaptive(1279) {
				padding-left: 20px;
				padding-right: 20px;
			}
			@include adaptive(767) {
				padding-top: 15px;
				padding-bottom: 15px;
			}
		}

		&-2 {
			display: flex;
			align-items: center;
			width: 100%;
			max-width: calc(1440px - 260px);
			min-height: 100vh;
			padding: 140px 30px;
			margin-left: auto;
			margin-right: auto;
			@include adaptive(767) {
				padding-top: 124px;
				padding-bottom: 124px;
			}

			> div {
				width: 100%;
			}
		}
	}

	&__link {
		display: flex;
		font-weight: 600;
		font-size: 14px;
		text-transform: uppercase;
		color: #121E31;

		&:hover {
			text-decoration: underline;
		}
	}

	&__content {
		display: flex;
		align-items: center;
		justify-content: space-between;
		@include adaptive(767) {
			flex-direction: column-reverse;
		}

		> div {
			&:nth-of-type(1) {
				width: 100%;
				max-width: 365px;
				@include adaptive(767) {
					max-width: 100%;
					margin-left: auto;
					margin-right: auto;
				}
			}
			&:nth-of-type(2) {
				padding-left: 50px;
				@include adaptive(767) {
					padding-left: 0;
					padding-bottom: 10px;
				}

				img {
					display: block;
					max-width: 100%;
					height: auto;
					margin-left: auto;
					margin-right: auto;
				}
			}
		}
	}

	&__title {
		padding-bottom: 20px;
		font-weight: bold;
		font-size: 72px;
		line-height: 82px;
		color: #121E31;
		@include adaptive(1535) {
			font-size: 64px;
			line-height: 78px;
		}
		@include adaptive(1365) {
			font-size: 55px;
			line-height: 66px;
		}
		@include adaptive(767) {
			font-size: 36px;
			line-height: 47px;
			text-align: center;
		}
	}

	&__desc {
		padding-bottom: 60px;
		@extend %fontOpenSans;
		font-size: 16px;
		line-height: 22px;
		color: #1D284A;
		@include adaptive(767) {
			padding-bottom: 40px;
			font-size: 13px;
			line-height: 18px;
			text-align: center;
		}
	}

	&__btn {
		display: flex;
		@extend %fontOpenSans;
		font-weight: 600;
		font-size: 16px;
		line-height: 22px;
		color: #4097D9;
		@include adaptive(767) {
			justify-content: center;
			text-align: center;
			font-size: 14px;
			line-height: 19px;
		}

		&:hover {
			text-decoration: underline;
		}
	}
}

// SECTION :: END
