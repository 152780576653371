///
/// ADDITIONALLY
/// ===============
$maxWidth: 1170px;
$paddingLeft: 15px;
$paddingRight: $paddingLeft;

%grid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: $paddingRight;
  padding-left: $paddingLeft;
}


///
/// MAIN
/// ===============
.c-grid {
  @extend %grid;
  @if($maxWidth) {
    max-width: $maxWidth;
  }

  &-fluid {
    @extend %grid;
    max-width: 100%;
  }
}
