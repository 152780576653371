// SECTION :: START

.approach {
	//z-index: 999;
	position: relative;
	display: flex;
	align-items: center;
	//min-height: 1531px;
	padding-top: 100px;
	padding-bottom: 30px;
	@include adaptive(767) {
		padding-top: 80px;
		padding-bottom: 0;
	}

	&__title {
		padding-bottom: 70px;
		@include adaptive(767) {
			padding-bottom: 40px;
		}
	}

	&__subtitle {
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 100px;
		border-radius: 6px;
		background-color: rgba(#4097D9, 0.05);
		@include adaptive(1023) {
			min-height: 75px;
		}
		@include adaptive(767) {
			min-height: unset;
			padding: 20px 35px;
		}

		p {
			font-size: 20px;
			line-height: 30px;
			text-align: center;
			color: #4097D9;
			@include adaptive(1023) {
				font-size: 16px;
				line-height: normal;
			}
			@include adaptive(767) {
				font-size: 14px;
				line-height: 21px;
			}
		}
	}

	&__tab {
		position: relative;
		display: flex;
		align-items: center;
		flex: 1;
		min-width: 1px;
		max-width: 100%;
		padding-left: 60px;
		@include adaptive(1279) {
			padding-left: 30px;
		}
		@include adaptive(1023) {
			padding-left: 15px;
			padding-right: 15px;
		}
		@include adaptive(767) {
			flex-direction: column;
		}

		&:after {
			content: '';
			z-index: 5;
			opacity: 0;
			visibility: hidden;
			transform: scaleY(0);
			position: absolute;
			left: 0;
			bottom: -30px;
			width: 100%;
			height: 4px;
			background-color: #4097D9;
			@include transition(opacity, visibility, transform 0.45s);
		}

		&:hover,
		&.is-active {
			> div {
				&:nth-of-type(1) {
					background-color: rgba(#4097D9, 0.1);
					@include transition(background-color);

					.icon-font {
						color: #4097d9;
						@include transition(color);
					}
				}

				span {
					color: #4097d9;
					@include transition(color);
				}

				p {
					color: #121e31;
					@include transition(color);
				}
			}
		}

		&.is-active {
			&:after {
				opacity: 1;
				visibility: visible;
				transform: scaleY(1);
				@include transition(opacity, visibility, transform 0.45s);
			}
		}

		> div {
			&:nth-of-type(1) {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 50px;
				height: 50px;
				border-radius: 10px;
				background-color: rgba(#C4CFDD, 0.15);
				@include transition(background-color);
				@include adaptive(767) {
					width: 40px;
					height: 40px;
				}

				.icon-font {
					color: #6E7E92;
					font-size: 15px;
					@include transition(color);
					@include adaptive(767) {
						font-size: 12px;
					}

					&.icon-rocket {
						font-size: 22px;
						@include adaptive(767) {
							font-size: 18px;
						}
					}
				}
			}

			&:nth-of-type(2) {
				flex: 1;
				min-width: 1px;
				max-width: 100%;
				padding-left: 20px;
				@include adaptive(767) {
					padding-left: 0;
				}
			}

			span {
				font-family: 'Open Sans', sans-serif;
				font-size: 14px;
				line-height: 19px;
				color: #6E7E92;
				@include transition(color);
				@include adaptive(767) {
					display: block;
					padding-top: 20px;
					padding-bottom: 8px;
					font-size: 10px;
					text-align: center;
				}
			}

			p {
				font-family: 'Open Sans', sans-serif;
				font-weight: 600;
				font-size: 20px;
				line-height: 27px;
				color: #6E7E92;
				@include transition(color);
				@include adaptive(1365) {
					font-size: 18px;
				}
				@include adaptive(767) {
					text-align: center;
					font-size: 14px;
					line-height: 19px;
				}
			}
		}

		&-wrapper {
			display: flex;
			flex-direction: column;
		}

		&-header {
			position: relative;
			display: flex;
			align-items: center;
			padding-bottom: 60px;

			&:after {
				content: '';
				position: absolute;
				left: 0;
				bottom: 31px;
				width: 100%;
				height: 2px;
				background-color: #DFF1FF;
			}
		}

		&-body {
			padding-bottom: 30px;
		}

		&-footer {
			display: flex;
			flex-direction: column;
		}

		&-content {
			display: none;
		}

		&-section {
			display: flex;
			padding: 20px;
			border: 2px solid #DFF1FF;
			border-radius: 10px;
			background-color: rgba(255, 255, 255, 0.3);
			backdrop-filter: blur(4px);

			> div {
				width: 100%;
				padding: 40px;
				box-shadow: 0 4px 70px rgba(192, 218, 238, 0.5);
				border-radius: 10px;
				background-color: #FFFFFF;
				@include adaptive(1023) {
					padding: 20px;
				}
			}

			&:not(:last-of-type) {
				margin-bottom: 38px;
			}
		}

		&-heading {
			display: flex;
			align-items: center;
			padding-bottom: 20px;

			> div {
				&:nth-of-type(1) {
					display: flex;
					align-items: center;
					flex: 1;
					min-width: 1px;
					max-width: 100%;

					> div {
						&:nth-of-type(1) {
							display: flex;
							align-items: center;
							justify-content: center;
							width: 60px;
							height: 60px;
							border-radius: 8px;
							background-color: rgba(64, 151, 217, 0.1);
							@include transition(background-color);

							.icon-font {
								font-size: 20px;
								color: #4097d9;
								@include transition(color);

								&.icon-pen {
									transform: scaleX(-1);
								}
							}
						}
						&:nth-of-type(2) {
							flex: 1;
							min-width: 1px;
							max-width: 100%;
							padding-left: 24px;
						}
					}
				}
				&:nth-of-type(2) {}
			}

			p {
				font-weight: 600;
				font-size: 28px;
				line-height: 42px;
				color: #121E31;
				@include adaptive(1365) {
					font-size: 24px;
					line-height: 38px;
				}
				@include adaptive(767) {
					font-size: 18px;
					line-height: 27px;
				}
			}

			span {
				font-size: 18px;
				line-height: 27px;
				color: #4097D9;
				@include adaptive(1023) {
					font-size: 16px;
				}
				@include adaptive(767) {
					font-size: 14px;
					line-height: 21px;
				}
			}
		}

		&-desc {
			> p {
				//padding-bottom: 15px;
				font-size: 16px;
				line-height: 24px;
				color: #121E31;
				@include adaptive(767) {
					font-size: 13px;
					line-height: 20px;
				}
			}

			ul {
				display: flex;
				flex-direction: column;

				li {
					display: flex;
					align-items: center;

					&:not(:last-of-type) {
						padding-bottom: 15px;
					}

					> div {
						&:nth-of-type(1) {
							width: 40px;

							.icon-font {
								font-size: 11px;
								color: #4097d9;
							}
						}
						&:nth-of-type(2) {
							flex: 1;
							min-width: 1px;
							max-width: 100%;
						}
					}
				}

				span {
					font-family: 'Open Sans', sans-serif;
					font-size: 14px;
					line-height: 19px;
					color: #636B73;
					@include adaptive(767) {
						font-size: 13px;
						line-height: 18px;
					}
				}
			}
		}
	}

	&__collapse {
		display: flex;
		flex-direction: column;

		&-head {
			cursor: pointer;

			&:hover,
			&.is-active {
				.approach__tab-heading {
					> div:nth-of-type(1) {
						> div:nth-of-type(1) {
							background-color: rgba(64, 151, 217, 1);
							@include transition(background-color);

							.icon-font {
								color: #ffffff;
								@include transition(color);
							}
						}
					}
				}
			}
		}

		&-body {
			display: none;

			> div {
				padding-top: 15px;
			}
		}
	}
}

// SECTION :: END
