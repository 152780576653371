// LOGOTYPE :: START

.logo {
	display: flex;

	&.logo--header {
		.logo {
			&__img {
				@include transition(opacity);

				&--mobile {
					opacity: 0;
					position: absolute;
				}
			}
		}
	}

	&.logo--dashboard {
		.logo {
			&__btn {
				@include adaptive(1023) {
					max-width: 42px;
				}

				img {
					display: block;
					max-width: 100%;
					height: auto;
				}
			}
		}
	}

	&.logo--footer {}

	&__btn {
		position: relative;
		display: flex;
		align-items: flex-start;
	}

	&__img {
		display: block;
		max-width: 100%;
		height: auto;
		max-height: 100%;
		@include adaptive(1439) {
			width: 100%;
			max-width: 50px;
		}
		@include adaptive(767) {
			max-width: 42px;
		}
	}
}

// LOGOTYPE :: END
