// FOOTER :: START

.footer {
	z-index: 999;
	position: relative;
	padding-top: 50px;
	padding-bottom: 50px;
	background-color: #ffffff;
	@include adaptive(1279) {
		padding-bottom: 0;
	}
	
	&__bg {
		z-index: 0;
		position: absolute;
		bottom: 50px;
		left: 0;
		right: 0;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		max-width: calc(100% - 100px);
		height: 600px;
		border-radius: 10px;
		background-color: #0A0A0A;
		@include adaptive(1279) {
			bottom: 0;
			max-width: 100%;
			border-radius: 10px 10px 0 0;
		}
		@include adaptive(767) {
			height: calc(100% - 350px);
			border-radius: 0;
		}
	}

	&__wrapper {
		z-index: 2;
		position: relative;

		&-1 {
			overflow: hidden;
			padding: 50px 60px;
			border-radius: 10px;
			background-color: #4097D9;
			@include adaptive(1279) {
				padding: 30px;
			}
			@include adaptive(767) {
				padding: 30px 20px;
			}
		}

		&-2 {
			display: flex;
			padding-top: 50px;
			margin-bottom: 50px;
			padding-bottom: 50px;
			border-bottom: 1px solid rgba(#fff, 0.1);
			@include adaptive(767) {
				flex-wrap: wrap;
			}

			> div {
				&:nth-of-type(1) {
					display: flex;
					width: 100%;
					max-width: 680px;
					@include adaptive(1279) {
						max-width: 50%;
						flex-direction: column;
					}
					@include adaptive(767) {
						max-width: 100%;
					}

					> div {
						&:nth-of-type(1) {
							width: 100%;
							max-width: 230px;
							@include adaptive(1279) {
								max-width: 100%;
							}
						}
						&:nth-of-type(2) {
							position: relative;
							flex: 1;
							min-width: 1px;
							max-width: 100%;
							padding-left: 20px;
							margin-left: 20px;
							@include adaptive(1279) {
								padding-left: 0;
								margin-left: 0;
							}
							@include adaptive(767) {
								margin-top: 20px;
								padding-top: 20px;
								padding-bottom: 50px;
							}

							&:after {
								content: '';
								position: absolute;
								top: 50%;
								transform: translateY(-50%);
								left: 0;
								width: 1px;
								height: 30px;
								background: rgba(#FFFFFF, 0.2);
								@include adaptive(1279) {
									display: none;
								}
								@include adaptive(767) {
									top: 0;
									right: 0;
									transform: translateY(0);
									display: block;
									width: 100%;
									max-width: 200px;
									height: 1px;
									margin-left: auto;
									margin-right: auto;
								}
							}

							.footer__heading {
								opacity: 0;
								@include adaptive(767) {
									display: none;
								}
							}
						}
					}
				}
				&:nth-of-type(2) {
					display: flex;
					justify-content: space-between;
					flex: 1;
					min-width: 1px;
					max-width: 100%;
					padding-left: 90px;
					@include adaptive(767) {
						padding-left: 0;
					}

					> div {
						white-space: nowrap;
						@include adaptive(767) {
							white-space: normal;
							flex: 1;
							min-width: 1px;
							max-width: 100%;
						}
					}
				}
			}
		}

		&-3 {
			display: flex;
			align-items: center;
			@include adaptive(767) {
				align-items: flex-start;
				flex-wrap: wrap;
			}

			> div {
				&:nth-of-type(1) {
					@include adaptive(767) {
						display: none;
					}
				}
				&:nth-of-type(2) {
					flex: 1;
					min-width: 1px;
					max-width: 100%;
					padding-left: 30px;
					padding-right: 55px;
					@include adaptive(767) {
						padding-left: 0;
						padding-right: 0;
						padding-bottom: 50px;
					}
				}
				&:nth-of-type(3) {
					@include adaptive(767) {
						width: 100%;
					}
				}
			}

			.c-btn {
				@include adaptive(767) {
					width: 100%;
					max-width: calc((100% - 15px) / 2);

					&__wrapper {
						justify-content: space-between;
					}
				}

				&:not(:last-of-type) {
					margin-right: 11px;
				}
			}
		}

		&-4 {
			padding-top: 100px;
			padding-bottom: 35px;
			@include adaptive(767) {
				padding-top: 50px;
				padding-bottom: 50px;
			}
		}

		&-bg {
			z-index: 0;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-image: url('../img/img-footer-pattern.svg');
			background-repeat: no-repeat;
			background-position: center top;
			background-size: cover;
		}
	}

	&__form {
		z-index: 2;
		position: relative;

		&-title {
			padding-bottom: 60px;
			font-weight: 600;
			font-size: 20px;
			line-height: 30px;
			color: #FFFFFF;
			@include adaptive(767) {
				padding-bottom: 30px;
				font-size: 16px;
				line-height: 24px;
			}
		}

		&-group {
			display: flex;
			justify-content: space-between;
			@include adaptive(1023) {
				flex-wrap: wrap;
			}

			> div {
				width: 100%;
				max-width: calc((100% - 80px) / 3);
				padding-bottom: 50px;
				@include adaptive(1023) {
					max-width: calc((100% - 30px) / 3);
				}
				@include adaptive(767) {
					max-width: 100%;
					padding-bottom: 30px;
				}
			}
		}

		&-field {
			display: flex;
			width: 100%;

			&.footer__form-field--btn {
				align-items: center;
				justify-content: space-between;
				padding-top: 15px;
				@include adaptive(767) {
					flex-direction: column;
					justify-content: center;
				}
			}

			.c-btn {
				min-width: 140px;
				@include adaptive(767) {
					width: 100%;
					max-width: 100%;

					&__wrapper {
						width: 100%;
					}
				}
			}

			label {
				display: flex;
				flex-direction: column;
				width: 100%;

				> span {
					display: block;
					font-family: 'Open Sans', sans-serif;
					font-weight: 800;
					font-size: 10px;
					line-height: 14px;
					text-transform: uppercase;
					color: #FFFFFF;
				}
				
				input,
				textarea {
					outline: none;
					position: relative;
					display: flex;
					width: 100%;
					height: 35px;
					padding: 0;
					border: none;
					border-bottom: 2px solid transparent;
					box-shadow: none;
					border-radius: 0;
					background-color: transparent;
					font-family: 'Open Sans', sans-serif;
					font-size: 14px;
					line-height: 19px;
					color: #ffffff;
					@include transition(border-color);
					@include inputPlaceholder {
						font-family: 'Open Sans', sans-serif;
						font-size: 14px;
						line-height: 19px;
						color: rgba(#FFFFFF, 0.7);
					}

					&:focus {
						border-color: #fff;
						@include transition(border-color);
					}
				}

				textarea {
					resize: none;
				}
			}
		}

		&-message {
			@include adaptive(767) {
				padding-bottom: 20px;
			}

			> div {
				display: none;
				align-items: center;

				&.error {
					span {
						background-color: #ff4b55;
					}
				}
				&.done {
					span {
						background-color: #00b94f;
					}
				}

				&.is-active {
					display: flex;
				}

				span {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 20px;
					height: 20px;
					border-radius: 50%;

					& + p {
						padding-left: 10px;
					}

					.icon-font {
						color: #ffffff;
						font-size: 8px;
					}
				}

				p {
					@extend %fontOpenSans;
					font-weight: bold;
					font-size: 12px;
					line-height: 16px;
					color: #FFFFFF;
				}
			}
		}
	}

	&__title {
		padding-bottom: 10px;
		font-weight: 600;
		font-size: 20px;
		line-height: 30px;
		color: #FFFFFF;
	}

	&__desc {
		font-family: 'Open Sans', sans-serif;
		font-size: 14px;
		line-height: 24px;
		color: #FFFFFF;
	}

	&__heading {
		padding-bottom: 10px;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: #FFFFFF;
	}

	&__link {
		font-size: 14px;
		line-height: 19px;
		color: rgba(#FFFFFF, 0.8);
	}

	&__address {
		font-size: 14px;
		line-height: 24px;
		color: rgba(#FFFFFF, 0.8);

		img {
			display: inline-block;
			vertical-align: middle;
		}
	}
}

// FOOTER :: END
