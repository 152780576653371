// BUTTON :: START

.c-btn {
  outline: none;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 50px;
  padding-left: 32px;
  padding-right: 32px;
  box-shadow: none;
  border-radius: 2rem;
  backface-visibility: hidden;
  transform: translateX(-1);

  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;

    &--center {
      justify-content: center;
    }
    &--end {
      justify-content: flex-end;
    }
  }

  &__border {
    border: 1px solid #fff;
    background-color: transparent;
    @include transition(background-color);

    &:hover {
      background-color: #fff;
      @include transition(background-color);

      span {
        color: #0A0A0A;
        @include transition(color);
      }
    }

    &.c-btn__border--dark {
      border-color: #0A0A0A;
      background-color: transparent;
      @include transition(background-color);

      &:hover {
        background-color: #0a0a0a;
        @include transition(background-color);

        span {
          color: #ffffff;
          @include transition(color);
        }
      }

      span {
        color: #0a0a0a;
        @include transition(color);
      }
    }
  }

  &__bg {
    overflow: hidden;
    //background-color: #00B94F;

    &:hover {
      transform: translateX(-1);
      backface-visibility: hidden;

      &:before,
      &:after {
        @include transition(opacity, visibility);
      }

      &:before {
        opacity: 1;
        visibility: visible;
      }

      &:after {
        opacity: 0;
        visibility: hidden;
      }
    }

    &.c-btn__bg--white {
      background-color: #ffffff;

      &:before,
      &:after {
        display: none;
      }

      span {
        color: #00b94f;
      }
    }

    &:before,
    &:after {
      content: '';
      z-index: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 2rem;
      transform: translateZ(-1);
      backface-visibility: hidden;
      @include transition(opacity, visibility);
    }

    &:before {
      background: linear-gradient(177.56deg, #3396E1 -48.98%, #2AB492 68.87%, #038A3D 180.55%);
      box-shadow: 0px 6px 20px rgba(40, 223, 153, 0.2), 0px 20px 30px rgba(40, 223, 153, 0.2);
    }

    &:after {
      background: linear-gradient(177.56deg, #4097D9 -48.98%, #30C4A0 68.87%, #00B94F 180.55%);
    }
  }

  span {
    z-index: 2;
    position: relative;
    @extend %fontOpenSans;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
  }
}

// BUTTON :: END
