// SECTION :: START

.main {
	overflow: hidden;
	position: relative;
	padding-bottom: 200px;
	@include adaptive(767) {
		z-index: -9999;
		opacity: 0;
		visibility: hidden;
		position: absolute;
		left: -999999px;
		height: 0;
	}

	[main-content-js] {
		opacity: 0;
	}

	&__bg {
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		&-grid {
			> div {
				position: fixed;
				top: 0;
				right: 10%;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 768px;
				height: 100vh;
				margin-left: auto;
				@include adaptive(1599) {
					right: 5%;
				}
				@include adaptive(1439) {
					right: 2%;
				}
				@include adaptive(1279) {
					width: 568px;
				}
				@include adaptive(1023) {
					right: 1%;
					width: 428px;
				}

				> div {
					opacity: 0;
					position: absolute;
					top: 0;
					left: 0;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 100%;
					height: 100%;
					margin-left: auto;
					margin-right: auto;
					padding-left: 50px;
					padding-right: 50px;
					@include adaptive(1439) {
						padding-left: 25px;
						padding-right: 25px;
					}
					@include adaptive(1279) {
						padding-left: 0;
						padding-right: 0;
					}

					&#video2Wrapper {
						//opacity: 0;

						> div {
							position: absolute;
							left: 0;
							right: 0;
							width: 100%;
							margin-left: auto;
							margin-right: auto;
							padding: 0 50px;
							transform: translate(11px, 35px);
							@include adaptive(1279) {
								left: -35px;
								right: -35px;
								width: calc(100% + 70px);
								padding: 0 25px;
								transform: translate(11px, 33px);
							}
						}

						video {
							width: 100%;
						}
					}
					&#video3Wrapper {
						padding-bottom: 75px;
					}

					&#video1Wrapper {
						opacity: 1;

						video {
							z-index: 0;
							position: relative;
						}

						#video1WrapperBorder {
							position: relative;
							display: flex;
							align-items: center;
							justify-content: center;

							i {
								z-index: 5;
								overflow: hidden;
								position: absolute;
								display: flex;
								align-items: center;
								justify-content: center;
								transform: translate(10px, 30px) !important;
								@include adaptive(1279) {
									transform: translate(9px, 27px) !important;
								}
								@include adaptive(1023) {
									transform: translate(6px, 20px) !important;
								}

								video {
									position: fixed;
									width: 100%;
									max-width: 650px;
									margin-left: auto;
									transform-origin: center;
								}
							}
						}
					}

					&#video2Wrapper,
					&#video3Wrapper {
						opacity: 1;
					}

					&#mainSVG1 {
						z-index: 1;
						opacity: 1;
					}

					&#mainSVG2 {
						z-index: 2;
						opacity: 1;
					}

					&#mainSVG3 {
						z-index: 4;
						opacity: 1;
					}

					&#mainSVG4 {
						opacity: 1;
					}

					video,
					img,
					svg {
						display: block;
						width: 650px;
					}
				}
			}
		}
	}

	&__wrapper {
		//z-index: 2;
		//position: relative;
		display: flex;
		height: 2200px;

		&-1 {
			.main__wrapper-left {
				padding-top: 50px;
			}
		}
		&-5 {
			.main__wrapper-left {
				padding-bottom: 20px;
			}
		}

		&:last-of-type {
			.main__wrapper {
				&-left {
					transform: translateY(0);
				}
			}
		}

		&-left {
			position: fixed;
			top: 0;
			left: 0;
			display: flex;
			align-items: center;
			width: 100%;
			max-width: 50%;
			height: 100%;
			backface-visibility: hidden;
			transform-origin: center;
			transform: translateY(100%);
			@include adaptive(1023) {
				max-width: 45%;
			}
		}

		&-left {
			> div {
				width: 100%;
				max-width: 465px;
			}
		}
	}

	&__title {
		font-weight: bold;
		font-size: 72px;
		line-height: 109px;
		color: #121E31;
		@include adaptive(1439) {
			font-size: 62px;
			line-height: 99px;
		}
		@include adaptive(1279) {
			font-size: 52px;
			line-height: 89px;
		}
		@include adaptive(1023) {
			font-size: 48px;
			line-height: 82px;
		}
		@include adaptive(767) {
			font-size: 30px;
			line-height: 40px;
			text-align: center;
		}
	}

	&__subtitle {
		padding-bottom: 20px;
		font-weight: 600;
		font-size: 36px;
		line-height: 54px;
		color: #121E31;
		@include adaptive(1439) {
			font-size: 33px;
			line-height: 44px;
		}
		@include adaptive(1279) {
			font-size: 30px;
			line-height: 40px;
		}
		@include adaptive(1023) {
			font-size: 26px;
			line-height: 36px;
		}
		@include adaptive(767) {
			padding-bottom: 10px;
			font-size: 18px;
			line-height: 26px;
			text-align: center;
		}
	}

	&__desc {
		padding-bottom: 60px;
		font-family: 'Open Sans', sans-serif;
		font-size: 20px;
		line-height: 30px;
		color: #636B73;
		@include adaptive(1439) {
			font-size: 18px;
			line-height: 28px;
		}
		@include adaptive(1023) {
			font-size: 16px;
			line-height: 26px;
		}
		@include adaptive(767) {
			width: 100%;
			max-width: 265px;
			padding-bottom: 20px;
			margin-left: auto;
			margin-right: auto;
			font-size: 14px;
			line-height: 22px;
			text-align: center;
		}
	}

	&__heading {
		font-weight: bold;
		font-size: 48px;
		line-height: 73px;
		color: #121E31;
		@include adaptive(1439) {
			font-size: 44px;
			line-height: 63px;
		}
		@include adaptive(1279) {
			font-size: 40px;
			line-height: 60px;
		}
		@include adaptive(1023) {
			font-size: 35px;
			line-height: 52px;
		}
		@include adaptive(767) {
			font-size: 26px;
			line-height: 40px;
		}
	}

	&__label {
		padding-bottom: 20px;
		font-weight: 600;
		font-size: 24px;
		line-height: 36px;
		color: #121E31;
		@include adaptive(1439) {
			font-size: 22px;
			line-height: 32px;
		}
		@include adaptive(767) {
			font-size: 18px;
			line-height: 26px;
		}
	}

	.c-btn {
		min-width: 200px;
		height: 69px;
		@include adaptive(1439) {
			height: 63px;
		}
		@include adaptive(1279) {
			height: 60px;
		}
		@include adaptive(1023) {
			min-width: 175px;
			height: 57px;
		}

		span {
			font-weight: bold;
			font-size: 17px;
			@include adaptive(1023) {
				font-size: 15px;
			}
		}
	}
}

.submain {
	display: none;
	padding-top: 80px;
	padding-bottom: 50px;
	@include adaptive(767) {
		display: flex;
	}

	&__wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 50px;
		padding-bottom: 50px;
		@media screen and (orientation: landscape) {
			flex-direction: row;
			padding-top: 30px;

			.submain__wrapper-left,
			.submain__wrapper-right {
				flex: 1;
				min-width: 1px;
				max-width: 100%;

				.c-btn {
					&__wrapper {
						padding-bottom: 0;
					}
				}
			}

			.submain__wrapper-left {
				padding-right: 15px;
				padding-bottom: 0;
			}
		}

		&.submain__wrapper-1 {
			padding-top: 30px;

			.submain__wrapper-right {
				padding-bottom: 30px;
			}
		}
		
		&.submain__wrapper-5 {
			.submain__wrapper-right {
				overflow: hidden;

				video {
					position: relative;
					width: calc(100% + 140px);
					max-width: unset;
				}
			}
		}

		&:not(:first-of-type) {
			.submain__wrapper-left {
				align-items: flex-start;
			}
			
			.main__heading,
			.main__label,
			.main__desc {
				text-align: left;
			}

			.main__desc {
				max-width: 100%;
			}
		}

		&-left {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 100%;
			padding-bottom: 50px;

			.c-btn {
				&__wrapper {
					padding-bottom: 0 !important;
				}
			}
		}

		&-right {
			display: flex;
			align-items: center;
			justify-content: center;

			img,
			video {
				opacity: 0;
				display: block;
				max-width: 100%;
				height: auto;
				margin-left: auto;
				margin-right: auto;
			}
		}

		.c-btn {
			@include adaptive(767) {
				width: 100%;

				&__wrapper {
					width: 100%;
					padding-bottom: 35px;
				}

				span {
					font-size: 14px;
				}
			}
		}
	}
}

// SECTION :: END
