// TITLE :: START

.c-title {
  font-weight: bold;
  font-size: 48px;
  line-height: 73px;
  text-align: center;
  color: #121E31;
  @include adaptive(1365) {
    font-size: 44px;
    line-height: 68px;
  }
  @include adaptive(1279) {
    font-size: 40px;
    line-height: 62px;
  }
  @include adaptive(1023) {
    font-size: 36px;
    line-height: 58px;
  }
  @include adaptive(767) {
    font-size: 24px;
    line-height: 36px;
  }
}

// TITLE :: END
