@font-face {
	font-family: "iconFont";
	src: url('../fonts/iconFont.eot');
	src: url('../fonts/iconFont.eot?#iefix') format('eot'),
		url('../fonts/iconFont.woff2') format('woff2'),
		url('../fonts/iconFont.woff') format('woff'),
		url('../fonts/iconFont.ttf') format('truetype'),
		url('../fonts/iconFont.svg#iconFont') format('svg');
}

@mixin icon-styles {
	font-family: "iconFont", sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	// speak: none; // only necessary if not using the private unicode range (firstGlyph option)
	text-decoration: none;
	text-transform: none;
}

%icon {
	@include icon-styles;
}

@function icon-char($filename) {
	$char: "";

	@if $filename == arrow {
		$char: "\E001";
	}
	@if $filename == build {
		$char: "\E002";
	}
	@if $filename == category {
		$char: "\E003";
	}
	@if $filename == chat {
		$char: "\E004";
	}
	@if $filename == checked {
		$char: "\E005";
	}
	@if $filename == circle-checked {
		$char: "\E006";
	}
	@if $filename == close {
		$char: "\E007";
	}
	@if $filename == cloud-check {
		$char: "\E008";
	}
	@if $filename == cloud {
		$char: "\E009";
	}
	@if $filename == corner {
		$char: "\E00A";
	}
	@if $filename == database {
		$char: "\E00B";
	}
	@if $filename == document {
		$char: "\E00C";
	}
	@if $filename == eye {
		$char: "\E00D";
	}
	@if $filename == governance {
		$char: "\E00E";
	}
	@if $filename == list-dots {
		$char: "\E00F";
	}
	@if $filename == logout {
		$char: "\E010";
	}
	@if $filename == message {
		$char: "\E011";
	}
	@if $filename == pen {
		$char: "\E012";
	}
	@if $filename == plus {
		$char: "\E013";
	}
	@if $filename == profile {
		$char: "\E014";
	}
	@if $filename == right-arrow {
		$char: "\E015";
	}
	@if $filename == rocket {
		$char: "\E016";
	}
	@if $filename == security {
		$char: "\E017";
	}
	@if $filename == square-dashed {
		$char: "\E018";
	}
	@if $filename == swap {
		$char: "\E019";
	}
	@if $filename == terminal {
		$char: "\E01A";
	}
	@if $filename == work {
		$char: "\E01B";
	}

	@return $char;
}

@mixin icon($filename, $insert: before, $extend: true) {
	&:#{$insert} {
		@if $extend {
			@extend %icon;
		} @else {
			@include icon-styles;
		}
		content: icon-char($filename);
	}
}

.icon-arrow {
	@include icon(arrow);

}
.icon-build {
	@include icon(build);

}
.icon-category {
	@include icon(category);

}
.icon-chat {
	@include icon(chat);

}
.icon-checked {
	@include icon(checked);

}
.icon-circle-checked {
	@include icon(circle-checked);

}
.icon-close {
	@include icon(close);

}
.icon-cloud-check {
	@include icon(cloud-check);

}
.icon-cloud {
	@include icon(cloud);

}
.icon-corner {
	@include icon(corner);

}
.icon-database {
	@include icon(database);

}
.icon-document {
	@include icon(document);

}
.icon-eye {
	@include icon(eye);

}
.icon-governance {
	@include icon(governance);

}
.icon-list-dots {
	@include icon(list-dots);

}
.icon-logout {
	@include icon(logout);

}
.icon-message {
	@include icon(message);

}
.icon-pen {
	@include icon(pen);

}
.icon-plus {
	@include icon(plus);

}
.icon-profile {
	@include icon(profile);

}
.icon-right-arrow {
	@include icon(right-arrow);

}
.icon-rocket {
	@include icon(rocket);

}
.icon-security {
	@include icon(security);

}
.icon-square-dashed {
	@include icon(square-dashed);

}
.icon-swap {
	@include icon(swap);

}
.icon-terminal {
	@include icon(terminal);

}
.icon-work {
	@include icon(work);

}