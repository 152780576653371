@charset "UTF-8";
/* line 14, src/scss/_utilities/_extend.scss */
.career__bg img, .describe__block-media img, .contacts__bg img, .dashboard__user > div:nth-of-type(1) img, .dashboard__account > div:nth-of-type(1) img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/* line 26, src/scss/_utilities/_extend.scss */
.copyright__text, .c-btn span, .footer__form-message > div p, .menu__wrapper-bottom a span, .sign__form-field label, .sign__form-field input, .sign__form-link, .sign__form-info, .sign__form-confirm, .sign__form-back, .career__wrapper-2 p, .career__slogan, .describe__block-desc p, .contacts__wrapper-2 p, .contacts__slogan, .office__block-body span, .office__box-desc, .dashboard__wrapper-left-bottom a span, .dashboard__nav a span, .dashboard__indicator-badge span, .dashboard__indicator-project p, .dashboard__indicator-email span, .dashboard__box.dashboard__box--add > div p, .dashboard__box > div, .dashboard__account-details, .dashboard__switch, .nfound__desc, .nfound__btn {
  font-family: 'Open Sans', sans-serif;
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
/* line 3, src/scss/_vendor/hamburger/_base.scss */
.hamburger {
  padding: 0 0;
  display: flex;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.35s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

/* line 21, src/scss/_vendor/hamburger/_base.scss */
.hamburger:hover {
  opacity: 1;
}

/* line 31, src/scss/_vendor/hamburger/_base.scss */
.hamburger-box {
  width: 28px;
  height: 19px;
  display: inline-block;
  position: relative;
}

/* line 38, src/scss/_vendor/hamburger/_base.scss */
.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1.5px;
}

/* line 43, src/scss/_vendor/hamburger/_base.scss */
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 28px;
  height: 3px;
  background-color: #fff;
  border-radius: 0;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

/* line 56, src/scss/_vendor/hamburger/_base.scss */
.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}

/* line 62, src/scss/_vendor/hamburger/_base.scss */
.hamburger-inner::before {
  top: -8px;
}

/* line 66, src/scss/_vendor/hamburger/_base.scss */
.hamburger-inner::after {
  bottom: -8px;
}

/*
   * Squeeze
   */
/* line 6, src/scss/_vendor/hamburger/types/_squeeze.scss */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

/* line 10, src/scss/_vendor/hamburger/types/_squeeze.scss */
.hamburger--squeeze .hamburger-inner::before {
  transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}

/* line 15, src/scss/_vendor/hamburger/types/_squeeze.scss */
.hamburger--squeeze .hamburger-inner::after {
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

/* line 22, src/scss/_vendor/hamburger/types/_squeeze.scss */
.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

/* line 27, src/scss/_vendor/hamburger/types/_squeeze.scss */
.hamburger--squeeze.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}

/* line 34, src/scss/_vendor/hamburger/types/_squeeze.scss */
.hamburger--squeeze.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@font-face {
  font-family: "iconFont";
  src: url("../fonts/iconFont.eot");
  src: url("../fonts/iconFont.eot?#iefix") format("eot"), url("../fonts/iconFont.woff2") format("woff2"), url("../fonts/iconFont.woff") format("woff"), url("../fonts/iconFont.ttf") format("truetype"), url("../fonts/iconFont.svg#iconFont") format("svg");
}

/* line 23, src/scss/_generated/_spriteFont.scss */
.icon-arrow:before, .icon-build:before, .icon-category:before, .icon-chat:before, .icon-checked:before, .icon-circle-checked:before, .icon-close:before, .icon-cloud-check:before, .icon-cloud:before, .icon-corner:before, .icon-database:before, .icon-document:before, .icon-eye:before, .icon-governance:before, .icon-list-dots:before, .icon-logout:before, .icon-message:before, .icon-pen:before, .icon-plus:before, .icon-profile:before, .icon-right-arrow:before, .icon-rocket:before, .icon-security:before, .icon-square-dashed:before, .icon-swap:before, .icon-terminal:before, .icon-work:before {
  font-family: "iconFont", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
}

/* line 116, src/scss/_generated/_spriteFont.scss */
.icon-arrow:before {
  content: "";
}

/* line 116, src/scss/_generated/_spriteFont.scss */
.icon-build:before {
  content: "";
}

/* line 116, src/scss/_generated/_spriteFont.scss */
.icon-category:before {
  content: "";
}

/* line 116, src/scss/_generated/_spriteFont.scss */
.icon-chat:before {
  content: "";
}

/* line 116, src/scss/_generated/_spriteFont.scss */
.icon-checked:before {
  content: "";
}

/* line 116, src/scss/_generated/_spriteFont.scss */
.icon-circle-checked:before {
  content: "";
}

/* line 116, src/scss/_generated/_spriteFont.scss */
.icon-close:before {
  content: "";
}

/* line 116, src/scss/_generated/_spriteFont.scss */
.icon-cloud-check:before {
  content: "";
}

/* line 116, src/scss/_generated/_spriteFont.scss */
.icon-cloud:before {
  content: "";
}

/* line 116, src/scss/_generated/_spriteFont.scss */
.icon-corner:before {
  content: "";
}

/* line 116, src/scss/_generated/_spriteFont.scss */
.icon-database:before {
  content: "";
}

/* line 116, src/scss/_generated/_spriteFont.scss */
.icon-document:before {
  content: "";
}

/* line 116, src/scss/_generated/_spriteFont.scss */
.icon-eye:before {
  content: "";
}

/* line 116, src/scss/_generated/_spriteFont.scss */
.icon-governance:before {
  content: "";
}

/* line 116, src/scss/_generated/_spriteFont.scss */
.icon-list-dots:before {
  content: "";
}

/* line 116, src/scss/_generated/_spriteFont.scss */
.icon-logout:before {
  content: "";
}

/* line 116, src/scss/_generated/_spriteFont.scss */
.icon-message:before {
  content: "";
}

/* line 116, src/scss/_generated/_spriteFont.scss */
.icon-pen:before {
  content: "";
}

/* line 116, src/scss/_generated/_spriteFont.scss */
.icon-plus:before {
  content: "";
}

/* line 116, src/scss/_generated/_spriteFont.scss */
.icon-profile:before {
  content: "";
}

/* line 116, src/scss/_generated/_spriteFont.scss */
.icon-right-arrow:before {
  content: "";
}

/* line 116, src/scss/_generated/_spriteFont.scss */
.icon-rocket:before {
  content: "";
}

/* line 116, src/scss/_generated/_spriteFont.scss */
.icon-security:before {
  content: "";
}

/* line 116, src/scss/_generated/_spriteFont.scss */
.icon-square-dashed:before {
  content: "";
}

/* line 116, src/scss/_generated/_spriteFont.scss */
.icon-swap:before {
  content: "";
}

/* line 116, src/scss/_generated/_spriteFont.scss */
.icon-terminal:before {
  content: "";
}

/* line 116, src/scss/_generated/_spriteFont.scss */
.icon-work:before {
  content: "";
}

/* line 1, src/scss/_shared/_main-setting-reset.scss */
html,
body {
  width: 100%;
  min-height: 100vh;
}

/* line 6, src/scss/_shared/_main-setting-reset.scss */
html.is-hideScroll,
body.is-hideScroll {
  width: 100%;
  overflow: hidden !important;
}

/* line 13, src/scss/_shared/_main-setting-reset.scss */
html {
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* line 23, src/scss/_shared/_main-setting-reset.scss */
body {
  overflow-x: hidden;
  position: relative;
  margin: 0;
  padding: 0;
  background-color: white;
  line-height: 1.42857143;
  font-size: 14px;
  font-weight: normal;
  font-family: "Prompt", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* line 37, src/scss/_shared/_main-setting-reset.scss */
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* line 42, src/scss/_shared/_main-setting-reset.scss */
body > .body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 auto;
}

/* line 48, src/scss/_shared/_main-setting-reset.scss */
body > .body--start {
  justify-content: flex-start;
}

/* line 55, src/scss/_shared/_main-setting-reset.scss */
* {
  box-sizing: border-box;
}

/* line 58, src/scss/_shared/_main-setting-reset.scss */
*:before, *:after {
  box-sizing: border-box;
}

/* line 65, src/scss/_shared/_main-setting-reset.scss */
button,
input,
optgroup,
select,
textarea {
  font-size: 100%;
  margin: 0;
}

/* line 75, src/scss/_shared/_main-setting-reset.scss */
button,
input {
  overflow: visible;
}

/* line 81, src/scss/_shared/_main-setting-reset.scss */
button[disabled] {
  opacity: 0.55;
  cursor: not-allowed;
}

/* line 87, src/scss/_shared/_main-setting-reset.scss */
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* line 93, src/scss/_shared/_main-setting-reset.scss */
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/* line 99, src/scss/_shared/_main-setting-reset.scss */
textarea {
  overflow: auto;
}

/* line 104, src/scss/_shared/_main-setting-reset.scss */
p,
h1, h2, h3, h4, h5, h6,
hr {
  margin: 0;
  padding: 0;
}

/* line 112, src/scss/_shared/_main-setting-reset.scss */
ul {
  margin: 0;
  padding: 0;
}

/* line 117, src/scss/_shared/_main-setting-reset.scss */
ul li {
  list-style: none;
}

/* line 123, src/scss/_shared/_main-setting-reset.scss */
a {
  outline: none;
  text-decoration: none;
}

/* line 128, src/scss/_shared/_main-setting-reset.scss */
a:hover, a:focus {
  text-decoration: none;
}

/* line 135, src/scss/_shared/_main-setting-reset.scss */
input[type="text"] {
  appearance: none;
}

/* line 139, src/scss/_shared/_main-setting-reset.scss */
picture {
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* line 8, src/scss/_shared/_grid.scss */
.c-grid, .c-grid-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
}

/* line 20, src/scss/_shared/_grid.scss */
.c-grid {
  max-width: 1170px;
}

/* line 26, src/scss/_shared/_grid.scss */
.c-grid-fluid {
  max-width: 100%;
}

/* line 3, src/scss/_shared/_common.scss */
#container {
  z-index: 999;
  position: relative;
  padding-top: 0;
  box-shadow: 0 -8px 30px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

@media screen and (max-width: 767px) {
  /* line 3, src/scss/_shared/_common.scss */
  #container {
    box-shadow: none;
    padding-top: 0;
  }
}

/* line 15, src/scss/_shared/_common.scss */
.copyright {
  display: flex;
  justify-content: center;
}

/* line 19, src/scss/_shared/_common.scss */
.copyright__text {
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #FFFFFF;
}

/* line 1, src/scss/_shared/_iconFont.scss */
.icon-font {
  display: flex;
  line-height: 1;
}

/* line 3, src/scss/_macros/_logotype.scss */
.logo {
  display: flex;
}

/* line 8, src/scss/_macros/_logotype.scss */
.logo.logo--header .logo__img {
  transition: opacity 0.3s ease-in-out;
}

/* line 11, src/scss/_macros/_logotype.scss */
.logo.logo--header .logo__img--mobile {
  opacity: 0;
  position: absolute;
}

@media screen and (max-width: 1023px) {
  /* line 21, src/scss/_macros/_logotype.scss */
  .logo.logo--dashboard .logo__btn {
    max-width: 42px;
  }
}

/* line 26, src/scss/_macros/_logotype.scss */
.logo.logo--dashboard .logo__btn img {
  display: block;
  max-width: 100%;
  height: auto;
}

/* line 37, src/scss/_macros/_logotype.scss */
.logo__btn {
  position: relative;
  display: flex;
  align-items: flex-start;
}

/* line 43, src/scss/_macros/_logotype.scss */
.logo__img {
  display: block;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

@media screen and (max-width: 1439px) {
  /* line 43, src/scss/_macros/_logotype.scss */
  .logo__img {
    width: 100%;
    max-width: 50px;
  }
}

@media screen and (max-width: 767px) {
  /* line 43, src/scss/_macros/_logotype.scss */
  .logo__img {
    max-width: 42px;
  }
}

/* line 3, src/scss/_macros/_title.scss */
.c-title {
  font-weight: bold;
  font-size: 48px;
  line-height: 73px;
  text-align: center;
  color: #121E31;
}

@media screen and (max-width: 1365px) {
  /* line 3, src/scss/_macros/_title.scss */
  .c-title {
    font-size: 44px;
    line-height: 68px;
  }
}

@media screen and (max-width: 1279px) {
  /* line 3, src/scss/_macros/_title.scss */
  .c-title {
    font-size: 40px;
    line-height: 62px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 3, src/scss/_macros/_title.scss */
  .c-title {
    font-size: 36px;
    line-height: 58px;
  }
}

@media screen and (max-width: 767px) {
  /* line 3, src/scss/_macros/_title.scss */
  .c-title {
    font-size: 24px;
    line-height: 36px;
  }
}

/* line 3, src/scss/_macros/_btn.scss */
.c-btn {
  outline: none;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 50px;
  padding-left: 32px;
  padding-right: 32px;
  box-shadow: none;
  border-radius: 2rem;
  backface-visibility: hidden;
  transform: translateX(-1);
}

/* line 19, src/scss/_macros/_btn.scss */
.c-btn__wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

/* line 24, src/scss/_macros/_btn.scss */
.c-btn__wrapper--center {
  justify-content: center;
}

/* line 27, src/scss/_macros/_btn.scss */
.c-btn__wrapper--end {
  justify-content: flex-end;
}

/* line 32, src/scss/_macros/_btn.scss */
.c-btn__border {
  border: 1px solid #fff;
  background-color: transparent;
  transition: background-color 0.3s ease-in-out;
}

/* line 37, src/scss/_macros/_btn.scss */
.c-btn__border:hover {
  background-color: #fff;
  transition: background-color 0.3s ease-in-out;
}

/* line 41, src/scss/_macros/_btn.scss */
.c-btn__border:hover span {
  color: #0A0A0A;
  transition: color 0.3s ease-in-out;
}

/* line 47, src/scss/_macros/_btn.scss */
.c-btn__border.c-btn__border--dark {
  border-color: #0A0A0A;
  background-color: transparent;
  transition: background-color 0.3s ease-in-out;
}

/* line 52, src/scss/_macros/_btn.scss */
.c-btn__border.c-btn__border--dark:hover {
  background-color: #0a0a0a;
  transition: background-color 0.3s ease-in-out;
}

/* line 56, src/scss/_macros/_btn.scss */
.c-btn__border.c-btn__border--dark:hover span {
  color: #ffffff;
  transition: color 0.3s ease-in-out;
}

/* line 62, src/scss/_macros/_btn.scss */
.c-btn__border.c-btn__border--dark span {
  color: #0a0a0a;
  transition: color 0.3s ease-in-out;
}

/* line 69, src/scss/_macros/_btn.scss */
.c-btn__bg {
  overflow: hidden;
}

/* line 73, src/scss/_macros/_btn.scss */
.c-btn__bg:hover {
  transform: translateX(-1);
  backface-visibility: hidden;
}

/* line 77, src/scss/_macros/_btn.scss */
.c-btn__bg:hover:before, .c-btn__bg:hover:after {
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

/* line 82, src/scss/_macros/_btn.scss */
.c-btn__bg:hover:before {
  opacity: 1;
  visibility: visible;
}

/* line 87, src/scss/_macros/_btn.scss */
.c-btn__bg:hover:after {
  opacity: 0;
  visibility: hidden;
}

/* line 93, src/scss/_macros/_btn.scss */
.c-btn__bg.c-btn__bg--white {
  background-color: #ffffff;
}

/* line 96, src/scss/_macros/_btn.scss */
.c-btn__bg.c-btn__bg--white:before, .c-btn__bg.c-btn__bg--white:after {
  display: none;
}

/* line 101, src/scss/_macros/_btn.scss */
.c-btn__bg.c-btn__bg--white span {
  color: #00b94f;
}

/* line 106, src/scss/_macros/_btn.scss */
.c-btn__bg:before, .c-btn__bg:after {
  content: '';
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2rem;
  transform: translateZ(-1);
  backface-visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

/* line 121, src/scss/_macros/_btn.scss */
.c-btn__bg:before {
  background: linear-gradient(177.56deg, #3396E1 -48.98%, #2AB492 68.87%, #038A3D 180.55%);
  box-shadow: 0px 6px 20px rgba(40, 223, 153, 0.2), 0px 20px 30px rgba(40, 223, 153, 0.2);
}

/* line 126, src/scss/_macros/_btn.scss */
.c-btn__bg:after {
  background: linear-gradient(177.56deg, #4097D9 -48.98%, #30C4A0 68.87%, #00B94F 180.55%);
}

/* line 131, src/scss/_macros/_btn.scss */
.c-btn span {
  z-index: 2;
  position: relative;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #FFFFFF;
}

/* line 3, src/scss/_components/_header.scss */
.header {
  z-index: 1999;
  opacity: 0;
  position: fixed;
  top: 25px;
  left: 25px;
  width: calc(100% - 50px);
  border-radius: 10px;
  box-shadow: 0 10px 40px 0 rgba(64, 151, 217, 0.25);
  background: linear-gradient(90deg, #4097D9 -14.97%, #30C4A0 54.44%, #00B94F 120.21%);
  transition: top 0.3s ease-in-out, left 0.3s ease-in-out, width 0.3s ease-in-out, border-radius 0.3s ease-in-out, box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
}

@media screen and (max-width: 1439px) {
  /* line 3, src/scss/_components/_header.scss */
  .header {
    top: 10px;
    left: 10px;
    width: calc(100% - 20px);
  }
}

@media screen and (max-width: 767px) {
  /* line 3, src/scss/_components/_header.scss */
  .header {
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 0;
  }
}

/* line 27, src/scss/_components/_header.scss */
.header:after {
  content: '';
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

/* line 40, src/scss/_components/_header.scss */
.header.is-fixed {
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 0 0 10px 10px;
  transition: top 0.3s ease-in-out, left 0.3s ease-in-out, width 0.3s ease-in-out, border-radius 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

@media screen and (max-width: 767px) {
  /* line 40, src/scss/_components/_header.scss */
  .header.is-fixed {
    border-radius: 0;
  }
}

/* line 51, src/scss/_components/_header.scss */
.header.is-fixed .header__wrapper {
  height: 100px;
  padding-top: 0;
  padding-bottom: 0;
  transition: height 0.3s ease-in-out, padding-top 0.3s ease-in-out, padding-bottom 0.3s ease-in-out;
}

@media screen and (max-width: 767px) {
  /* line 51, src/scss/_components/_header.scss */
  .header.is-fixed .header__wrapper {
    height: 80px;
  }
}

/* line 63, src/scss/_components/_header.scss */
.header.is-color {
  box-shadow: 0 10px 40px 0 rgba(64, 151, 217, 0.25);
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
}

/* line 67, src/scss/_components/_header.scss */
.header.is-color:after {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

/* line 76, src/scss/_components/_header.scss */
.header.is-color .header__btn .c-btn:after {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

/* line 82, src/scss/_components/_header.scss */
.header.is-color .header__btn .c-btn span {
  color: #ffffff;
  transition: color 0.3s ease-in-out;
}

/* line 93, src/scss/_components/_header.scss */
.header.is-color .logo.logo--header .logo__img--desktop {
  opacity: 0;
}

/* line 96, src/scss/_components/_header.scss */
.header.is-color .logo.logo--header .logo__img--mobile {
  opacity: 1;
}

/* line 104, src/scss/_components/_header.scss */
.header__wrapper {
  z-index: 2;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 120px;
  padding: 10px 50px;
  transition: height 0.3s ease-in-out, padding-top 0.3s ease-in-out, padding-bottom 0.3s ease-in-out;
}

@media screen and (max-width: 1439px) {
  /* line 104, src/scss/_components/_header.scss */
  .header__wrapper {
    height: 100px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 104, src/scss/_components/_header.scss */
  .header__wrapper {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media screen and (max-width: 767px) {
  /* line 104, src/scss/_components/_header.scss */
  .header__wrapper {
    height: 80px;
    padding: 15px 20px;
  }
}

/* line 125, src/scss/_components/_header.scss */
.header__wrapper-left, .header__wrapper-right {
  width: 100%;
  max-width: 200px;
}

@media screen and (max-width: 1023px) {
  /* line 125, src/scss/_components/_header.scss */
  .header__wrapper-left, .header__wrapper-right {
    max-width: 125px;
  }
}

@media screen and (max-width: 767px) {
  /* line 134, src/scss/_components/_header.scss */
  .header__wrapper-left {
    flex: 1;
    min-width: 1px;
    max-width: 100%;
  }
}

/* line 142, src/scss/_components/_header.scss */
.header__wrapper-middle {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-width: 1px;
  max-width: 100%;
  padding-left: 50px;
  padding-right: 50px;
}

@media screen and (max-width: 1023px) {
  /* line 142, src/scss/_components/_header.scss */
  .header__wrapper-middle {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 767px) {
  /* line 142, src/scss/_components/_header.scss */
  .header__wrapper-middle {
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    overflow-y: auto;
    position: fixed;
    top: 80px;
    left: 0;
    flex-direction: column;
    flex: unset;
    width: 100%;
    height: calc(100% - 80px);
    padding: 30px 50px;
    background: linear-gradient(90deg, #4097D9 -14.97%, #30C4A0 54.44%, #00B94F 120.21%);
    transform-origin: center;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, transform 0.3s ease-in-out;
  }
  /* line 173, src/scss/_components/_header.scss */
  .header__wrapper-middle.is-open {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, transform 0.3s ease-in-out;
  }
}

/* line 182, src/scss/_components/_header.scss */
.header__wrapper-right {
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 767px) {
  /* line 182, src/scss/_components/_header.scss */
  .header__wrapper-right {
    align-items: center;
    width: auto;
    max-width: unset;
    padding-left: 20px;
  }
}

/* line 194, src/scss/_components/_header.scss */
.header__nav {
  display: flex;
}

@media screen and (max-width: 767px) {
  /* line 194, src/scss/_components/_header.scss */
  .header__nav {
    flex-direction: column;
  }
}

/* line 200, src/scss/_components/_header.scss */
.header__nav a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: 10px;
  margin-right: 10px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
}

@media screen and (max-width: 1023px) {
  /* line 200, src/scss/_components/_header.scss */
  .header__nav a {
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 15px;
  }
}

@media screen and (max-width: 767px) {
  /* line 200, src/scss/_components/_header.scss */
  .header__nav a {
    align-items: center;
    min-height: 55px;
    margin: 0;
    padding: 0;
    font-size: 18px;
  }
}

@media screen and (max-width: 767px) {
  /* line 229, src/scss/_components/_header.scss */
  .header__nav a[desktop-nav-js] {
    display: none;
  }
}

/* line 234, src/scss/_components/_header.scss */
.header__nav a[mobile-nav-js] {
  display: none;
}

@media screen and (max-width: 767px) {
  /* line 234, src/scss/_components/_header.scss */
  .header__nav a[mobile-nav-js] {
    display: flex;
  }
}

@media screen and (max-width: 767px) {
  /* line 244, src/scss/_components/_header.scss */
  .header__btn .c-btn {
    height: 40px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

/* line 251, src/scss/_components/_header.scss */
.header__btn .c-btn:after {
  content: '';
  opacity: 0;
  visibility: hidden;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2rem;
  background: linear-gradient(177.56deg, #4097D9 -48.98%, #30C4A0 68.87%, #00B94F 180.55%), #FFFFFF;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

/* line 266, src/scss/_components/_header.scss */
.header__btn .c-btn span {
  transition: color 0.3s ease-in-out;
}

/* line 272, src/scss/_components/_header.scss */
.header .hamburger {
  display: none;
  margin-left: 20px;
}

@media screen and (max-width: 767px) {
  /* line 272, src/scss/_components/_header.scss */
  .header .hamburger {
    display: flex;
  }
}

/* line 3, src/scss/_components/_footer.scss */
.footer {
  z-index: 999;
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #ffffff;
}

@media screen and (max-width: 1279px) {
  /* line 3, src/scss/_components/_footer.scss */
  .footer {
    padding-bottom: 0;
  }
}

/* line 13, src/scss/_components/_footer.scss */
.footer__bg {
  z-index: 0;
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: calc(100% - 100px);
  height: 600px;
  border-radius: 10px;
  background-color: #0A0A0A;
}

@media screen and (max-width: 1279px) {
  /* line 13, src/scss/_components/_footer.scss */
  .footer__bg {
    bottom: 0;
    max-width: 100%;
    border-radius: 10px 10px 0 0;
  }
}

@media screen and (max-width: 767px) {
  /* line 13, src/scss/_components/_footer.scss */
  .footer__bg {
    height: calc(100% - 350px);
    border-radius: 0;
  }
}

/* line 37, src/scss/_components/_footer.scss */
.footer__wrapper {
  z-index: 2;
  position: relative;
}

/* line 41, src/scss/_components/_footer.scss */
.footer__wrapper-1 {
  overflow: hidden;
  padding: 50px 60px;
  border-radius: 10px;
  background-color: #4097D9;
}

@media screen and (max-width: 1279px) {
  /* line 41, src/scss/_components/_footer.scss */
  .footer__wrapper-1 {
    padding: 30px;
  }
}

@media screen and (max-width: 767px) {
  /* line 41, src/scss/_components/_footer.scss */
  .footer__wrapper-1 {
    padding: 30px 20px;
  }
}

/* line 54, src/scss/_components/_footer.scss */
.footer__wrapper-2 {
  display: flex;
  padding-top: 50px;
  margin-bottom: 50px;
  padding-bottom: 50px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

@media screen and (max-width: 767px) {
  /* line 54, src/scss/_components/_footer.scss */
  .footer__wrapper-2 {
    flex-wrap: wrap;
  }
}

/* line 65, src/scss/_components/_footer.scss */
.footer__wrapper-2 > div:nth-of-type(1) {
  display: flex;
  width: 100%;
  max-width: 680px;
}

@media screen and (max-width: 1279px) {
  /* line 65, src/scss/_components/_footer.scss */
  .footer__wrapper-2 > div:nth-of-type(1) {
    max-width: 50%;
    flex-direction: column;
  }
}

@media screen and (max-width: 767px) {
  /* line 65, src/scss/_components/_footer.scss */
  .footer__wrapper-2 > div:nth-of-type(1) {
    max-width: 100%;
  }
}

/* line 78, src/scss/_components/_footer.scss */
.footer__wrapper-2 > div:nth-of-type(1) > div:nth-of-type(1) {
  width: 100%;
  max-width: 230px;
}

@media screen and (max-width: 1279px) {
  /* line 78, src/scss/_components/_footer.scss */
  .footer__wrapper-2 > div:nth-of-type(1) > div:nth-of-type(1) {
    max-width: 100%;
  }
}

/* line 85, src/scss/_components/_footer.scss */
.footer__wrapper-2 > div:nth-of-type(1) > div:nth-of-type(2) {
  position: relative;
  flex: 1;
  min-width: 1px;
  max-width: 100%;
  padding-left: 20px;
  margin-left: 20px;
}

@media screen and (max-width: 1279px) {
  /* line 85, src/scss/_components/_footer.scss */
  .footer__wrapper-2 > div:nth-of-type(1) > div:nth-of-type(2) {
    padding-left: 0;
    margin-left: 0;
  }
}

@media screen and (max-width: 767px) {
  /* line 85, src/scss/_components/_footer.scss */
  .footer__wrapper-2 > div:nth-of-type(1) > div:nth-of-type(2) {
    margin-top: 20px;
    padding-top: 20px;
    padding-bottom: 50px;
  }
}

/* line 102, src/scss/_components/_footer.scss */
.footer__wrapper-2 > div:nth-of-type(1) > div:nth-of-type(2):after {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 1px;
  height: 30px;
  background: rgba(255, 255, 255, 0.2);
}

@media screen and (max-width: 1279px) {
  /* line 102, src/scss/_components/_footer.scss */
  .footer__wrapper-2 > div:nth-of-type(1) > div:nth-of-type(2):after {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  /* line 102, src/scss/_components/_footer.scss */
  .footer__wrapper-2 > div:nth-of-type(1) > div:nth-of-type(2):after {
    top: 0;
    right: 0;
    transform: translateY(0);
    display: block;
    width: 100%;
    max-width: 200px;
    height: 1px;
    margin-left: auto;
    margin-right: auto;
  }
}

/* line 127, src/scss/_components/_footer.scss */
.footer__wrapper-2 > div:nth-of-type(1) > div:nth-of-type(2) .footer__heading {
  opacity: 0;
}

@media screen and (max-width: 767px) {
  /* line 127, src/scss/_components/_footer.scss */
  .footer__wrapper-2 > div:nth-of-type(1) > div:nth-of-type(2) .footer__heading {
    display: none;
  }
}

/* line 136, src/scss/_components/_footer.scss */
.footer__wrapper-2 > div:nth-of-type(2) {
  display: flex;
  justify-content: space-between;
  flex: 1;
  min-width: 1px;
  max-width: 100%;
  padding-left: 90px;
}

@media screen and (max-width: 767px) {
  /* line 136, src/scss/_components/_footer.scss */
  .footer__wrapper-2 > div:nth-of-type(2) {
    padding-left: 0;
  }
}

/* line 147, src/scss/_components/_footer.scss */
.footer__wrapper-2 > div:nth-of-type(2) > div {
  white-space: nowrap;
}

@media screen and (max-width: 767px) {
  /* line 147, src/scss/_components/_footer.scss */
  .footer__wrapper-2 > div:nth-of-type(2) > div {
    white-space: normal;
    flex: 1;
    min-width: 1px;
    max-width: 100%;
  }
}

/* line 160, src/scss/_components/_footer.scss */
.footer__wrapper-3 {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  /* line 160, src/scss/_components/_footer.scss */
  .footer__wrapper-3 {
    align-items: flex-start;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 767px) {
  /* line 169, src/scss/_components/_footer.scss */
  .footer__wrapper-3 > div:nth-of-type(1) {
    display: none;
  }
}

/* line 174, src/scss/_components/_footer.scss */
.footer__wrapper-3 > div:nth-of-type(2) {
  flex: 1;
  min-width: 1px;
  max-width: 100%;
  padding-left: 30px;
  padding-right: 55px;
}

@media screen and (max-width: 767px) {
  /* line 174, src/scss/_components/_footer.scss */
  .footer__wrapper-3 > div:nth-of-type(2) {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 767px) {
  /* line 186, src/scss/_components/_footer.scss */
  .footer__wrapper-3 > div:nth-of-type(3) {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  /* line 193, src/scss/_components/_footer.scss */
  .footer__wrapper-3 .c-btn {
    width: 100%;
    max-width: calc((100% - 15px) / 2);
  }
  /* line 198, src/scss/_components/_footer.scss */
  .footer__wrapper-3 .c-btn__wrapper {
    justify-content: space-between;
  }
}

/* line 203, src/scss/_components/_footer.scss */
.footer__wrapper-3 .c-btn:not(:last-of-type) {
  margin-right: 11px;
}

/* line 209, src/scss/_components/_footer.scss */
.footer__wrapper-4 {
  padding-top: 100px;
  padding-bottom: 35px;
}

@media screen and (max-width: 767px) {
  /* line 209, src/scss/_components/_footer.scss */
  .footer__wrapper-4 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

/* line 218, src/scss/_components/_footer.scss */
.footer__wrapper-bg {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../img/img-footer-pattern.svg");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
}

/* line 232, src/scss/_components/_footer.scss */
.footer__form {
  z-index: 2;
  position: relative;
}

/* line 236, src/scss/_components/_footer.scss */
.footer__form-title {
  padding-bottom: 60px;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #FFFFFF;
}

@media screen and (max-width: 767px) {
  /* line 236, src/scss/_components/_footer.scss */
  .footer__form-title {
    padding-bottom: 30px;
    font-size: 16px;
    line-height: 24px;
  }
}

/* line 249, src/scss/_components/_footer.scss */
.footer__form-group {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 1023px) {
  /* line 249, src/scss/_components/_footer.scss */
  .footer__form-group {
    flex-wrap: wrap;
  }
}

/* line 256, src/scss/_components/_footer.scss */
.footer__form-group > div {
  width: 100%;
  max-width: calc((100% - 80px) / 3);
  padding-bottom: 50px;
}

@media screen and (max-width: 1023px) {
  /* line 256, src/scss/_components/_footer.scss */
  .footer__form-group > div {
    max-width: calc((100% - 30px) / 3);
  }
}

@media screen and (max-width: 767px) {
  /* line 256, src/scss/_components/_footer.scss */
  .footer__form-group > div {
    max-width: 100%;
    padding-bottom: 30px;
  }
}

/* line 270, src/scss/_components/_footer.scss */
.footer__form-field {
  display: flex;
  width: 100%;
}

/* line 274, src/scss/_components/_footer.scss */
.footer__form-field.footer__form-field--btn {
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
}

@media screen and (max-width: 767px) {
  /* line 274, src/scss/_components/_footer.scss */
  .footer__form-field.footer__form-field--btn {
    flex-direction: column;
    justify-content: center;
  }
}

/* line 284, src/scss/_components/_footer.scss */
.footer__form-field .c-btn {
  min-width: 140px;
}

@media screen and (max-width: 767px) {
  /* line 284, src/scss/_components/_footer.scss */
  .footer__form-field .c-btn {
    width: 100%;
    max-width: 100%;
  }
  /* line 290, src/scss/_components/_footer.scss */
  .footer__form-field .c-btn__wrapper {
    width: 100%;
  }
}

/* line 296, src/scss/_components/_footer.scss */
.footer__form-field label {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* line 301, src/scss/_components/_footer.scss */
.footer__form-field label > span {
  display: block;
  font-family: 'Open Sans', sans-serif;
  font-weight: 800;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  color: #FFFFFF;
}

/* line 311, src/scss/_components/_footer.scss */
.footer__form-field label input,
.footer__form-field label textarea {
  outline: none;
  position: relative;
  display: flex;
  width: 100%;
  height: 35px;
  padding: 0;
  border: none;
  border-bottom: 2px solid transparent;
  box-shadow: none;
  border-radius: 0;
  background-color: transparent;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  transition: border-color 0.3s ease-in-out;
}

/* line 88, src/scss/_utilities/_mixin.scss */
.footer__form-field label input.placeholder,
.footer__form-field label textarea.placeholder {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.7);
}

/* line 89, src/scss/_utilities/_mixin.scss */
.footer__form-field label input:-moz-placeholder,
.footer__form-field label textarea:-moz-placeholder {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.7);
}

/* line 90, src/scss/_utilities/_mixin.scss */
.footer__form-field label input::-moz-placeholder,
.footer__form-field label textarea::-moz-placeholder {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.7);
}

/* line 91, src/scss/_utilities/_mixin.scss */
.footer__form-field label input:-ms-input-placeholder,
.footer__form-field label textarea:-ms-input-placeholder {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.7);
}

/* line 92, src/scss/_utilities/_mixin.scss */
.footer__form-field label input::-webkit-input-placeholder,
.footer__form-field label textarea::-webkit-input-placeholder {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.7);
}

/* line 336, src/scss/_components/_footer.scss */
.footer__form-field label input:focus,
.footer__form-field label textarea:focus {
  border-color: #fff;
  transition: border-color 0.3s ease-in-out;
}

/* line 342, src/scss/_components/_footer.scss */
.footer__form-field label textarea {
  resize: none;
}

@media screen and (max-width: 767px) {
  /* line 348, src/scss/_components/_footer.scss */
  .footer__form-message {
    padding-bottom: 20px;
  }
}

/* line 353, src/scss/_components/_footer.scss */
.footer__form-message > div {
  display: none;
  align-items: center;
}

/* line 358, src/scss/_components/_footer.scss */
.footer__form-message > div.error span {
  background-color: #ff4b55;
}

/* line 363, src/scss/_components/_footer.scss */
.footer__form-message > div.done span {
  background-color: #00b94f;
}

/* line 368, src/scss/_components/_footer.scss */
.footer__form-message > div.is-active {
  display: flex;
}

/* line 372, src/scss/_components/_footer.scss */
.footer__form-message > div span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

/* line 380, src/scss/_components/_footer.scss */
.footer__form-message > div span + p {
  padding-left: 10px;
}

/* line 384, src/scss/_components/_footer.scss */
.footer__form-message > div span .icon-font {
  color: #ffffff;
  font-size: 8px;
}

/* line 390, src/scss/_components/_footer.scss */
.footer__form-message > div p {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #FFFFFF;
}

/* line 401, src/scss/_components/_footer.scss */
.footer__title {
  padding-bottom: 10px;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #FFFFFF;
}

/* line 409, src/scss/_components/_footer.scss */
.footer__desc {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 24px;
  color: #FFFFFF;
}

/* line 416, src/scss/_components/_footer.scss */
.footer__heading {
  padding-bottom: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
}

/* line 424, src/scss/_components/_footer.scss */
.footer__link {
  font-size: 14px;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.8);
}

/* line 430, src/scss/_components/_footer.scss */
.footer__address {
  font-size: 14px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.8);
}

/* line 435, src/scss/_components/_footer.scss */
.footer__address img {
  display: inline-block;
  vertical-align: middle;
}

/* line 3, src/scss/_components/_menu.scss */
.menu {
  z-index: 2999;
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(51, 50, 92, 0.2);
  backdrop-filter: blur(2px);
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

/* line 16, src/scss/_components/_menu.scss */
.menu.is-open {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

/* line 22, src/scss/_components/_menu.scss */
.menu.is-open .menu__wrapper {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, transform 0.3s ease-in-out;
}

/* line 31, src/scss/_components/_menu.scss */
.menu__wrapper {
  opacity: 0;
  visibility: hidden;
  transform: translateX(100%);
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 250px;
  height: 100%;
  padding: 90px 25px 30px;
  background-color: #ffffff;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, transform 0.3s ease-in-out;
}

/* line 47, src/scss/_components/_menu.scss */
.menu__wrapper-top {
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(51, 50, 92, 0.2);
}

/* line 52, src/scss/_components/_menu.scss */
.menu__wrapper-middle {
  position: relative;
  flex: 1;
  min-width: 1px;
  max-width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 0;
}

/* line 61, src/scss/_components/_menu.scss */
.menu__wrapper-middle:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

/* line 69, src/scss/_components/_menu.scss */
.menu__wrapper-bottom {
  padding-top: 30px;
  border-top: 1px solid rgba(51, 50, 92, 0.2);
}

/* line 73, src/scss/_components/_menu.scss */
.menu__wrapper-bottom a {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* line 78, src/scss/_components/_menu.scss */
.menu__wrapper-bottom a .icon-font,
.menu__wrapper-bottom a span {
  color: #33325C;
}

/* line 83, src/scss/_components/_menu.scss */
.menu__wrapper-bottom a .icon-font {
  font-size: 22px;
}

/* line 86, src/scss/_components/_menu.scss */
.menu__wrapper-bottom a .icon-font + span {
  padding-left: 10px;
}

/* line 91, src/scss/_components/_menu.scss */
.menu__wrapper-bottom a span {
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
}

/* line 101, src/scss/_components/_menu.scss */
.menu__close {
  z-index: 10;
  position: absolute;
  top: 20px;
  right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  border-radius: 10px;
  background-color: rgba(108, 108, 113, 0.1);
}

/* line 114, src/scss/_components/_menu.scss */
.menu__close .icon-font {
  color: #6c6c71;
  font-size: 10px;
  transform: rotate(45deg);
}

/* line 121, src/scss/_components/_menu.scss */
.menu .dashboard__nav {
  display: inline-block;
  vertical-align: middle;
}

/* line 125, src/scss/_components/_menu.scss */
.menu .dashboard__nav a {
  padding-left: 10px;
  padding-right: 10px;
}

/* line 129, src/scss/_components/_menu.scss */
.menu .dashboard__nav a:not(:last-of-type) {
  margin-bottom: 30px;
}

/* line 133, src/scss/_components/_menu.scss */
.menu .dashboard__nav a:after {
  display: none;
}

/* line 137, src/scss/_components/_menu.scss */
.menu .dashboard__nav a .icon-font,
.menu .dashboard__nav a span {
  color: #33325c;
}

/* line 3, src/scss/_components/homepage/_main.scss */
.main {
  overflow: hidden;
  position: relative;
  padding-bottom: 200px;
}

@media screen and (max-width: 767px) {
  /* line 3, src/scss/_components/homepage/_main.scss */
  .main {
    z-index: -9999;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: -999999px;
    height: 0;
  }
}

/* line 16, src/scss/_components/homepage/_main.scss */
.main [main-content-js] {
  opacity: 0;
}

/* line 20, src/scss/_components/homepage/_main.scss */
.main__bg {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* line 29, src/scss/_components/homepage/_main.scss */
.main__bg-grid > div {
  position: fixed;
  top: 0;
  right: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 768px;
  height: 100vh;
  margin-left: auto;
}

@media screen and (max-width: 1599px) {
  /* line 29, src/scss/_components/homepage/_main.scss */
  .main__bg-grid > div {
    right: 5%;
  }
}

@media screen and (max-width: 1439px) {
  /* line 29, src/scss/_components/homepage/_main.scss */
  .main__bg-grid > div {
    right: 2%;
  }
}

@media screen and (max-width: 1279px) {
  /* line 29, src/scss/_components/homepage/_main.scss */
  .main__bg-grid > div {
    width: 568px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 29, src/scss/_components/homepage/_main.scss */
  .main__bg-grid > div {
    right: 1%;
    width: 428px;
  }
}

/* line 53, src/scss/_components/homepage/_main.scss */
.main__bg-grid > div > div {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 50px;
  padding-right: 50px;
}

@media screen and (max-width: 1439px) {
  /* line 53, src/scss/_components/homepage/_main.scss */
  .main__bg-grid > div > div {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media screen and (max-width: 1279px) {
  /* line 53, src/scss/_components/homepage/_main.scss */
  .main__bg-grid > div > div {
    padding-left: 0;
    padding-right: 0;
  }
}

/* line 79, src/scss/_components/homepage/_main.scss */
.main__bg-grid > div > div#video2Wrapper > div {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0 50px;
  transform: translate(11px, 35px);
}

@media screen and (max-width: 1279px) {
  /* line 79, src/scss/_components/homepage/_main.scss */
  .main__bg-grid > div > div#video2Wrapper > div {
    left: -35px;
    right: -35px;
    width: calc(100% + 70px);
    padding: 0 25px;
    transform: translate(11px, 33px);
  }
}

/* line 97, src/scss/_components/homepage/_main.scss */
.main__bg-grid > div > div#video2Wrapper video {
  width: 100%;
}

/* line 101, src/scss/_components/homepage/_main.scss */
.main__bg-grid > div > div#video3Wrapper {
  padding-bottom: 75px;
}

/* line 105, src/scss/_components/homepage/_main.scss */
.main__bg-grid > div > div#video1Wrapper {
  opacity: 1;
}

/* line 108, src/scss/_components/homepage/_main.scss */
.main__bg-grid > div > div#video1Wrapper video {
  z-index: 0;
  position: relative;
}

/* line 113, src/scss/_components/homepage/_main.scss */
.main__bg-grid > div > div#video1Wrapper #video1WrapperBorder {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* line 119, src/scss/_components/homepage/_main.scss */
.main__bg-grid > div > div#video1Wrapper #video1WrapperBorder i {
  z-index: 5;
  overflow: hidden;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(10px, 30px) !important;
}

@media screen and (max-width: 1279px) {
  /* line 119, src/scss/_components/homepage/_main.scss */
  .main__bg-grid > div > div#video1Wrapper #video1WrapperBorder i {
    transform: translate(9px, 27px) !important;
  }
}

@media screen and (max-width: 1023px) {
  /* line 119, src/scss/_components/homepage/_main.scss */
  .main__bg-grid > div > div#video1Wrapper #video1WrapperBorder i {
    transform: translate(6px, 20px) !important;
  }
}

/* line 134, src/scss/_components/homepage/_main.scss */
.main__bg-grid > div > div#video1Wrapper #video1WrapperBorder i video {
  position: fixed;
  width: 100%;
  max-width: 650px;
  margin-left: auto;
  transform-origin: center;
}

/* line 145, src/scss/_components/homepage/_main.scss */
.main__bg-grid > div > div#video2Wrapper, .main__bg-grid > div > div#video3Wrapper {
  opacity: 1;
}

/* line 150, src/scss/_components/homepage/_main.scss */
.main__bg-grid > div > div#mainSVG1 {
  z-index: 1;
  opacity: 1;
}

/* line 155, src/scss/_components/homepage/_main.scss */
.main__bg-grid > div > div#mainSVG2 {
  z-index: 2;
  opacity: 1;
}

/* line 160, src/scss/_components/homepage/_main.scss */
.main__bg-grid > div > div#mainSVG3 {
  z-index: 4;
  opacity: 1;
}

/* line 165, src/scss/_components/homepage/_main.scss */
.main__bg-grid > div > div#mainSVG4 {
  opacity: 1;
}

/* line 169, src/scss/_components/homepage/_main.scss */
.main__bg-grid > div > div video,
.main__bg-grid > div > div img,
.main__bg-grid > div > div svg {
  display: block;
  width: 650px;
}

/* line 180, src/scss/_components/homepage/_main.scss */
.main__wrapper {
  display: flex;
  height: 2200px;
}

/* line 187, src/scss/_components/homepage/_main.scss */
.main__wrapper-1 .main__wrapper-left {
  padding-top: 50px;
}

/* line 192, src/scss/_components/homepage/_main.scss */
.main__wrapper-5 .main__wrapper-left {
  padding-bottom: 20px;
}

/* line 199, src/scss/_components/homepage/_main.scss */
.main__wrapper:last-of-type .main__wrapper-left {
  transform: translateY(0);
}

/* line 205, src/scss/_components/homepage/_main.scss */
.main__wrapper-left {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 50%;
  height: 100%;
  backface-visibility: hidden;
  transform-origin: center;
  transform: translateY(100%);
}

@media screen and (max-width: 1023px) {
  /* line 205, src/scss/_components/homepage/_main.scss */
  .main__wrapper-left {
    max-width: 45%;
  }
}

/* line 223, src/scss/_components/homepage/_main.scss */
.main__wrapper-left > div {
  width: 100%;
  max-width: 465px;
}

/* line 230, src/scss/_components/homepage/_main.scss */
.main__title {
  font-weight: bold;
  font-size: 72px;
  line-height: 109px;
  color: #121E31;
}

@media screen and (max-width: 1439px) {
  /* line 230, src/scss/_components/homepage/_main.scss */
  .main__title {
    font-size: 62px;
    line-height: 99px;
  }
}

@media screen and (max-width: 1279px) {
  /* line 230, src/scss/_components/homepage/_main.scss */
  .main__title {
    font-size: 52px;
    line-height: 89px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 230, src/scss/_components/homepage/_main.scss */
  .main__title {
    font-size: 48px;
    line-height: 82px;
  }
}

@media screen and (max-width: 767px) {
  /* line 230, src/scss/_components/homepage/_main.scss */
  .main__title {
    font-size: 30px;
    line-height: 40px;
    text-align: center;
  }
}

/* line 254, src/scss/_components/homepage/_main.scss */
.main__subtitle {
  padding-bottom: 20px;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  color: #121E31;
}

@media screen and (max-width: 1439px) {
  /* line 254, src/scss/_components/homepage/_main.scss */
  .main__subtitle {
    font-size: 33px;
    line-height: 44px;
  }
}

@media screen and (max-width: 1279px) {
  /* line 254, src/scss/_components/homepage/_main.scss */
  .main__subtitle {
    font-size: 30px;
    line-height: 40px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 254, src/scss/_components/homepage/_main.scss */
  .main__subtitle {
    font-size: 26px;
    line-height: 36px;
  }
}

@media screen and (max-width: 767px) {
  /* line 254, src/scss/_components/homepage/_main.scss */
  .main__subtitle {
    padding-bottom: 10px;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
  }
}

/* line 280, src/scss/_components/homepage/_main.scss */
.main__desc {
  padding-bottom: 60px;
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  line-height: 30px;
  color: #636B73;
}

@media screen and (max-width: 1439px) {
  /* line 280, src/scss/_components/homepage/_main.scss */
  .main__desc {
    font-size: 18px;
    line-height: 28px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 280, src/scss/_components/homepage/_main.scss */
  .main__desc {
    font-size: 16px;
    line-height: 26px;
  }
}

@media screen and (max-width: 767px) {
  /* line 280, src/scss/_components/homepage/_main.scss */
  .main__desc {
    width: 100%;
    max-width: 265px;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
  }
}

/* line 306, src/scss/_components/homepage/_main.scss */
.main__heading {
  font-weight: bold;
  font-size: 48px;
  line-height: 73px;
  color: #121E31;
}

@media screen and (max-width: 1439px) {
  /* line 306, src/scss/_components/homepage/_main.scss */
  .main__heading {
    font-size: 44px;
    line-height: 63px;
  }
}

@media screen and (max-width: 1279px) {
  /* line 306, src/scss/_components/homepage/_main.scss */
  .main__heading {
    font-size: 40px;
    line-height: 60px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 306, src/scss/_components/homepage/_main.scss */
  .main__heading {
    font-size: 35px;
    line-height: 52px;
  }
}

@media screen and (max-width: 767px) {
  /* line 306, src/scss/_components/homepage/_main.scss */
  .main__heading {
    font-size: 26px;
    line-height: 40px;
  }
}

/* line 329, src/scss/_components/homepage/_main.scss */
.main__label {
  padding-bottom: 20px;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #121E31;
}

@media screen and (max-width: 1439px) {
  /* line 329, src/scss/_components/homepage/_main.scss */
  .main__label {
    font-size: 22px;
    line-height: 32px;
  }
}

@media screen and (max-width: 767px) {
  /* line 329, src/scss/_components/homepage/_main.scss */
  .main__label {
    font-size: 18px;
    line-height: 26px;
  }
}

/* line 345, src/scss/_components/homepage/_main.scss */
.main .c-btn {
  min-width: 200px;
  height: 69px;
}

@media screen and (max-width: 1439px) {
  /* line 345, src/scss/_components/homepage/_main.scss */
  .main .c-btn {
    height: 63px;
  }
}

@media screen and (max-width: 1279px) {
  /* line 345, src/scss/_components/homepage/_main.scss */
  .main .c-btn {
    height: 60px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 345, src/scss/_components/homepage/_main.scss */
  .main .c-btn {
    min-width: 175px;
    height: 57px;
  }
}

/* line 359, src/scss/_components/homepage/_main.scss */
.main .c-btn span {
  font-weight: bold;
  font-size: 17px;
}

@media screen and (max-width: 1023px) {
  /* line 359, src/scss/_components/homepage/_main.scss */
  .main .c-btn span {
    font-size: 15px;
  }
}

/* line 369, src/scss/_components/homepage/_main.scss */
.submain {
  display: none;
  padding-top: 80px;
  padding-bottom: 50px;
}

@media screen and (max-width: 767px) {
  /* line 369, src/scss/_components/homepage/_main.scss */
  .submain {
    display: flex;
  }
}

/* line 377, src/scss/_components/homepage/_main.scss */
.submain__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

@media screen and (orientation: landscape) {
  /* line 377, src/scss/_components/homepage/_main.scss */
  .submain__wrapper {
    flex-direction: row;
    padding-top: 30px;
  }
  /* line 387, src/scss/_components/homepage/_main.scss */
  .submain__wrapper .submain__wrapper-left,
  .submain__wrapper .submain__wrapper-right {
    flex: 1;
    min-width: 1px;
    max-width: 100%;
  }
  /* line 394, src/scss/_components/homepage/_main.scss */
  .submain__wrapper .submain__wrapper-left .c-btn__wrapper,
  .submain__wrapper .submain__wrapper-right .c-btn__wrapper {
    padding-bottom: 0;
  }
  /* line 400, src/scss/_components/homepage/_main.scss */
  .submain__wrapper .submain__wrapper-left {
    padding-right: 15px;
    padding-bottom: 0;
  }
}

/* line 406, src/scss/_components/homepage/_main.scss */
.submain__wrapper.submain__wrapper-1 {
  padding-top: 30px;
}

/* line 409, src/scss/_components/homepage/_main.scss */
.submain__wrapper.submain__wrapper-1 .submain__wrapper-right {
  padding-bottom: 30px;
}

/* line 415, src/scss/_components/homepage/_main.scss */
.submain__wrapper.submain__wrapper-5 .submain__wrapper-right {
  overflow: hidden;
}

/* line 418, src/scss/_components/homepage/_main.scss */
.submain__wrapper.submain__wrapper-5 .submain__wrapper-right video {
  position: relative;
  width: calc(100% + 140px);
  max-width: unset;
}

/* line 427, src/scss/_components/homepage/_main.scss */
.submain__wrapper:not(:first-of-type) .submain__wrapper-left {
  align-items: flex-start;
}

/* line 431, src/scss/_components/homepage/_main.scss */
.submain__wrapper:not(:first-of-type) .main__heading,
.submain__wrapper:not(:first-of-type) .main__label,
.submain__wrapper:not(:first-of-type) .main__desc {
  text-align: left;
}

/* line 437, src/scss/_components/homepage/_main.scss */
.submain__wrapper:not(:first-of-type) .main__desc {
  max-width: 100%;
}

/* line 442, src/scss/_components/homepage/_main.scss */
.submain__wrapper-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 50px;
}

/* line 451, src/scss/_components/homepage/_main.scss */
.submain__wrapper-left .c-btn__wrapper {
  padding-bottom: 0 !important;
}

/* line 457, src/scss/_components/homepage/_main.scss */
.submain__wrapper-right {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* line 462, src/scss/_components/homepage/_main.scss */
.submain__wrapper-right img,
.submain__wrapper-right video {
  opacity: 0;
  display: block;
  max-width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 767px) {
  /* line 473, src/scss/_components/homepage/_main.scss */
  .submain__wrapper .c-btn {
    width: 100%;
  }
  /* line 477, src/scss/_components/homepage/_main.scss */
  .submain__wrapper .c-btn__wrapper {
    width: 100%;
    padding-bottom: 35px;
  }
  /* line 482, src/scss/_components/homepage/_main.scss */
  .submain__wrapper .c-btn span {
    font-size: 14px;
  }
}

/* line 3, src/scss/_components/homepage/_foundation.scss */
.foundation {
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
}

@media screen and (max-width: 767px) {
  /* line 3, src/scss/_components/homepage/_foundation.scss */
  .foundation {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

/* line 16, src/scss/_components/homepage/_foundation.scss */
.foundation__title {
  padding-bottom: 70px;
}

@media screen and (max-width: 767px) {
  /* line 16, src/scss/_components/homepage/_foundation.scss */
  .foundation__title {
    padding-bottom: 40px;
  }
}

/* line 23, src/scss/_components/homepage/_foundation.scss */
.foundation__subtitle {
  padding-bottom: 30px;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #121E31;
}

@media screen and (max-width: 1365px) {
  /* line 23, src/scss/_components/homepage/_foundation.scss */
  .foundation__subtitle {
    font-size: 22px;
    line-height: 30px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 23, src/scss/_components/homepage/_foundation.scss */
  .foundation__subtitle {
    font-size: 18px;
    line-height: normal;
  }
}

@media screen and (max-width: 767px) {
  /* line 23, src/scss/_components/homepage/_foundation.scss */
  .foundation__subtitle {
    padding-bottom: 25px;
  }
}

/* line 42, src/scss/_components/homepage/_foundation.scss */
.foundation__desc {
  padding-bottom: 50px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 26px;
  color: #636B73;
}

@media screen and (max-width: 1023px) {
  /* line 42, src/scss/_components/homepage/_foundation.scss */
  .foundation__desc {
    font-size: 14px;
    line-height: 22px;
  }
}

@media screen and (max-width: 767px) {
  /* line 42, src/scss/_components/homepage/_foundation.scss */
  .foundation__desc {
    padding-bottom: 10px;
  }
}

/* line 58, src/scss/_components/homepage/_foundation.scss */
.foundation__describe {
  position: relative;
  left: -15px;
  width: calc(100% + 15px);
  padding-top: 50px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #636B73;
}

@media screen and (max-width: 1279px) {
  /* line 58, src/scss/_components/homepage/_foundation.scss */
  .foundation__describe {
    left: 0;
    width: 100%;
  }
}

@media screen and (max-width: 1023px) {
  /* line 58, src/scss/_components/homepage/_foundation.scss */
  .foundation__describe {
    font-size: 14px;
    line-height: 24px;
  }
}

@media screen and (max-width: 767px) {
  /* line 58, src/scss/_components/homepage/_foundation.scss */
  .foundation__describe {
    padding-top: 20px;
  }
}

/* line 81, src/scss/_components/homepage/_foundation.scss */
.foundation__tab {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 1px;
  max-width: 100%;
  padding-left: 60px;
}

@media screen and (max-width: 1279px) {
  /* line 81, src/scss/_components/homepage/_foundation.scss */
  .foundation__tab {
    padding-left: 30px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 81, src/scss/_components/homepage/_foundation.scss */
  .foundation__tab {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 767px) {
  /* line 81, src/scss/_components/homepage/_foundation.scss */
  .foundation__tab {
    flex-direction: column;
  }
}

/* line 100, src/scss/_components/homepage/_foundation.scss */
.foundation__tab:after {
  content: '';
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  transform: scaleY(0);
  position: absolute;
  left: 0;
  bottom: -30px;
  width: 100%;
  height: 4px;
  background-color: #4097D9;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, transform 0.45s 0.3s ease-in-out;
}

/* line 118, src/scss/_components/homepage/_foundation.scss */
.foundation__tab:hover > div:nth-of-type(1), .foundation__tab.is-active > div:nth-of-type(1) {
  background-color: rgba(64, 151, 217, 0.1);
  transition: background-color 0.3s ease-in-out;
}

/* line 122, src/scss/_components/homepage/_foundation.scss */
.foundation__tab:hover > div:nth-of-type(1) .icon-font, .foundation__tab.is-active > div:nth-of-type(1) .icon-font {
  color: #4097D9;
  transition: color 0.3s ease-in-out;
}

/* line 128, src/scss/_components/homepage/_foundation.scss */
.foundation__tab:hover > div p, .foundation__tab.is-active > div p {
  color: #121E31;
  transition: color 0.3s ease-in-out;
}

/* line 136, src/scss/_components/homepage/_foundation.scss */
.foundation__tab.is-active:after {
  opacity: 1;
  visibility: visible;
  transform: scaleY(1);
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, transform 0.45s 0.3s ease-in-out;
}

/* line 145, src/scss/_components/homepage/_foundation.scss */
.foundation__tab > div:nth-of-type(1) {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background-color: rgba(196, 207, 221, 0.15);
  transition: background-color 0.3s ease-in-out;
}

@media screen and (max-width: 767px) {
  /* line 145, src/scss/_components/homepage/_foundation.scss */
  .foundation__tab > div:nth-of-type(1) {
    width: 40px;
    height: 40px;
  }
}

/* line 159, src/scss/_components/homepage/_foundation.scss */
.foundation__tab > div:nth-of-type(1) .icon-font {
  color: #6e7e92;
  font-size: 15px;
  transition: color 0.3s ease-in-out;
}

@media screen and (max-width: 767px) {
  /* line 159, src/scss/_components/homepage/_foundation.scss */
  .foundation__tab > div:nth-of-type(1) .icon-font {
    font-size: 12px;
  }
}

/* line 167, src/scss/_components/homepage/_foundation.scss */
.foundation__tab > div:nth-of-type(1) .icon-font.icon-rocket {
  font-size: 22px;
}

@media screen and (max-width: 767px) {
  /* line 167, src/scss/_components/homepage/_foundation.scss */
  .foundation__tab > div:nth-of-type(1) .icon-font.icon-rocket {
    font-size: 18px;
  }
}

/* line 176, src/scss/_components/homepage/_foundation.scss */
.foundation__tab > div:nth-of-type(2) {
  flex: 1;
  min-width: 1px;
  max-width: 100%;
  padding-left: 20px;
}

@media screen and (max-width: 767px) {
  /* line 176, src/scss/_components/homepage/_foundation.scss */
  .foundation__tab > div:nth-of-type(2) {
    padding-left: 0;
  }
}

/* line 186, src/scss/_components/homepage/_foundation.scss */
.foundation__tab > div p {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #6e7e92;
  transition: color 0.3s ease-in-out;
}

@media screen and (max-width: 1365px) {
  /* line 186, src/scss/_components/homepage/_foundation.scss */
  .foundation__tab > div p {
    font-size: 18px;
  }
}

@media screen and (max-width: 767px) {
  /* line 186, src/scss/_components/homepage/_foundation.scss */
  .foundation__tab > div p {
    padding-top: 10px;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
  }
}

/* line 205, src/scss/_components/homepage/_foundation.scss */
.foundation__tab-wrapper {
  display: flex;
  flex-direction: column;
}

/* line 210, src/scss/_components/homepage/_foundation.scss */
.foundation__tab-header {
  position: relative;
  display: flex;
  align-items: center;
  padding-bottom: 60px;
}

/* line 216, src/scss/_components/homepage/_foundation.scss */
.foundation__tab-header:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 31px;
  width: 100%;
  height: 2px;
  background-color: #dff1ff;
}

/* line 227, src/scss/_components/homepage/_foundation.scss */
.foundation__tab-body {
  padding-top: 40px;
  padding-bottom: 30px;
}

@media screen and (max-width: 767px) {
  /* line 227, src/scss/_components/homepage/_foundation.scss */
  .foundation__tab-body {
    padding-top: 0;
    padding-bottom: 0;
  }
}

/* line 236, src/scss/_components/homepage/_foundation.scss */
.foundation__tab-content {
  display: none;
}

/* line 241, src/scss/_components/homepage/_foundation.scss */
.foundation__box {
  display: flex;
  height: 100%;
  padding: 20px;
  border: 2px solid #DFF1FF;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(4px);
}

/* line 250, src/scss/_components/homepage/_foundation.scss */
.foundation__box > div {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px 24px 20px 30px;
  border-radius: 10px;
  box-shadow: 0px 4px 70px rgba(192, 218, 238, 0.5);
  background-color: #FFFFFF;
}

@media screen and (max-width: 1023px) {
  /* line 250, src/scss/_components/homepage/_foundation.scss */
  .foundation__box > div {
    padding: 20px;
  }
}

/* line 263, src/scss/_components/homepage/_foundation.scss */
.foundation__box > div > div:nth-of-type(2) {
  flex: 1;
  min-height: 1px;
  max-height: 100%;
}

/* line 271, src/scss/_components/homepage/_foundation.scss */
.foundation__box-wrapper {
  position: relative;
  left: -20px;
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 20px);
}

@media screen and (max-width: 767px) {
  /* line 271, src/scss/_components/homepage/_foundation.scss */
  .foundation__box-wrapper {
    left: 0;
    width: 100%;
    flex-wrap: wrap;
  }
}

/* line 285, src/scss/_components/homepage/_foundation.scss */
.foundation__box-wrapper-1 > div:nth-of-type(1) {
  width: calc(100% + 20px);
  padding-left: 5px;
}

@media screen and (max-width: 1279px) {
  /* line 285, src/scss/_components/homepage/_foundation.scss */
  .foundation__box-wrapper-1 > div:nth-of-type(1) {
    padding-left: 20px;
  }
}

@media screen and (max-width: 767px) {
  /* line 285, src/scss/_components/homepage/_foundation.scss */
  .foundation__box-wrapper-1 > div:nth-of-type(1) {
    width: 100%;
    padding-left: 0;
  }
}

/* line 301, src/scss/_components/homepage/_foundation.scss */
.foundation__box-wrapper-2 > div:nth-of-type(1) {
  max-width: calc((100% / 3) * 2);
  padding-left: 5px;
}

@media screen and (max-width: 1279px) {
  /* line 301, src/scss/_components/homepage/_foundation.scss */
  .foundation__box-wrapper-2 > div:nth-of-type(1) {
    padding-left: 20px;
  }
}

@media screen and (max-width: 767px) {
  /* line 301, src/scss/_components/homepage/_foundation.scss */
  .foundation__box-wrapper-2 > div:nth-of-type(1) {
    max-width: 100%;
    padding-left: 0;
  }
}

/* line 317, src/scss/_components/homepage/_foundation.scss */
.foundation__box-wrapper-3 > div:nth-of-type(1) {
  max-width: 100%;
  padding-left: 5px;
}

@media screen and (max-width: 1279px) {
  /* line 317, src/scss/_components/homepage/_foundation.scss */
  .foundation__box-wrapper-3 > div:nth-of-type(1) {
    padding-left: 20px;
  }
}

@media screen and (max-width: 767px) {
  /* line 317, src/scss/_components/homepage/_foundation.scss */
  .foundation__box-wrapper-3 > div:nth-of-type(1) {
    padding-left: 0;
  }
}

/* line 330, src/scss/_components/homepage/_foundation.scss */
.foundation__box-wrapper > div {
  position: relative;
  width: 100%;
  max-width: calc(100% / 3);
  padding-left: 20px;
  padding-bottom: 20px;
}

@media screen and (max-width: 767px) {
  /* line 330, src/scss/_components/homepage/_foundation.scss */
  .foundation__box-wrapper > div {
    max-width: 100%;
    padding-left: 0;
  }
}

/* line 343, src/scss/_components/homepage/_foundation.scss */
.foundation__box-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 8px;
  background-color: rgba(64, 151, 217, 0.1);
}

/* line 353, src/scss/_components/homepage/_foundation.scss */
.foundation__box-icon .icon-font {
  color: #4097d9;
  font-size: 20px;
}

/* line 359, src/scss/_components/homepage/_foundation.scss */
.foundation__box-title {
  padding-top: 20px;
  padding-bottom: 10px;
  font-size: 18px;
  line-height: 27px;
  color: #121E31;
}

@media screen and (max-width: 767px) {
  /* line 359, src/scss/_components/homepage/_foundation.scss */
  .foundation__box-title {
    font-size: 16px;
    line-height: 24px;
  }
}

/* line 371, src/scss/_components/homepage/_foundation.scss */
.foundation__box-desc {
  padding-bottom: 43px;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: #636B73;
}

@media screen and (max-width: 767px) {
  /* line 371, src/scss/_components/homepage/_foundation.scss */
  .foundation__box-desc {
    font-size: 13px;
    line-height: 20px;
  }
}

/* line 383, src/scss/_components/homepage/_foundation.scss */
.foundation__box-link {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #4097D9;
}

@media screen and (max-width: 767px) {
  /* line 383, src/scss/_components/homepage/_foundation.scss */
  .foundation__box-link {
    font-size: 13px;
    line-height: 20px;
  }
}

/* line 3, src/scss/_components/homepage/_solution.scss */
.solution {
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #0a0a0a;
}

@media screen and (max-width: 767px) {
  /* line 3, src/scss/_components/homepage/_solution.scss */
  .solution {
    padding-top: 80px;
    padding-bottom: 50px;
  }
}

/* line 17, src/scss/_components/homepage/_solution.scss */
.solution__title {
  padding-bottom: 70px;
  color: #fff;
}

@media screen and (max-width: 767px) {
  /* line 17, src/scss/_components/homepage/_solution.scss */
  .solution__title {
    padding-bottom: 40px;
  }
}

/* line 25, src/scss/_components/homepage/_solution.scss */
.solution__tab {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 1px;
  max-width: 100%;
  padding-left: 60px;
}

@media screen and (max-width: 1279px) {
  /* line 25, src/scss/_components/homepage/_solution.scss */
  .solution__tab {
    padding-left: 30px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 25, src/scss/_components/homepage/_solution.scss */
  .solution__tab {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 767px) {
  /* line 25, src/scss/_components/homepage/_solution.scss */
  .solution__tab {
    flex-direction: column;
  }
}

/* line 44, src/scss/_components/homepage/_solution.scss */
.solution__tab:after {
  content: '';
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  transform: scaleY(0);
  position: absolute;
  left: 0;
  bottom: -30px;
  width: 100%;
  height: 4px;
  background-color: #00B94F;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, transform 0.45s 0.3s ease-in-out;
}

/* line 62, src/scss/_components/homepage/_solution.scss */
.solution__tab:hover > div:nth-of-type(1), .solution__tab.is-active > div:nth-of-type(1) {
  background-color: rgba(0, 185, 79, 0.1);
  transition: background-color 0.3s ease-in-out;
}

/* line 66, src/scss/_components/homepage/_solution.scss */
.solution__tab:hover > div:nth-of-type(1) .icon-font, .solution__tab.is-active > div:nth-of-type(1) .icon-font {
  color: #00B94F;
  transition: color 0.3s ease-in-out;
}

/* line 72, src/scss/_components/homepage/_solution.scss */
.solution__tab:hover > div span, .solution__tab.is-active > div span {
  color: #00B94F;
  transition: color 0.3s ease-in-out;
}

/* line 77, src/scss/_components/homepage/_solution.scss */
.solution__tab:hover > div p, .solution__tab.is-active > div p {
  color: #fff;
  transition: color 0.3s ease-in-out;
}

/* line 85, src/scss/_components/homepage/_solution.scss */
.solution__tab.is-active:after {
  opacity: 1;
  visibility: visible;
  transform: scaleY(1);
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, transform 0.45s 0.3s ease-in-out;
}

/* line 94, src/scss/_components/homepage/_solution.scss */
.solution__tab > div:nth-of-type(1) {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background-color: rgba(196, 207, 221, 0.15);
  transition: background-color 0.3s ease-in-out;
}

@media screen and (max-width: 767px) {
  /* line 94, src/scss/_components/homepage/_solution.scss */
  .solution__tab > div:nth-of-type(1) {
    width: 40px;
    height: 40px;
  }
}

/* line 108, src/scss/_components/homepage/_solution.scss */
.solution__tab > div:nth-of-type(1) .icon-font {
  color: rgba(255, 255, 255, 0.5);
  font-size: 15px;
  transition: color 0.3s ease-in-out;
}

@media screen and (max-width: 767px) {
  /* line 108, src/scss/_components/homepage/_solution.scss */
  .solution__tab > div:nth-of-type(1) .icon-font {
    font-size: 12px;
  }
}

/* line 116, src/scss/_components/homepage/_solution.scss */
.solution__tab > div:nth-of-type(1) .icon-font.icon-rocket {
  font-size: 22px;
}

@media screen and (max-width: 767px) {
  /* line 116, src/scss/_components/homepage/_solution.scss */
  .solution__tab > div:nth-of-type(1) .icon-font.icon-rocket {
    font-size: 18px;
  }
}

/* line 125, src/scss/_components/homepage/_solution.scss */
.solution__tab > div:nth-of-type(2) {
  flex: 1;
  min-width: 1px;
  max-width: 100%;
  padding-left: 20px;
}

@media screen and (max-width: 767px) {
  /* line 125, src/scss/_components/homepage/_solution.scss */
  .solution__tab > div:nth-of-type(2) {
    padding-left: 0;
  }
}

/* line 135, src/scss/_components/homepage/_solution.scss */
.solution__tab > div span {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.5);
  transition: color 0.3s ease-in-out;
}

@media screen and (max-width: 767px) {
  /* line 135, src/scss/_components/homepage/_solution.scss */
  .solution__tab > div span {
    display: block;
    padding-top: 20px;
    padding-bottom: 6px;
    text-align: center;
    font-size: 10px;
  }
}

/* line 150, src/scss/_components/homepage/_solution.scss */
.solution__tab > div p {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: rgba(255, 255, 255, 0.5);
  transition: color 0.3s ease-in-out;
}

@media screen and (max-width: 1365px) {
  /* line 150, src/scss/_components/homepage/_solution.scss */
  .solution__tab > div p {
    font-size: 18px;
  }
}

@media screen and (max-width: 767px) {
  /* line 150, src/scss/_components/homepage/_solution.scss */
  .solution__tab > div p {
    font-size: 14px;
    line-height: 19px;
    text-align: center;
  }
}

/* line 168, src/scss/_components/homepage/_solution.scss */
.solution__tab-wrapper {
  display: flex;
  flex-direction: column;
}

/* line 173, src/scss/_components/homepage/_solution.scss */
.solution__tab-header {
  position: relative;
  display: flex;
  align-items: center;
  padding-bottom: 60px;
}

/* line 179, src/scss/_components/homepage/_solution.scss */
.solution__tab-header:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 31px;
  width: 100%;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.2);
}

/* line 190, src/scss/_components/homepage/_solution.scss */
.solution__tab-body {
  padding-bottom: 30px;
}

@media screen and (max-width: 767px) {
  /* line 190, src/scss/_components/homepage/_solution.scss */
  .solution__tab-body {
    padding-bottom: 0;
  }
}

/* line 197, src/scss/_components/homepage/_solution.scss */
.solution__tab-content {
  display: none;
}

/* line 201, src/scss/_components/homepage/_solution.scss */
.solution__tab-desc {
  display: flex;
  justify-content: space-between;
  padding-bottom: 60px;
}

@media screen and (max-width: 767px) {
  /* line 201, src/scss/_components/homepage/_solution.scss */
  .solution__tab-desc {
    flex-wrap: wrap;
    padding-bottom: 20px;
  }
}

/* line 210, src/scss/_components/homepage/_solution.scss */
.solution__tab-desc > div {
  width: 100%;
  max-width: calc((100% - 40px) / 2);
}

@media screen and (max-width: 767px) {
  /* line 210, src/scss/_components/homepage/_solution.scss */
  .solution__tab-desc > div {
    max-width: 100%;
  }
}

/* line 218, src/scss/_components/homepage/_solution.scss */
.solution__tab-desc p {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 26px;
  color: #FFFFFF;
}

@media screen and (max-width: 767px) {
  /* line 218, src/scss/_components/homepage/_solution.scss */
  .solution__tab-desc p {
    font-size: 14px;
    line-height: 24px;
  }
}

/* line 232, src/scss/_components/homepage/_solution.scss */
.solution__list {
  display: flex;
  flex-direction: column;
}

/* line 236, src/scss/_components/homepage/_solution.scss */
.solution__list li {
  display: flex;
  align-items: center;
}

/* line 240, src/scss/_components/homepage/_solution.scss */
.solution__list li:not(:last-of-type) {
  padding-bottom: 15px;
}

/* line 245, src/scss/_components/homepage/_solution.scss */
.solution__list li > div:nth-of-type(1) {
  width: 40px;
}

/* line 248, src/scss/_components/homepage/_solution.scss */
.solution__list li > div:nth-of-type(1) .icon-font {
  font-size: 10px;
  color: #00B94F;
}

/* line 253, src/scss/_components/homepage/_solution.scss */
.solution__list li > div:nth-of-type(2) {
  flex: 1;
  min-width: 1px;
  max-width: 100%;
}

/* line 261, src/scss/_components/homepage/_solution.scss */
.solution__list p {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
}

@media screen and (max-width: 767px) {
  /* line 261, src/scss/_components/homepage/_solution.scss */
  .solution__list p {
    font-size: 14px;
    line-height: 19px;
  }
}

/* line 272, src/scss/_components/homepage/_solution.scss */
.solution__list-wrapper {
  position: relative;
  display: flex;
  padding: 40px 30px;
  background-color: rgba(255, 255, 255, 0.04);
  margin-bottom: 50px;
}

@media screen and (max-width: 1023px) {
  /* line 272, src/scss/_components/homepage/_solution.scss */
  .solution__list-wrapper {
    padding: 30px 20px;
  }
}

@media screen and (max-width: 767px) {
  /* line 272, src/scss/_components/homepage/_solution.scss */
  .solution__list-wrapper {
    left: -15px;
    flex-wrap: wrap;
    width: calc(100% + 30px);
    padding: 30px 15px;
  }
}

/* line 288, src/scss/_components/homepage/_solution.scss */
.solution__list-wrapper > div {
  position: relative;
  width: 50%;
}

@media screen and (max-width: 767px) {
  /* line 288, src/scss/_components/homepage/_solution.scss */
  .solution__list-wrapper > div {
    width: 100%;
  }
}

/* line 295, src/scss/_components/homepage/_solution.scss */
.solution__list-wrapper > div:nth-of-type(1) {
  padding-right: 60px;
}

@media screen and (max-width: 1023px) {
  /* line 295, src/scss/_components/homepage/_solution.scss */
  .solution__list-wrapper > div:nth-of-type(1) {
    padding-right: 30px;
  }
}

@media screen and (max-width: 767px) {
  /* line 295, src/scss/_components/homepage/_solution.scss */
  .solution__list-wrapper > div:nth-of-type(1) {
    padding-right: 0;
    padding-bottom: 15px;
  }
}

/* line 305, src/scss/_components/homepage/_solution.scss */
.solution__list-wrapper > div:nth-of-type(1):after {
  content: '';
  position: absolute;
  top: 30px;
  right: 0;
  width: 1px;
  height: calc(100% - 60px);
  background: rgba(196, 196, 196, 0.07);
}

@media screen and (max-width: 767px) {
  /* line 305, src/scss/_components/homepage/_solution.scss */
  .solution__list-wrapper > div:nth-of-type(1):after {
    display: none;
  }
}

/* line 318, src/scss/_components/homepage/_solution.scss */
.solution__list-wrapper > div:nth-of-type(2) {
  padding-left: 60px;
}

@media screen and (max-width: 1023px) {
  /* line 318, src/scss/_components/homepage/_solution.scss */
  .solution__list-wrapper > div:nth-of-type(2) {
    padding-left: 30px;
  }
}

@media screen and (max-width: 767px) {
  /* line 318, src/scss/_components/homepage/_solution.scss */
  .solution__list-wrapper > div:nth-of-type(2) {
    padding-left: 0;
  }
}

/* line 332, src/scss/_components/homepage/_solution.scss */
.solution__slider-wrapper {
  display: flex;
  flex-direction: column;
}

/* line 337, src/scss/_components/homepage/_solution.scss */
.solution__slider-head {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
}

@media screen and (max-width: 767px) {
  /* line 337, src/scss/_components/homepage/_solution.scss */
  .solution__slider-head {
    overflow-x: auto;
    white-space: nowrap;
    position: relative;
    left: -15px;
    padding-left: 20px;
    padding-bottom: 20px;
    display: block;
    width: calc(100% + 30px);
  }
}

/* line 353, src/scss/_components/homepage/_solution.scss */
.solution__slider-head a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 35px;
  padding-right: 35px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #FFFFFF;
}

@media screen and (max-width: 1023px) {
  /* line 353, src/scss/_components/homepage/_solution.scss */
  .solution__slider-head a {
    padding-left: 25px;
    padding-right: 25px;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 12px;
  }
}

@media screen and (max-width: 767px) {
  /* line 353, src/scss/_components/homepage/_solution.scss */
  .solution__slider-head a {
    display: inline-block;
    height: 40px;
    margin-left: 4px;
    margin-right: 4px;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 40px;
  }
  /* line 386, src/scss/_components/homepage/_solution.scss */
  .solution__slider-head a:last-of-type {
    margin-right: 11px;
  }
}

/* line 391, src/scss/_components/homepage/_solution.scss */
.solution__slider-head a.is-active {
  background-color: #00B94F;
}

/* line 397, src/scss/_components/homepage/_solution.scss */
.solution__slider-body {
  display: flex;
  min-height: 100%;
}

@media screen and (max-width: 767px) {
  /* line 397, src/scss/_components/homepage/_solution.scss */
  .solution__slider-body {
    position: relative;
    flex-wrap: wrap;
  }
}

/* line 406, src/scss/_components/homepage/_solution.scss */
.solution__slider-body > div:nth-of-type(1) {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
}

@media screen and (max-width: 1279px) {
  /* line 406, src/scss/_components/homepage/_solution.scss */
  .solution__slider-body > div:nth-of-type(1) {
    width: 100px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 406, src/scss/_components/homepage/_solution.scss */
  .solution__slider-body > div:nth-of-type(1) {
    width: 75px;
  }
}

@media screen and (max-width: 767px) {
  /* line 406, src/scss/_components/homepage/_solution.scss */
  .solution__slider-body > div:nth-of-type(1) {
    order: 2;
    z-index: 10;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -15px;
    width: 45px;
    justify-content: flex-end;
    padding-right: 10px;
  }
}

/* line 430, src/scss/_components/homepage/_solution.scss */
.solution__slider-body > div:nth-of-type(2) {
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 1px;
  max-width: 100%;
  min-height: 100%;
  background-color: #FFFFFF;
  border-radius: 10px;
}

@media screen and (max-width: 767px) {
  /* line 430, src/scss/_components/homepage/_solution.scss */
  .solution__slider-body > div:nth-of-type(2) {
    order: 1;
    flex: 100%;
    flex-wrap: wrap;
  }
}

/* line 446, src/scss/_components/homepage/_solution.scss */
.solution__slider-body > div:nth-of-type(2) > div {
  width: 50%;
  min-height: 100%;
}

@media screen and (max-width: 767px) {
  /* line 446, src/scss/_components/homepage/_solution.scss */
  .solution__slider-body > div:nth-of-type(2) > div {
    width: 100%;
    min-height: unset;
    height: auto;
  }
}

/* line 455, src/scss/_components/homepage/_solution.scss */
.solution__slider-body > div:nth-of-type(2) > div:nth-of-type(1) {
  padding: 60px 30px 60px 60px;
}

@media screen and (max-width: 1279px) {
  /* line 455, src/scss/_components/homepage/_solution.scss */
  .solution__slider-body > div:nth-of-type(2) > div:nth-of-type(1) {
    padding: 30px 15px 30px 30px;
  }
}

@media screen and (max-width: 767px) {
  /* line 455, src/scss/_components/homepage/_solution.scss */
  .solution__slider-body > div:nth-of-type(2) > div:nth-of-type(1) {
    padding: 30px 30px 0;
  }
}

/* line 464, src/scss/_components/homepage/_solution.scss */
.solution__slider-body > div:nth-of-type(2) > div:nth-of-type(2) {
  padding: 60px 60px 30px 60px;
}

@media screen and (max-width: 1279px) {
  /* line 464, src/scss/_components/homepage/_solution.scss */
  .solution__slider-body > div:nth-of-type(2) > div:nth-of-type(2) {
    padding: 30px 30px 15px 30px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 464, src/scss/_components/homepage/_solution.scss */
  .solution__slider-body > div:nth-of-type(2) > div:nth-of-type(2) {
    padding-left: 0;
  }
}

@media screen and (max-width: 767px) {
  /* line 464, src/scss/_components/homepage/_solution.scss */
  .solution__slider-body > div:nth-of-type(2) > div:nth-of-type(2) {
    padding: 0 30px 30px;
  }
}

/* line 479, src/scss/_components/homepage/_solution.scss */
.solution__slider-body > div:nth-of-type(3) {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
}

@media screen and (max-width: 1279px) {
  /* line 479, src/scss/_components/homepage/_solution.scss */
  .solution__slider-body > div:nth-of-type(3) {
    width: 100px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 479, src/scss/_components/homepage/_solution.scss */
  .solution__slider-body > div:nth-of-type(3) {
    width: 75px;
  }
}

@media screen and (max-width: 767px) {
  /* line 479, src/scss/_components/homepage/_solution.scss */
  .solution__slider-body > div:nth-of-type(3) {
    order: 3;
    z-index: 10;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -15px;
    width: 45px;
    justify-content: flex-start;
    padding-left: 10px;
  }
}

/* line 505, src/scss/_components/homepage/_solution.scss */
.solution__slider-media {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 767px) {
  /* line 505, src/scss/_components/homepage/_solution.scss */
  .solution__slider-media {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
}

/* line 517, src/scss/_components/homepage/_solution.scss */
.solution__slider-media img {
  display: block;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

/* line 525, src/scss/_components/homepage/_solution.scss */
.solution__slider-footer {
  position: relative;
  transition: transform 1s;
  transform-style: preserve-3d;
}

/* line 531, src/scss/_components/homepage/_solution.scss */
.solution__slider-btn {
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  box-shadow: 0 10px 30px 0 rgba(22, 58, 95, 0.05);
  border-radius: 50px;
  background-color: #00b94f;
  transition: background-color 0.3s ease-in-out;
}

@media screen and (max-width: 767px) {
  /* line 531, src/scss/_components/homepage/_solution.scss */
  .solution__slider-btn {
    width: 35px;
    height: 35px;
  }
}

/* line 547, src/scss/_components/homepage/_solution.scss */
.solution__slider-btn.swiper-button-disabled {
  pointer-events: none;
  background-color: rgba(255, 255, 255, 0.2);
  transition: background-color 0.3s ease-in-out;
}

@media screen and (max-width: 767px) {
  /* line 547, src/scss/_components/homepage/_solution.scss */
  .solution__slider-btn.swiper-button-disabled {
    opacity: 0 !important;
  }
}

/* line 556, src/scss/_components/homepage/_solution.scss */
.solution__slider-btn--prev {
  padding-right: 4px;
}

/* line 560, src/scss/_components/homepage/_solution.scss */
.solution__slider-btn--next {
  padding-left: 4px;
}

/* line 562, src/scss/_components/homepage/_solution.scss */
.solution__slider-btn--next .icon-font {
  transform: scaleX(-1);
}

/* line 567, src/scss/_components/homepage/_solution.scss */
.solution__slider-btn .icon-font {
  color: #ffffff;
}

@media screen and (max-width: 767px) {
  /* line 567, src/scss/_components/homepage/_solution.scss */
  .solution__slider-btn .icon-font {
    font-size: 12px;
  }
}

/* line 575, src/scss/_components/homepage/_solution.scss */
.solution__slider-row {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.55s ease-in-out, visibility 0.3s ease-in-out;
}

/* line 599, src/scss/_components/homepage/_solution.scss */
.solution__slider-row.is-show {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.55s ease-in-out, visibility 0.3s ease-in-out;
}

/* line 612, src/scss/_components/homepage/_solution.scss */
.solution__slider-row:not(:first-of-type) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* line 623, src/scss/_components/homepage/_solution.scss */
.solution .swiper-wrapper {
  align-items: center;
}

/* line 626, src/scss/_components/homepage/_solution.scss */
.solution .swiper-slide {
  position: relative;
  height: auto;
}

/* line 632, src/scss/_components/homepage/_solution.scss */
.solution .swiper-slide > div {
  width: 100%;
}

/* line 638, src/scss/_components/homepage/_solution.scss */
.solution .swiper-slide > div:nth-of-type(1) h4 {
  padding-bottom: 20px;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  color: #121E31;
}

@media screen and (max-width: 1279px) {
  /* line 638, src/scss/_components/homepage/_solution.scss */
  .solution .swiper-slide > div:nth-of-type(1) h4 {
    font-size: 26px;
    line-height: 42px;
  }
}

@media screen and (max-width: 767px) {
  /* line 638, src/scss/_components/homepage/_solution.scss */
  .solution .swiper-slide > div:nth-of-type(1) h4 {
    padding-bottom: 10px;
    font-size: 20px;
    line-height: 30px;
  }
}

/* line 655, src/scss/_components/homepage/_solution.scss */
.solution .swiper-slide > div:nth-of-type(1) p {
  padding-bottom: 64px;
  font-size: 15px;
  line-height: 24px;
  color: #636B73;
}

@media screen and (max-width: 1279px) {
  /* line 655, src/scss/_components/homepage/_solution.scss */
  .solution .swiper-slide > div:nth-of-type(1) p {
    font-size: 14px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 655, src/scss/_components/homepage/_solution.scss */
  .solution .swiper-slide > div:nth-of-type(1) p {
    padding-bottom: 32px;
  }
}

@media screen and (max-width: 767px) {
  /* line 655, src/scss/_components/homepage/_solution.scss */
  .solution .swiper-slide > div:nth-of-type(1) p {
    padding-bottom: 20px;
    font-size: 13px;
    line-height: 20px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 673, src/scss/_components/homepage/_solution.scss */
  .solution .swiper-slide > div:nth-of-type(1) .c-btn {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  /* line 673, src/scss/_components/homepage/_solution.scss */
  .solution .swiper-slide > div:nth-of-type(1) .c-btn {
    height: 45px;
  }
}

/* line 681, src/scss/_components/homepage/_solution.scss */
.solution .swiper-slide > div:nth-of-type(1) .c-btn:not(:last-of-type) {
  margin-right: 10px;
}

@media screen and (max-width: 1023px) {
  /* line 681, src/scss/_components/homepage/_solution.scss */
  .solution .swiper-slide > div:nth-of-type(1) .c-btn:not(:last-of-type) {
    margin-right: 0;
    margin-bottom: 8px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 689, src/scss/_components/homepage/_solution.scss */
  .solution .swiper-slide > div:nth-of-type(1) .c-btn__wrapper {
    flex-direction: column;
  }
}

/* line 3, src/scss/_components/homepage/_approach.scss */
.approach {
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 30px;
}

@media screen and (max-width: 767px) {
  /* line 3, src/scss/_components/homepage/_approach.scss */
  .approach {
    padding-top: 80px;
    padding-bottom: 0;
  }
}

/* line 16, src/scss/_components/homepage/_approach.scss */
.approach__title {
  padding-bottom: 70px;
}

@media screen and (max-width: 767px) {
  /* line 16, src/scss/_components/homepage/_approach.scss */
  .approach__title {
    padding-bottom: 40px;
  }
}

/* line 23, src/scss/_components/homepage/_approach.scss */
.approach__subtitle {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  border-radius: 6px;
  background-color: rgba(64, 151, 217, 0.05);
}

@media screen and (max-width: 1023px) {
  /* line 23, src/scss/_components/homepage/_approach.scss */
  .approach__subtitle {
    min-height: 75px;
  }
}

@media screen and (max-width: 767px) {
  /* line 23, src/scss/_components/homepage/_approach.scss */
  .approach__subtitle {
    min-height: unset;
    padding: 20px 35px;
  }
}

/* line 38, src/scss/_components/homepage/_approach.scss */
.approach__subtitle p {
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #4097D9;
}

@media screen and (max-width: 1023px) {
  /* line 38, src/scss/_components/homepage/_approach.scss */
  .approach__subtitle p {
    font-size: 16px;
    line-height: normal;
  }
}

@media screen and (max-width: 767px) {
  /* line 38, src/scss/_components/homepage/_approach.scss */
  .approach__subtitle p {
    font-size: 14px;
    line-height: 21px;
  }
}

/* line 54, src/scss/_components/homepage/_approach.scss */
.approach__tab {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 1px;
  max-width: 100%;
  padding-left: 60px;
}

@media screen and (max-width: 1279px) {
  /* line 54, src/scss/_components/homepage/_approach.scss */
  .approach__tab {
    padding-left: 30px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 54, src/scss/_components/homepage/_approach.scss */
  .approach__tab {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 767px) {
  /* line 54, src/scss/_components/homepage/_approach.scss */
  .approach__tab {
    flex-direction: column;
  }
}

/* line 73, src/scss/_components/homepage/_approach.scss */
.approach__tab:after {
  content: '';
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  transform: scaleY(0);
  position: absolute;
  left: 0;
  bottom: -30px;
  width: 100%;
  height: 4px;
  background-color: #4097D9;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, transform 0.45s 0.3s ease-in-out;
}

/* line 91, src/scss/_components/homepage/_approach.scss */
.approach__tab:hover > div:nth-of-type(1), .approach__tab.is-active > div:nth-of-type(1) {
  background-color: rgba(64, 151, 217, 0.1);
  transition: background-color 0.3s ease-in-out;
}

/* line 95, src/scss/_components/homepage/_approach.scss */
.approach__tab:hover > div:nth-of-type(1) .icon-font, .approach__tab.is-active > div:nth-of-type(1) .icon-font {
  color: #4097d9;
  transition: color 0.3s ease-in-out;
}

/* line 101, src/scss/_components/homepage/_approach.scss */
.approach__tab:hover > div span, .approach__tab.is-active > div span {
  color: #4097d9;
  transition: color 0.3s ease-in-out;
}

/* line 106, src/scss/_components/homepage/_approach.scss */
.approach__tab:hover > div p, .approach__tab.is-active > div p {
  color: #121e31;
  transition: color 0.3s ease-in-out;
}

/* line 114, src/scss/_components/homepage/_approach.scss */
.approach__tab.is-active:after {
  opacity: 1;
  visibility: visible;
  transform: scaleY(1);
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, transform 0.45s 0.3s ease-in-out;
}

/* line 123, src/scss/_components/homepage/_approach.scss */
.approach__tab > div:nth-of-type(1) {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background-color: rgba(196, 207, 221, 0.15);
  transition: background-color 0.3s ease-in-out;
}

@media screen and (max-width: 767px) {
  /* line 123, src/scss/_components/homepage/_approach.scss */
  .approach__tab > div:nth-of-type(1) {
    width: 40px;
    height: 40px;
  }
}

/* line 137, src/scss/_components/homepage/_approach.scss */
.approach__tab > div:nth-of-type(1) .icon-font {
  color: #6E7E92;
  font-size: 15px;
  transition: color 0.3s ease-in-out;
}

@media screen and (max-width: 767px) {
  /* line 137, src/scss/_components/homepage/_approach.scss */
  .approach__tab > div:nth-of-type(1) .icon-font {
    font-size: 12px;
  }
}

/* line 145, src/scss/_components/homepage/_approach.scss */
.approach__tab > div:nth-of-type(1) .icon-font.icon-rocket {
  font-size: 22px;
}

@media screen and (max-width: 767px) {
  /* line 145, src/scss/_components/homepage/_approach.scss */
  .approach__tab > div:nth-of-type(1) .icon-font.icon-rocket {
    font-size: 18px;
  }
}

/* line 154, src/scss/_components/homepage/_approach.scss */
.approach__tab > div:nth-of-type(2) {
  flex: 1;
  min-width: 1px;
  max-width: 100%;
  padding-left: 20px;
}

@media screen and (max-width: 767px) {
  /* line 154, src/scss/_components/homepage/_approach.scss */
  .approach__tab > div:nth-of-type(2) {
    padding-left: 0;
  }
}

/* line 164, src/scss/_components/homepage/_approach.scss */
.approach__tab > div span {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 19px;
  color: #6E7E92;
  transition: color 0.3s ease-in-out;
}

@media screen and (max-width: 767px) {
  /* line 164, src/scss/_components/homepage/_approach.scss */
  .approach__tab > div span {
    display: block;
    padding-top: 20px;
    padding-bottom: 8px;
    font-size: 10px;
    text-align: center;
  }
}

/* line 179, src/scss/_components/homepage/_approach.scss */
.approach__tab > div p {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #6E7E92;
  transition: color 0.3s ease-in-out;
}

@media screen and (max-width: 1365px) {
  /* line 179, src/scss/_components/homepage/_approach.scss */
  .approach__tab > div p {
    font-size: 18px;
  }
}

@media screen and (max-width: 767px) {
  /* line 179, src/scss/_components/homepage/_approach.scss */
  .approach__tab > div p {
    text-align: center;
    font-size: 14px;
    line-height: 19px;
  }
}

/* line 197, src/scss/_components/homepage/_approach.scss */
.approach__tab-wrapper {
  display: flex;
  flex-direction: column;
}

/* line 202, src/scss/_components/homepage/_approach.scss */
.approach__tab-header {
  position: relative;
  display: flex;
  align-items: center;
  padding-bottom: 60px;
}

/* line 208, src/scss/_components/homepage/_approach.scss */
.approach__tab-header:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 31px;
  width: 100%;
  height: 2px;
  background-color: #DFF1FF;
}

/* line 219, src/scss/_components/homepage/_approach.scss */
.approach__tab-body {
  padding-bottom: 30px;
}

/* line 223, src/scss/_components/homepage/_approach.scss */
.approach__tab-footer {
  display: flex;
  flex-direction: column;
}

/* line 228, src/scss/_components/homepage/_approach.scss */
.approach__tab-content {
  display: none;
}

/* line 232, src/scss/_components/homepage/_approach.scss */
.approach__tab-section {
  display: flex;
  padding: 20px;
  border: 2px solid #DFF1FF;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(4px);
}

/* line 240, src/scss/_components/homepage/_approach.scss */
.approach__tab-section > div {
  width: 100%;
  padding: 40px;
  box-shadow: 0 4px 70px rgba(192, 218, 238, 0.5);
  border-radius: 10px;
  background-color: #FFFFFF;
}

@media screen and (max-width: 1023px) {
  /* line 240, src/scss/_components/homepage/_approach.scss */
  .approach__tab-section > div {
    padding: 20px;
  }
}

/* line 251, src/scss/_components/homepage/_approach.scss */
.approach__tab-section:not(:last-of-type) {
  margin-bottom: 38px;
}

/* line 256, src/scss/_components/homepage/_approach.scss */
.approach__tab-heading {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}

/* line 262, src/scss/_components/homepage/_approach.scss */
.approach__tab-heading > div:nth-of-type(1) {
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 1px;
  max-width: 100%;
}

/* line 270, src/scss/_components/homepage/_approach.scss */
.approach__tab-heading > div:nth-of-type(1) > div:nth-of-type(1) {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 8px;
  background-color: rgba(64, 151, 217, 0.1);
  transition: background-color 0.3s ease-in-out;
}

/* line 280, src/scss/_components/homepage/_approach.scss */
.approach__tab-heading > div:nth-of-type(1) > div:nth-of-type(1) .icon-font {
  font-size: 20px;
  color: #4097d9;
  transition: color 0.3s ease-in-out;
}

/* line 285, src/scss/_components/homepage/_approach.scss */
.approach__tab-heading > div:nth-of-type(1) > div:nth-of-type(1) .icon-font.icon-pen {
  transform: scaleX(-1);
}

/* line 290, src/scss/_components/homepage/_approach.scss */
.approach__tab-heading > div:nth-of-type(1) > div:nth-of-type(2) {
  flex: 1;
  min-width: 1px;
  max-width: 100%;
  padding-left: 24px;
}

/* line 301, src/scss/_components/homepage/_approach.scss */
.approach__tab-heading p {
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  color: #121E31;
}

@media screen and (max-width: 1365px) {
  /* line 301, src/scss/_components/homepage/_approach.scss */
  .approach__tab-heading p {
    font-size: 24px;
    line-height: 38px;
  }
}

@media screen and (max-width: 767px) {
  /* line 301, src/scss/_components/homepage/_approach.scss */
  .approach__tab-heading p {
    font-size: 18px;
    line-height: 27px;
  }
}

/* line 316, src/scss/_components/homepage/_approach.scss */
.approach__tab-heading span {
  font-size: 18px;
  line-height: 27px;
  color: #4097D9;
}

@media screen and (max-width: 1023px) {
  /* line 316, src/scss/_components/homepage/_approach.scss */
  .approach__tab-heading span {
    font-size: 16px;
  }
}

@media screen and (max-width: 767px) {
  /* line 316, src/scss/_components/homepage/_approach.scss */
  .approach__tab-heading span {
    font-size: 14px;
    line-height: 21px;
  }
}

/* line 331, src/scss/_components/homepage/_approach.scss */
.approach__tab-desc > p {
  font-size: 16px;
  line-height: 24px;
  color: #121E31;
}

@media screen and (max-width: 767px) {
  /* line 331, src/scss/_components/homepage/_approach.scss */
  .approach__tab-desc > p {
    font-size: 13px;
    line-height: 20px;
  }
}

/* line 342, src/scss/_components/homepage/_approach.scss */
.approach__tab-desc ul {
  display: flex;
  flex-direction: column;
}

/* line 346, src/scss/_components/homepage/_approach.scss */
.approach__tab-desc ul li {
  display: flex;
  align-items: center;
}

/* line 350, src/scss/_components/homepage/_approach.scss */
.approach__tab-desc ul li:not(:last-of-type) {
  padding-bottom: 15px;
}

/* line 355, src/scss/_components/homepage/_approach.scss */
.approach__tab-desc ul li > div:nth-of-type(1) {
  width: 40px;
}

/* line 358, src/scss/_components/homepage/_approach.scss */
.approach__tab-desc ul li > div:nth-of-type(1) .icon-font {
  font-size: 11px;
  color: #4097d9;
}

/* line 363, src/scss/_components/homepage/_approach.scss */
.approach__tab-desc ul li > div:nth-of-type(2) {
  flex: 1;
  min-width: 1px;
  max-width: 100%;
}

/* line 371, src/scss/_components/homepage/_approach.scss */
.approach__tab-desc ul span {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 19px;
  color: #636B73;
}

@media screen and (max-width: 767px) {
  /* line 371, src/scss/_components/homepage/_approach.scss */
  .approach__tab-desc ul span {
    font-size: 13px;
    line-height: 18px;
  }
}

/* line 385, src/scss/_components/homepage/_approach.scss */
.approach__collapse {
  display: flex;
  flex-direction: column;
}

/* line 389, src/scss/_components/homepage/_approach.scss */
.approach__collapse-head {
  cursor: pointer;
}

/* line 396, src/scss/_components/homepage/_approach.scss */
.approach__collapse-head:hover .approach__tab-heading > div:nth-of-type(1) > div:nth-of-type(1), .approach__collapse-head.is-active .approach__tab-heading > div:nth-of-type(1) > div:nth-of-type(1) {
  background-color: #4097d9;
  transition: background-color 0.3s ease-in-out;
}

/* line 400, src/scss/_components/homepage/_approach.scss */
.approach__collapse-head:hover .approach__tab-heading > div:nth-of-type(1) > div:nth-of-type(1) .icon-font, .approach__collapse-head.is-active .approach__tab-heading > div:nth-of-type(1) > div:nth-of-type(1) .icon-font {
  color: #ffffff;
  transition: color 0.3s ease-in-out;
}

/* line 410, src/scss/_components/homepage/_approach.scss */
.approach__collapse-body {
  display: none;
}

/* line 413, src/scss/_components/homepage/_approach.scss */
.approach__collapse-body > div {
  padding-top: 15px;
}

/* line 3, src/scss/_components/sign/_sign-in.scss */
.sign {
  padding-top: 235px;
  padding-bottom: 100px;
}

@media screen and (max-width: 1439px) {
  /* line 3, src/scss/_components/sign/_sign-in.scss */
  .sign {
    padding-top: 200px;
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 767px) {
  /* line 3, src/scss/_components/sign/_sign-in.scss */
  .sign {
    padding-top: 120px;
    padding-bottom: 15px;
  }
}

/* line 15, src/scss/_components/sign/_sign-in.scss */
.sign__wrapper {
  width: 100%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

/* line 22, src/scss/_components/sign/_sign-in.scss */
.sign__title {
  padding-bottom: 40px;
  font-weight: bold;
  font-size: 48px;
  line-height: 73px;
  text-align: center;
  color: #121E31;
}

@media screen and (max-width: 1439px) {
  /* line 22, src/scss/_components/sign/_sign-in.scss */
  .sign__title {
    font-size: 40px;
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  /* line 22, src/scss/_components/sign/_sign-in.scss */
  .sign__title {
    font-size: 24px;
    line-height: normal;
  }
}

/* line 38, src/scss/_components/sign/_sign-in.scss */
.sign__title.sign__title--pb0 {
  padding-bottom: 0;
}

/* line 43, src/scss/_components/sign/_sign-in.scss */
.sign__desc {
  padding-bottom: 40px;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #636B73;
}

@media screen and (max-width: 1439px) {
  /* line 43, src/scss/_components/sign/_sign-in.scss */
  .sign__desc {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  /* line 43, src/scss/_components/sign/_sign-in.scss */
  .sign__desc {
    font-size: 14px;
  }
}

/* line 57, src/scss/_components/sign/_sign-in.scss */
.sign__form {
  display: flex;
  flex-direction: column;
}

/* line 61, src/scss/_components/sign/_sign-in.scss */
.sign__form-group {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  /* line 61, src/scss/_components/sign/_sign-in.scss */
  .sign__form-group {
    flex-wrap: wrap;
  }
}

/* line 68, src/scss/_components/sign/_sign-in.scss */
.sign__form-group--action {
  align-items: center;
  padding-top: 25px;
}

/* line 73, src/scss/_components/sign/_sign-in.scss */
.sign__form-group > div {
  width: 100%;
  max-width: calc((100% - 10px) / 2);
}

@media screen and (max-width: 767px) {
  /* line 73, src/scss/_components/sign/_sign-in.scss */
  .sign__form-group > div {
    max-width: 100%;
  }
}

/* line 82, src/scss/_components/sign/_sign-in.scss */
.sign__form-field {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
}

/* line 88, src/scss/_components/sign/_sign-in.scss */
.sign__form-field--action {
  padding-top: 25px;
}

/* line 92, src/scss/_components/sign/_sign-in.scss */
.sign__form-field label {
  display: flex;
  padding-bottom: 10px;
  font-size: 14px;
  line-height: 19px;
  color: #636B73;
}

/* line 101, src/scss/_components/sign/_sign-in.scss */
.sign__form-field input {
  outline: none;
  position: relative;
  width: 100%;
  height: 57px;
  padding: 0 65px 0 30px;
  border-radius: 10px;
  border: 1px solid #CBDBE3;
  background-color: #FAFAFA;
  font-size: 14px;
  line-height: 19px;
  color: #313731;
}

/* line 88, src/scss/_utilities/_mixin.scss */
.sign__form-field input.placeholder {
  color: rgba(49, 55, 49, 0.5);
}

/* line 89, src/scss/_utilities/_mixin.scss */
.sign__form-field input:-moz-placeholder {
  color: rgba(49, 55, 49, 0.5);
}

/* line 90, src/scss/_utilities/_mixin.scss */
.sign__form-field input::-moz-placeholder {
  color: rgba(49, 55, 49, 0.5);
}

/* line 91, src/scss/_utilities/_mixin.scss */
.sign__form-field input:-ms-input-placeholder {
  color: rgba(49, 55, 49, 0.5);
}

/* line 92, src/scss/_utilities/_mixin.scss */
.sign__form-field input::-webkit-input-placeholder {
  color: rgba(49, 55, 49, 0.5);
}

/* line 120, src/scss/_components/sign/_sign-in.scss */
.sign__form-link {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-decoration: underline;
  text-align: right;
  color: #4097D9;
}

/* line 130, src/scss/_components/sign/_sign-in.scss */
.sign__form-info {
  text-align: right;
  font-size: 14px;
  line-height: 19px;
  color: #3C3C3C;
}

@media screen and (max-width: 767px) {
  /* line 130, src/scss/_components/sign/_sign-in.scss */
  .sign__form-info {
    text-align: center;
  }
}

/* line 140, src/scss/_components/sign/_sign-in.scss */
.sign__form-info a {
  margin-left: 5px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-decoration: underline;
  text-align: right;
  color: #4097D9;
}

/* line 151, src/scss/_components/sign/_sign-in.scss */
.sign__form-pass {
  z-index: 5;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 29px;
  right: 0;
  width: 65px;
  height: calc(100% - (15px + 29px));
  transition: opacity 0.3s ease-in-out;
}

/* line 163, src/scss/_components/sign/_sign-in.scss */
.sign__form-pass.is-active {
  opacity: 0.55;
  transition: opacity 0.3s ease-in-out;
}

/* line 168, src/scss/_components/sign/_sign-in.scss */
.sign__form-pass .icon-font {
  font-size: 16px;
  color: #182135;
}

/* line 174, src/scss/_components/sign/_sign-in.scss */
.sign__form-confirm {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #4097D9;
}

/* line 183, src/scss/_components/sign/_sign-in.scss */
.sign__form-back {
  margin-top: 35px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: rgba(99, 107, 115, 0.8);
}

/* line 193, src/scss/_components/sign/_sign-in.scss */
.sign__form .c-btn {
  width: 100%;
  max-width: 250px;
}

@media screen and (max-width: 767px) {
  /* line 193, src/scss/_components/sign/_sign-in.scss */
  .sign__form .c-btn {
    max-width: 100%;
  }
  /* line 199, src/scss/_components/sign/_sign-in.scss */
  .sign__form .c-btn__wrapper {
    justify-content: center;
  }
}

/* line 3, src/scss/_components/career/_career.scss */
.career {
  position: relative;
  padding-top: 0;
  padding-bottom: 50px;
}

@media screen and (max-width: 1439px) {
  /* line 3, src/scss/_components/career/_career.scss */
  .career {
    padding-top: 0;
  }
}

@media screen and (max-width: 767px) {
  /* line 3, src/scss/_components/career/_career.scss */
  .career {
    padding-top: 80px;
    padding-bottom: 0;
  }
}

/* line 15, src/scss/_components/career/_career.scss */
.career__bg {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 100%;
  height: 700px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 1439px) {
  /* line 15, src/scss/_components/career/_career.scss */
  .career__bg {
    top: 0;
    max-width: 100%;
  }
}

@media screen and (max-width: 767px) {
  /* line 15, src/scss/_components/career/_career.scss */
  .career__bg {
    top: 0;
    max-width: 100%;
    height: 360px;
  }
}

/* line 36, src/scss/_components/career/_career.scss */
.career__bg:after {
  content: '';
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(18, 30, 49, 0.5);
}

/* line 52, src/scss/_components/career/_career.scss */
.career__wrapper {
  z-index: 2;
  position: relative;
  padding-top: 143px;
}

/* line 57, src/scss/_components/career/_career.scss */
.career__wrapper-1 {
  width: 100%;
  max-width: 615px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 225px;
  padding-bottom: 137px;
}

@media screen and (max-width: 1439px) {
  /* line 57, src/scss/_components/career/_career.scss */
  .career__wrapper-1 {
    padding-top: 175px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 57, src/scss/_components/career/_career.scss */
  .career__wrapper-1 {
    padding: 125px 50px;
  }
}

@media screen and (max-width: 767px) {
  /* line 57, src/scss/_components/career/_career.scss */
  .career__wrapper-1 {
    padding: 50px 15px 38px;
  }
}

/* line 75, src/scss/_components/career/_career.scss */
.career__wrapper-2 {
  padding: 50px 60px;
  box-shadow: 0 10px 80px 0 rgba(18, 30, 49, 0.05);
  background-color: #FFFFFF;
}

@media screen and (max-width: 767px) {
  /* line 75, src/scss/_components/career/_career.scss */
  .career__wrapper-2 {
    padding: 20px;
  }
}

/* line 83, src/scss/_components/career/_career.scss */
.career__wrapper-2 p {
  font-size: 14px;
  line-height: 24px;
  color: #1D284A;
}

@media screen and (max-width: 767px) {
  /* line 83, src/scss/_components/career/_career.scss */
  .career__wrapper-2 p {
    font-size: 13px;
    line-height: 20px;
  }
}

/* line 96, src/scss/_components/career/_career.scss */
.career__title {
  padding-bottom: 30px;
  font-weight: bold;
  font-size: 72px;
  line-height: 80px;
  text-align: center;
  color: #FFFFFF;
}

@media screen and (max-width: 1439px) {
  /* line 96, src/scss/_components/career/_career.scss */
  .career__title {
    font-size: 62px;
    line-height: 70px;
  }
}

@media screen and (max-width: 1279px) {
  /* line 96, src/scss/_components/career/_career.scss */
  .career__title {
    font-size: 52px;
    line-height: 60px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 96, src/scss/_components/career/_career.scss */
  .career__title {
    font-size: 42px;
    line-height: 50px;
  }
}

@media screen and (max-width: 767px) {
  /* line 96, src/scss/_components/career/_career.scss */
  .career__title {
    padding-bottom: 10px;
    font-size: 30px;
    line-height: 38px;
  }
}

/* line 122, src/scss/_components/career/_career.scss */
.career__slogan {
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: #FFFFFF;
}

@media screen and (max-width: 1023px) {
  /* line 122, src/scss/_components/career/_career.scss */
  .career__slogan {
    font-size: 18px;
    line-height: 26px;
  }
}

@media screen and (max-width: 767px) {
  /* line 122, src/scss/_components/career/_career.scss */
  .career__slogan {
    font-size: 14px;
    line-height: 22px;
  }
}

/* line 3, src/scss/_components/career/_describe.scss */
.describe {
  padding-bottom: 100px;
}

@media screen and (max-width: 767px) {
  /* line 3, src/scss/_components/career/_describe.scss */
  .describe {
    padding-top: 50px;
    padding-bottom: 0;
  }
}

/* line 10, src/scss/_components/career/_describe.scss */
.describe__block {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  /* line 10, src/scss/_components/career/_describe.scss */
  .describe__block {
    flex-wrap: wrap;
  }
}

/* line 19, src/scss/_components/career/_describe.scss */
.describe__block--reverse .describe__block-left {
  order: 2;
  padding-right: 0;
  padding-left: 75px;
}

@media screen and (max-width: 1023px) {
  /* line 19, src/scss/_components/career/_describe.scss */
  .describe__block--reverse .describe__block-left {
    padding-left: 0;
  }
}

@media screen and (max-width: 767px) {
  /* line 19, src/scss/_components/career/_describe.scss */
  .describe__block--reverse .describe__block-left {
    order: 1;
  }
}

/* line 30, src/scss/_components/career/_describe.scss */
.describe__block--reverse .describe__block-right {
  order: 1;
  padding-left: 40px;
  padding-right: 75px;
}

@media screen and (max-width: 767px) {
  /* line 30, src/scss/_components/career/_describe.scss */
  .describe__block--reverse .describe__block-right {
    order: 2;
    padding-left: 0;
    padding-right: 0;
  }
}

/* line 43, src/scss/_components/career/_describe.scss */
.describe__block-wrapper {
  display: flex;
  flex-direction: column;
}

/* line 48, src/scss/_components/career/_describe.scss */
.describe__block-wrapper > div:not(:last-of-type) {
  padding-bottom: 76px;
}

@media screen and (max-width: 767px) {
  /* line 48, src/scss/_components/career/_describe.scss */
  .describe__block-wrapper > div:not(:last-of-type) {
    padding-bottom: 32px;
  }
}

/* line 57, src/scss/_components/career/_describe.scss */
.describe__block-left, .describe__block-right {
  width: 50%;
}

@media screen and (max-width: 767px) {
  /* line 57, src/scss/_components/career/_describe.scss */
  .describe__block-left, .describe__block-right {
    width: 100%;
  }
}

/* line 65, src/scss/_components/career/_describe.scss */
.describe__block-left {
  padding-right: 75px;
}

@media screen and (max-width: 1023px) {
  /* line 65, src/scss/_components/career/_describe.scss */
  .describe__block-left {
    padding-right: 0;
  }
}

/* line 71, src/scss/_components/career/_describe.scss */
.describe__block-right {
  padding-left: 75px;
  padding-right: 40px;
}

@media screen and (max-width: 767px) {
  /* line 71, src/scss/_components/career/_describe.scss */
  .describe__block-right {
    padding-left: 0;
    padding-right: 0;
  }
}

/* line 80, src/scss/_components/career/_describe.scss */
.describe__block-media {
  overflow: hidden;
  position: relative;
  width: 100%;
  max-width: 500px;
  height: 600px;
}

@media screen and (max-width: 1023px) {
  /* line 80, src/scss/_components/career/_describe.scss */
  .describe__block-media {
    height: 500px;
  }
}

@media screen and (max-width: 767px) {
  /* line 80, src/scss/_components/career/_describe.scss */
  .describe__block-media {
    height: 300px;
  }
}

/* line 98, src/scss/_components/career/_describe.scss */
.describe__block-title, .describe__block-desc {
  width: 100%;
  max-width: 450px;
}

@media screen and (max-width: 767px) {
  /* line 98, src/scss/_components/career/_describe.scss */
  .describe__block-title, .describe__block-desc {
    max-width: 100%;
  }
}

/* line 107, src/scss/_components/career/_describe.scss */
.describe__block-title {
  padding-bottom: 20px;
  font-weight: bold;
  font-size: 36px;
  line-height: 54px;
  color: #121E31;
}

@media screen and (max-width: 1023px) {
  /* line 107, src/scss/_components/career/_describe.scss */
  .describe__block-title {
    font-size: 30px;
    line-height: 50px;
  }
}

@media screen and (max-width: 767px) {
  /* line 107, src/scss/_components/career/_describe.scss */
  .describe__block-title {
    padding-top: 20px;
    font-size: 24px;
    line-height: 36px;
  }
}

/* line 125, src/scss/_components/career/_describe.scss */
.describe__block-desc p {
  font-size: 16px;
  line-height: 26px;
  color: #636B73;
}

@media screen and (max-width: 767px) {
  /* line 125, src/scss/_components/career/_describe.scss */
  .describe__block-desc p {
    font-size: 14px;
    line-height: 22px;
  }
}

/* line 135, src/scss/_components/career/_describe.scss */
.describe__block-desc p:not(:last-of-type) {
  padding-bottom: 20px;
}

@media screen and (max-width: 767px) {
  /* line 135, src/scss/_components/career/_describe.scss */
  .describe__block-desc p:not(:last-of-type) {
    padding-bottom: 10px;
  }
}

/* line 3, src/scss/_components/contacts/_contacts.scss */
.contacts {
  position: relative;
  padding-top: 0;
  padding-bottom: 50px;
}

@media screen and (max-width: 1439px) {
  /* line 3, src/scss/_components/contacts/_contacts.scss */
  .contacts {
    padding-top: 0;
  }
}

@media screen and (max-width: 767px) {
  /* line 3, src/scss/_components/contacts/_contacts.scss */
  .contacts {
    padding-top: 80px;
    padding-bottom: 0;
  }
}

/* line 15, src/scss/_components/contacts/_contacts.scss */
.contacts__bg {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 100%;
  height: 700px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 1439px) {
  /* line 15, src/scss/_components/contacts/_contacts.scss */
  .contacts__bg {
    top: 0;
    max-width: 100%;
  }
}

@media screen and (max-width: 767px) {
  /* line 15, src/scss/_components/contacts/_contacts.scss */
  .contacts__bg {
    top: 0;
    max-width: 100%;
    height: 360px;
  }
}

/* line 36, src/scss/_components/contacts/_contacts.scss */
.contacts__bg:after {
  content: '';
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(18, 30, 49, 0.5);
}

/* line 52, src/scss/_components/contacts/_contacts.scss */
.contacts__wrapper {
  z-index: 2;
  position: relative;
  padding-top: 143px;
}

/* line 57, src/scss/_components/contacts/_contacts.scss */
.contacts__wrapper-1 {
  width: 100%;
  max-width: 615px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 225px;
  padding-bottom: 137px;
}

@media screen and (max-width: 1439px) {
  /* line 57, src/scss/_components/contacts/_contacts.scss */
  .contacts__wrapper-1 {
    padding-top: 175px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 57, src/scss/_components/contacts/_contacts.scss */
  .contacts__wrapper-1 {
    padding: 125px 50px;
  }
}

@media screen and (max-width: 767px) {
  /* line 57, src/scss/_components/contacts/_contacts.scss */
  .contacts__wrapper-1 {
    padding: 50px 15px 38px;
  }
}

/* line 75, src/scss/_components/contacts/_contacts.scss */
.contacts__wrapper-2 {
  padding: 50px 60px;
  box-shadow: 0 10px 80px 0 rgba(18, 30, 49, 0.05);
  background-color: #FFFFFF;
}

@media screen and (max-width: 767px) {
  /* line 75, src/scss/_components/contacts/_contacts.scss */
  .contacts__wrapper-2 {
    padding: 20px;
  }
}

/* line 83, src/scss/_components/contacts/_contacts.scss */
.contacts__wrapper-2 p {
  font-size: 14px;
  line-height: 24px;
  color: #1D284A;
}

@media screen and (max-width: 767px) {
  /* line 83, src/scss/_components/contacts/_contacts.scss */
  .contacts__wrapper-2 p {
    font-size: 13px;
    line-height: 20px;
  }
}

/* line 96, src/scss/_components/contacts/_contacts.scss */
.contacts__title {
  padding-bottom: 30px;
  font-weight: bold;
  font-size: 72px;
  line-height: 80px;
  text-align: center;
  color: #FFFFFF;
}

@media screen and (max-width: 1439px) {
  /* line 96, src/scss/_components/contacts/_contacts.scss */
  .contacts__title {
    font-size: 62px;
    line-height: 70px;
  }
}

@media screen and (max-width: 1279px) {
  /* line 96, src/scss/_components/contacts/_contacts.scss */
  .contacts__title {
    font-size: 52px;
    line-height: 60px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 96, src/scss/_components/contacts/_contacts.scss */
  .contacts__title {
    font-size: 42px;
    line-height: 50px;
  }
}

@media screen and (max-width: 767px) {
  /* line 96, src/scss/_components/contacts/_contacts.scss */
  .contacts__title {
    padding-bottom: 10px;
    font-size: 30px;
    line-height: 38px;
  }
}

/* line 122, src/scss/_components/contacts/_contacts.scss */
.contacts__slogan {
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: #FFFFFF;
}

@media screen and (max-width: 1023px) {
  /* line 122, src/scss/_components/contacts/_contacts.scss */
  .contacts__slogan {
    font-size: 18px;
    line-height: 26px;
  }
}

@media screen and (max-width: 767px) {
  /* line 122, src/scss/_components/contacts/_contacts.scss */
  .contacts__slogan {
    font-size: 14px;
    line-height: 22px;
  }
}

/* line 3, src/scss/_components/contacts/_office.scss */
.office {
  padding-top: 50px;
  padding-bottom: 50px;
}

@media screen and (max-width: 1279px) {
  /* line 3, src/scss/_components/contacts/_office.scss */
  .office {
    padding-bottom: 0;
  }
}

/* line 11, src/scss/_components/contacts/_office.scss */
.office__wrapper-1 {
  padding-bottom: 80px;
}

@media screen and (max-width: 1279px) {
  /* line 11, src/scss/_components/contacts/_office.scss */
  .office__wrapper-1 {
    padding-bottom: 20px;
  }
}

/* line 19, src/scss/_components/contacts/_office.scss */
.office__title {
  padding-bottom: 55px;
  font-weight: bold;
  font-size: 36px;
  line-height: 54px;
  color: #121E31;
}

@media screen and (max-width: 1023px) {
  /* line 19, src/scss/_components/contacts/_office.scss */
  .office__title {
    font-size: 30px;
    line-height: 44px;
  }
}

@media screen and (max-width: 767px) {
  /* line 19, src/scss/_components/contacts/_office.scss */
  .office__title {
    padding-bottom: 20px;
    font-size: 24px;
    line-height: 36px;
  }
}

/* line 36, src/scss/_components/contacts/_office.scss */
.office__block {
  display: flex;
  flex-direction: column;
}

/* line 40, src/scss/_components/contacts/_office.scss */
.office__block-wrapper {
  position: relative;
  left: -40px;
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 40px);
}

/* line 47, src/scss/_components/contacts/_office.scss */
.office__block-wrapper > div {
  width: 100%;
  max-width: calc(100% / 2);
  padding-left: 40px;
  padding-bottom: 40px;
}

@media screen and (max-width: 1023px) {
  /* line 47, src/scss/_components/contacts/_office.scss */
  .office__block-wrapper > div {
    max-width: 100%;
  }
}

@media screen and (max-width: 767px) {
  /* line 47, src/scss/_components/contacts/_office.scss */
  .office__block-wrapper > div {
    padding-bottom: 30px;
  }
}

/* line 61, src/scss/_components/contacts/_office.scss */
.office__block-head {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 400px;
}

@media screen and (max-width: 1023px) {
  /* line 61, src/scss/_components/contacts/_office.scss */
  .office__block-head {
    height: 300px;
  }
}

/* line 70, src/scss/_components/contacts/_office.scss */
.office__block-head iframe,
.office__block-head img,
.office__block-head video {
  position: relative;
  display: block;
  width: 100%;
  min-width: 100%;
  height: 100%;
}

/* line 81, src/scss/_components/contacts/_office.scss */
.office__block-body {
  height: 200px;
  padding: 40px 40px 55px;
  background-color: #121e31;
}

@media screen and (max-width: 1023px) {
  /* line 81, src/scss/_components/contacts/_office.scss */
  .office__block-body {
    height: auto;
    padding: 30px;
  }
}

/* line 90, src/scss/_components/contacts/_office.scss */
.office__block-body p {
  padding-bottom: 10px;
  font-weight: bold;
  font-size: 30px;
  line-height: 45px;
  color: #FFFFFF;
}

@media screen and (max-width: 1023px) {
  /* line 90, src/scss/_components/contacts/_office.scss */
  .office__block-body p {
    font-size: 25px;
    line-height: 40px;
  }
}

@media screen and (max-width: 767px) {
  /* line 90, src/scss/_components/contacts/_office.scss */
  .office__block-body p {
    font-size: 20px;
    line-height: 30px;
  }
}

/* line 106, src/scss/_components/contacts/_office.scss */
.office__block-body span {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
}

@media screen and (max-width: 1023px) {
  /* line 106, src/scss/_components/contacts/_office.scss */
  .office__block-body span {
    font-size: 14px;
    line-height: 20px;
  }
}

@media screen and (max-width: 767px) {
  /* line 106, src/scss/_components/contacts/_office.scss */
  .office__block-body span {
    font-size: 13px;
    line-height: 22px;
  }
}

/* line 124, src/scss/_components/contacts/_office.scss */
.office__box {
  display: flex;
  height: 100%;
  padding: 20px;
  border: 2px solid #DFF1FF;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(4px);
}

/* line 133, src/scss/_components/contacts/_office.scss */
.office__box > div {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 70px 0 rgba(192, 218, 238, 0.5);
  background-color: #FFFFFF;
}

@media screen and (max-width: 767px) {
  /* line 133, src/scss/_components/contacts/_office.scss */
  .office__box > div {
    padding: 20px;
  }
}

/* line 146, src/scss/_components/contacts/_office.scss */
.office__box-wrapper {
  position: relative;
  left: -20px;
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 20px);
}

/* line 153, src/scss/_components/contacts/_office.scss */
.office__box-wrapper > div {
  width: 100%;
  max-width: calc(100% / 3);
  padding-left: 20px;
  padding-bottom: 20px;
}

@media screen and (max-width: 1023px) {
  /* line 153, src/scss/_components/contacts/_office.scss */
  .office__box-wrapper > div {
    max-width: 100%;
  }
}

/* line 164, src/scss/_components/contacts/_office.scss */
.office__box-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
  border-radius: 8px;
  background-color: rgba(64, 151, 217, 0.1);
}

/* line 175, src/scss/_components/contacts/_office.scss */
.office__box-icon .icon-font {
  color: #4097d9;
  font-size: 16px;
}

/* line 179, src/scss/_components/contacts/_office.scss */
.office__box-icon .icon-font.icon-governance {
  font-size: 22px;
}

/* line 182, src/scss/_components/contacts/_office.scss */
.office__box-icon .icon-font.icon-terminal {
  font-size: 18px;
}

/* line 188, src/scss/_components/contacts/_office.scss */
.office__box-title {
  padding-bottom: 10px;
  font-size: 18px;
  line-height: 27px;
  color: #121E31;
}

/* line 195, src/scss/_components/contacts/_office.scss */
.office__box-desc {
  padding-bottom: 30px;
  font-size: 14px;
  line-height: 22px;
  color: #636B73;
}

/* line 203, src/scss/_components/contacts/_office.scss */
.office__box .c-btn {
  width: 100%;
}

/* line 206, src/scss/_components/contacts/_office.scss */
.office__box .c-btn__wrapper {
  width: 100%;
}

/* line 3, src/scss/_components/dashboard/_dashboard.scss */
.dashboard {
  background-color: #f9f9f9;
}

/* line 6, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__wrapper {
  display: flex;
  min-height: 100vh;
  padding: 30px;
}

@media screen and (max-width: 1023px) {
  /* line 6, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__wrapper {
    flex-direction: column;
    padding: 0;
  }
}

/* line 16, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__wrapper-left {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
  min-height: 100%;
  padding: 50px;
  border-radius: 20px;
  background: linear-gradient(179.92deg, #4097D9 -23.94%, #30C4A0 53.12%, #00B94F 120.74%);
}

@media screen and (max-width: 1279px) {
  /* line 16, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__wrapper-left {
    max-width: 250px;
    padding: 30px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 16, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__wrapper-left {
    max-width: 100%;
    height: 80px;
    padding: 15px 20px;
    border-radius: 0;
  }
}

/* line 37, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__wrapper-left-top {
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
}

@media screen and (max-width: 1279px) {
  /* line 37, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__wrapper-left-top {
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 37, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__wrapper-left-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0;
  }
}

/* line 51, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__wrapper-left-top .hamburger {
  display: none;
}

@media screen and (max-width: 1023px) {
  /* line 51, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__wrapper-left-top .hamburger {
    display: flex;
  }
}

/* line 59, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__wrapper-left-middle {
  flex: 1;
  min-height: 1px;
  max-height: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

@media screen and (max-width: 1279px) {
  /* line 59, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__wrapper-left-middle {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 59, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__wrapper-left-middle {
    display: none;
  }
}

/* line 74, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__wrapper-left-bottom {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 160px;
  padding-top: 50px;
}

@media screen and (max-width: 1279px) {
  /* line 74, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__wrapper-left-bottom {
    padding-top: 30px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 74, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__wrapper-left-bottom {
    display: none;
  }
}

/* line 88, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__wrapper-left-bottom:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.2);
}

/* line 98, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__wrapper-left-bottom a {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* line 103, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__wrapper-left-bottom a .icon-font,
.dashboard__wrapper-left-bottom a span {
  color: #ffffff;
}

/* line 108, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__wrapper-left-bottom a .icon-font {
  font-size: 22px;
}

/* line 111, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__wrapper-left-bottom a .icon-font + span {
  padding-left: 10px;
}

/* line 116, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__wrapper-left-bottom a span {
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #FFFFFF;
}

/* line 127, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__wrapper-right {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 1px;
  max-width: 100%;
  min-height: 100%;
  padding-left: 30px;
}

@media screen and (max-width: 1023px) {
  /* line 127, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__wrapper-right {
    padding: 20px 15px;
  }
}

/* line 141, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__wrapper-top {
  padding-bottom: 50px;
}

@media screen and (max-width: 1023px) {
  /* line 141, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__wrapper-top {
    padding-bottom: 30px;
  }
}

/* line 148, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__wrapper-middle {
  flex: 1;
  min-height: 1px;
  max-height: 100%;
}

@media screen and (max-width: 767px) {
  /* line 148, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__wrapper-middle {
    flex: unset;
    max-height: unset;
  }
}

/* line 158, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__wrapper-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 50px;
}

@media screen and (max-width: 1023px) {
  /* line 158, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__wrapper-bottom {
    padding-top: 30px;
  }
}

/* line 169, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__header {
  display: flex;
  justify-content: space-between;
  min-height: 60px;
  padding: 10px 20px;
  box-shadow: 0 10px 80px 0 rgba(18, 30, 49, 0.03);
  border-radius: 10px;
  background: #FFFFFF;
}

@media screen and (max-width: 1023px) {
  /* line 179, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__header > div:nth-of-type(1) {
    width: 100%;
  }
  /* line 182, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__header > div:nth-of-type(2) {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  /* line 169, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__header {
    height: 50px;
    padding: 10px 15px;
  }
}

/* line 192, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__header > div {
  min-height: 100%;
}

/* line 195, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__header > div:nth-of-type(2) {
  padding-left: 20px;
}

/* line 201, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__nav {
  display: flex;
  flex-direction: column;
}

/* line 205, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__nav a {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 30px;
}

/* line 211, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__nav a:after {
  content: '';
  opacity: 0;
  visibility: hidden;
  transform: translateX(100%);
  position: absolute;
  top: -40px;
  left: 1px;
  width: 250px;
  height: 102px;
  background-image: url("../img/img-dashboard-overlay.svg");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: cover;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, transform 0.3s ease-in-out;
}

@media screen and (max-width: 1279px) {
  /* line 211, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__nav a:after {
    width: 220px;
    background-size: contain;
  }
}

/* line 233, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__nav a.is-active:after {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, transform 0.3s ease-in-out;
}

/* line 240, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__nav a.is-active .icon-font,
.dashboard__nav a.is-active span {
  color: #4097d9;
  transition: color 0.3s ease-in-out;
}

/* line 247, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__nav a:not(:last-of-type) {
  margin-bottom: 50px;
}

/* line 251, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__nav a .icon-font,
.dashboard__nav a span {
  z-index: 2;
  position: relative;
  color: #ffffff;
  transition: color 0.3s ease-in-out;
}

/* line 259, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__nav a .icon-font {
  font-size: 20px;
}

/* line 262, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__nav a .icon-font + span {
  padding-left: 10px;
}

/* line 267, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__nav a span {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
}

/* line 276, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__user {
  display: flex;
  align-items: center;
}

/* line 281, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__user > div:nth-of-type(1) {
  overflow: hidden;
  position: relative;
  width: 40px;
  height: 40px;
  border: 2px solid #FFFFFF;
  border-radius: 50%;
}

/* line 293, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__user > div:nth-of-type(2) {
  padding-left: 10px;
}

/* line 298, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__user p {
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: #121E31;
}

/* line 306, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__indicator {
  display: flex;
  min-height: 100%;
}

/* line 310, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__indicator > div {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
}

@media screen and (max-width: 767px) {
  /* line 316, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__indicator > div:nth-of-type(2), .dashboard__indicator > div:nth-of-type(3) {
    flex: 1;
    min-width: 1px;
    max-width: 100%;
  }
}

/* line 324, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__indicator > div:not(:last-of-type) {
  padding-right: 20px;
  margin-right: 20px;
  border-right: 1px solid rgba(99, 107, 115, 0.2);
}

@media screen and (max-width: 767px) {
  /* line 324, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__indicator > div:not(:last-of-type) {
    padding-right: 10px;
    margin-right: 10px;
  }
}

/* line 335, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__indicator-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 40px;
  background-color: rgba(16, 189, 106, 0.1);
  border-radius: 30px;
}

@media screen and (max-width: 767px) {
  /* line 335, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__indicator-badge {
    width: auto;
    height: 30px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 350, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__indicator-badge span {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #10BD6A;
}

/* line 360, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__indicator-project {
  display: flex;
  align-items: center;
}

/* line 364, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__indicator-project .icon-font {
  color: #121e31;
  font-size: 12px;
}

/* line 368, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__indicator-project .icon-font + p {
  padding-left: 6px;
}

/* line 373, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__indicator-project p {
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  color: #121e31;
}

@media screen and (max-width: 767px) {
  /* line 373, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__indicator-project p {
    font-size: 12px;
  }
}

/* line 383, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__indicator-project p span {
  color: #4097d9;
}

/* line 389, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__indicator-email {
  display: flex;
  align-items: center;
}

/* line 393, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__indicator-email .icon-font {
  color: #121e31;
  font-size: 12px;
}

/* line 397, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__indicator-email .icon-font + span {
  padding-left: 6px;
}

/* line 402, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__indicator-email span {
  font-size: 14px;
  line-height: 19px;
  color: #636B73;
}

@media screen and (max-width: 767px) {
  /* line 402, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__indicator-email span {
    font-size: 12px;
  }
}

/* line 414, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 202px;
  padding: 20px;
  background: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0 4px 50px 0 rgba(18, 30, 49, 0.05);
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

@media screen and (max-width: 767px) {
  /* line 414, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__box {
    height: 80px;
    padding: 10px;
  }
}

/* line 431, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__box:hover {
  background-color: rgba(64, 151, 217, 0.8);
  box-shadow: 0 4px 50px 0 rgba(18, 30, 49, 0.05);
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

/* line 437, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__box:hover > div p,
.dashboard__box:hover > div span {
  color: #ffffff;
  transition: color 0.3s ease-in-out;
}

/* line 445, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__box.dashboard__box--add {
  box-shadow: none;
  border: 1px dashed rgba(99, 107, 115, 0.5);
  background-color: transparent;
}

@media screen and (max-width: 767px) {
  /* line 445, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__box.dashboard__box--add {
    height: 57px;
  }
}

@media screen and (max-width: 767px) {
  /* line 453, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__box.dashboard__box--add > div {
    align-items: center;
    flex-direction: row;
  }
}

/* line 459, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__box.dashboard__box--add > div u {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  border-radius: 50%;
  background-color: #FFFFFF;
  text-decoration: none;
}

@media screen and (max-width: 767px) {
  /* line 459, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__box.dashboard__box--add > div u {
    width: 34px;
    height: 34px;
    margin-bottom: 0;
    margin-right: 10px;
  }
}

/* line 478, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__box.dashboard__box--add > div u .icon-font {
  font-size: 15px;
  color: #262f56;
}

@media screen and (max-width: 767px) {
  /* line 478, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__box.dashboard__box--add > div u .icon-font {
    font-size: 13px;
  }
}

/* line 487, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__box.dashboard__box--add > div p {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #33325C;
}

/* line 498, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__box > .icon-font {
  z-index: 5;
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 14px;
  color: #ffffff;
  transform: rotate(-45deg);
}

/* line 508, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__box > div {
  display: flex;
  flex-direction: column;
  text-align: center;
}

/* line 514, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__box > div p,
.dashboard__box > div span {
  transition: color 0.3s ease-in-out;
}

/* line 519, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__box > div p {
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: #33325C;
}

@media screen and (max-width: 767px) {
  /* line 519, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__box > div p {
    font-size: 14px;
    line-height: 19px;
  }
}

/* line 530, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__box > div span {
  font-size: 14px;
  line-height: 19px;
  color: #33325C;
}

@media screen and (max-width: 767px) {
  /* line 530, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__box > div span {
    font-size: 12px;
    line-height: 16px;
  }
}

/* line 541, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__box-wrapper {
  position: relative;
  left: -10px;
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 10px);
}

@media screen and (max-width: 767px) {
  /* line 541, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__box-wrapper {
    left: -8px;
    width: calc(100% + 8px);
  }
}

/* line 555, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__box-wrapper > div {
  width: 100%;
  max-width: calc(100% / 5);
  padding-left: 10px;
  padding-bottom: 10px;
}

@media screen and (max-width: 1535px) {
  /* line 555, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__box-wrapper > div {
    max-width: calc(100% / 4);
  }
}

@media screen and (max-width: 1279px) {
  /* line 555, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__box-wrapper > div {
    max-width: calc(100% / 3);
  }
}

@media screen and (max-width: 767px) {
  /* line 555, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__box-wrapper > div {
    max-width: calc(100% / 2);
    padding-left: 8px;
    padding-bottom: 8px;
  }
  /* line 571, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__box-wrapper > div:nth-of-type(1) {
    max-width: 100%;
  }
}

/* line 579, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* line 584, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__pagination a {
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #636B73;
}

@media screen and (max-width: 767px) {
  /* line 584, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__pagination a {
    width: 34px;
    height: 34px;
  }
}

/* line 603, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__pagination a:after {
  content: '';
  z-index: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180.59deg, #4097D9 -45.56%, #30C4A0 62.2%, #00B94F 156.76%), #EF2127;
}

/* line 615, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__pagination a.is-active {
  color: #ffffff;
}

/* line 618, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__pagination a.is-active:after {
  opacity: 1;
}

/* line 623, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__pagination a span {
  z-index: 2;
  position: relative;
}

/* line 634, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__btn--next .icon-font {
  transform: scaleX(-1);
}

/* line 639, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__btn .c-btn {
  min-width: 157px;
  height: 57px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
}

@media screen and (max-width: 767px) {
  /* line 639, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__btn .c-btn {
    min-width: 57px;
    max-width: 57px;
  }
}

/* line 652, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__btn.is-disabled .c-btn {
  pointer-events: none;
  background-color: transparent;
  border: 1px solid rgba(99, 107, 115, 0.3);
}

/* line 657, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__btn.is-disabled .c-btn:after, .dashboard__btn.is-disabled .c-btn:before {
  display: none;
}

/* line 662, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__btn.is-disabled .c-btn .icon-font,
.dashboard__btn.is-disabled .c-btn span {
  color: rgba(99, 107, 115, 0.3);
}

/* line 669, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__btn:after {
  content: '';
  position: absolute;
}

/* line 674, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__btn .icon-font {
  z-index: 2;
  position: relative;
  display: none;
  color: #ffffff;
}

@media screen and (max-width: 767px) {
  /* line 674, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__btn .icon-font {
    display: flex;
  }
}

/* line 684, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__btn span {
  font-weight: 600;
  font-size: 17px;
  line-height: 23px;
  text-align: center;
}

@media screen and (max-width: 767px) {
  /* line 684, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__btn span {
    display: none;
  }
}

/* line 695, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__row {
  display: none;
  flex-direction: column;
  min-height: 100%;
}

@media screen and (max-width: 767px) {
  /* line 700, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__row .c-grid {
    padding-left: 0;
    padding-right: 0;
  }
}

/* line 707, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__row-1 {
  justify-content: space-between;
}

/* line 712, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__account {
  display: flex;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  background-color: #F9F9F9;
}

@media screen and (max-width: 767px) {
  /* line 712, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__account {
    flex-direction: column;
  }
}

/* line 723, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__account > div:nth-of-type(1) {
  overflow: hidden;
  position: relative;
  width: 100px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  border: 2px solid #FFFFFF;
}

/* line 737, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__account > div:nth-of-type(2) {
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 1px;
  max-width: 100%;
  padding-left: 20px;
  margin-left: 20px;
  border-left: 1px solid #E8E8E8;
}

@media screen and (max-width: 767px) {
  /* line 737, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__account > div:nth-of-type(2) {
    padding-left: 0;
    margin-left: 0;
    border-left: none;
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #E8E8E8;
  }
}

/* line 757, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__account-wrapper {
  display: flex;
  width: 100%;
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  box-shadow: 0px 4px 50px rgba(18, 30, 49, 0.05);
  border-radius: 10px;
  background-color: #FFFFFF;
}

@media screen and (max-width: 767px) {
  /* line 757, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__account-wrapper {
    padding: 10px;
  }
}

/* line 772, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__account-details {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

/* line 778, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__account-details > div {
  width: 100%;
  max-width: calc(100% / 3);
}

@media screen and (max-width: 767px) {
  /* line 778, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__account-details > div {
    max-width: calc(100% / 2);
  }
  /* line 784, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__account-details > div:not(:nth-last-of-type(1)):not(:nth-last-of-type(2)) {
    padding-bottom: 20px;
  }
}

/* line 789, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__account-details > div:nth-of-type(1), .dashboard__account-details > div:nth-of-type(2), .dashboard__account-details > div:nth-of-type(3) {
  padding-bottom: 20px;
}

/* line 796, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__account-details span {
  display: block;
  padding-bottom: 5px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: #636B73;
}

/* line 806, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__account-details p {
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #33325C;
}

/* line 815, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__switch {
  width: 100%;
  max-width: 720px;
  padding: 40px 50px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 4px 50px 0 rgba(18, 30, 49, 0.05);
  border-radius: 10px;
  background-color: #FFFFFF;
}

@media screen and (max-width: 767px) {
  /* line 815, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__switch {
    padding: 30px 20px;
  }
}

/* line 829, src/scss/_components/dashboard/_dashboard.scss */
.dashboard__switch-title {
  padding-bottom: 45px;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  color: #121E31;
}

@media screen and (max-width: 767px) {
  /* line 829, src/scss/_components/dashboard/_dashboard.scss */
  .dashboard__switch-title {
    padding-bottom: 20px;
    font-size: 20px;
  }
}

/* line 3, src/scss/_components/404/_404.scss */
.nfound {
  position: relative;
}

/* line 7, src/scss/_components/404/_404.scss */
.nfound__wrapper-1 {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 30px 50px;
}

@media screen and (max-width: 1279px) {
  /* line 7, src/scss/_components/404/_404.scss */
  .nfound__wrapper-1 {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 767px) {
  /* line 7, src/scss/_components/404/_404.scss */
  .nfound__wrapper-1 {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

/* line 26, src/scss/_components/404/_404.scss */
.nfound__wrapper-2 {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: calc(1440px - 260px);
  min-height: 100vh;
  padding: 140px 30px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 767px) {
  /* line 26, src/scss/_components/404/_404.scss */
  .nfound__wrapper-2 {
    padding-top: 124px;
    padding-bottom: 124px;
  }
}

/* line 40, src/scss/_components/404/_404.scss */
.nfound__wrapper-2 > div {
  width: 100%;
}

/* line 46, src/scss/_components/404/_404.scss */
.nfound__link {
  display: flex;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  color: #121E31;
}

/* line 53, src/scss/_components/404/_404.scss */
.nfound__link:hover {
  text-decoration: underline;
}

/* line 58, src/scss/_components/404/_404.scss */
.nfound__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  /* line 58, src/scss/_components/404/_404.scss */
  .nfound__content {
    flex-direction: column-reverse;
  }
}

/* line 67, src/scss/_components/404/_404.scss */
.nfound__content > div:nth-of-type(1) {
  width: 100%;
  max-width: 365px;
}

@media screen and (max-width: 767px) {
  /* line 67, src/scss/_components/404/_404.scss */
  .nfound__content > div:nth-of-type(1) {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

/* line 76, src/scss/_components/404/_404.scss */
.nfound__content > div:nth-of-type(2) {
  padding-left: 50px;
}

@media screen and (max-width: 767px) {
  /* line 76, src/scss/_components/404/_404.scss */
  .nfound__content > div:nth-of-type(2) {
    padding-left: 0;
    padding-bottom: 10px;
  }
}

/* line 83, src/scss/_components/404/_404.scss */
.nfound__content > div:nth-of-type(2) img {
  display: block;
  max-width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

/* line 94, src/scss/_components/404/_404.scss */
.nfound__title {
  padding-bottom: 20px;
  font-weight: bold;
  font-size: 72px;
  line-height: 82px;
  color: #121E31;
}

@media screen and (max-width: 1535px) {
  /* line 94, src/scss/_components/404/_404.scss */
  .nfound__title {
    font-size: 64px;
    line-height: 78px;
  }
}

@media screen and (max-width: 1365px) {
  /* line 94, src/scss/_components/404/_404.scss */
  .nfound__title {
    font-size: 55px;
    line-height: 66px;
  }
}

@media screen and (max-width: 767px) {
  /* line 94, src/scss/_components/404/_404.scss */
  .nfound__title {
    font-size: 36px;
    line-height: 47px;
    text-align: center;
  }
}

/* line 115, src/scss/_components/404/_404.scss */
.nfound__desc {
  padding-bottom: 60px;
  font-size: 16px;
  line-height: 22px;
  color: #1D284A;
}

@media screen and (max-width: 767px) {
  /* line 115, src/scss/_components/404/_404.scss */
  .nfound__desc {
    padding-bottom: 40px;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
  }
}

/* line 129, src/scss/_components/404/_404.scss */
.nfound__btn {
  display: flex;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #4097D9;
}

@media screen and (max-width: 767px) {
  /* line 129, src/scss/_components/404/_404.scss */
  .nfound__btn {
    justify-content: center;
    text-align: center;
    font-size: 14px;
    line-height: 19px;
  }
}

/* line 143, src/scss/_components/404/_404.scss */
.nfound__btn:hover {
  text-decoration: underline;
}
