// SECTION :: START

.solution {
	//z-index: 999;
	position: relative;
	//display: flex;
	//align-items: center;
	//min-height: 1636px;
	padding-top: 100px;
	padding-bottom: 100px;
	background-color: #0a0a0a;
	@include adaptive(767) {
		padding-top: 80px;
		padding-bottom: 50px;
	}

	&__title {
		padding-bottom: 70px;
		color: #fff;
		@include adaptive(767) {
			padding-bottom: 40px;
		}
	}

	&__tab {
		position: relative;
		display: flex;
		align-items: center;
		flex: 1;
		min-width: 1px;
		max-width: 100%;
		padding-left: 60px;
		@include adaptive(1279) {
			padding-left: 30px;
		}
		@include adaptive(1023) {
			padding-left: 15px;
			padding-right: 15px;
		}
		@include adaptive(767) {
			flex-direction: column;
		}

		&:after {
			content: '';
			z-index: 5;
			opacity: 0;
			visibility: hidden;
			transform: scaleY(0);
			position: absolute;
			left: 0;
			bottom: -30px;
			width: 100%;
			height: 4px;
			background-color: #00B94F;
			@include transition(opacity, visibility, transform 0.45s);
		}

		&:hover,
		&.is-active {
			> div {
				&:nth-of-type(1) {
					background-color: rgba(#00B94F, 0.1);
					@include transition(background-color);

					.icon-font {
						color: #00B94F;
						@include transition(color);
					}
				}

				span {
					color: #00B94F;
					@include transition(color);
				}

				p {
					color: #fff;
					@include transition(color);
				}
			}
		}

		&.is-active {
			&:after {
				opacity: 1;
				visibility: visible;
				transform: scaleY(1);
				@include transition(opacity, visibility, transform 0.45s);
			}
		}

		> div {
			&:nth-of-type(1) {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 50px;
				height: 50px;
				border-radius: 10px;
				background-color: rgba(#C4CFDD, 0.15);
				@include transition(background-color);
				@include adaptive(767) {
					width: 40px;
					height: 40px;
				}

				.icon-font {
					color: rgba(#fff, 0.5);
					font-size: 15px;
					@include transition(color);
					@include adaptive(767) {
						font-size: 12px;
					}

					&.icon-rocket {
						font-size: 22px;
						@include adaptive(767) {
							font-size: 18px;
						}
					}
				}
			}

			&:nth-of-type(2) {
				flex: 1;
				min-width: 1px;
				max-width: 100%;
				padding-left: 20px;
				@include adaptive(767) {
					padding-left: 0;
				}
			}

			span {
				font-family: 'Open Sans', sans-serif;
				font-size: 14px;
				line-height: 19px;
				color: rgba(#fff, 0.5);
				@include transition(color);
				@include adaptive(767) {
					display: block;
					padding-top: 20px;
					padding-bottom: 6px;
					text-align: center;
					font-size: 10px;
				}
			}

			p {
				font-family: 'Open Sans', sans-serif;
				font-weight: 600;
				font-size: 20px;
				line-height: 27px;
				color: rgba(#fff, 0.5);
				@include transition(color);
				@include adaptive(1365) {
					font-size: 18px;
				}
				@include adaptive(767) {
					font-size: 14px;
					line-height: 19px;
					text-align: center;
				}
			}
		}

		&-wrapper {
			display: flex;
			flex-direction: column;
		}

		&-header {
			position: relative;
			display: flex;
			align-items: center;
			padding-bottom: 60px;

			&:after {
				content: '';
				position: absolute;
				left: 0;
				bottom: 31px;
				width: 100%;
				height: 2px;
				background-color: rgba(#fff, 0.2);
			}
		}

		&-body {
			padding-bottom: 30px;
			@include adaptive(767) {
				padding-bottom: 0;
			}
		}

		&-content {
			display: none;
		}

		&-desc {
			display: flex;
			justify-content: space-between;
			padding-bottom: 60px;
			@include adaptive(767) {
				flex-wrap: wrap;
				padding-bottom: 20px;
			}

			> div {
				width: 100%;
				max-width: calc((100% - 40px) / 2);
				@include adaptive(767) {
					max-width: 100%;
				}
			}

			p {
				font-family: 'Open Sans', sans-serif;
				font-weight: 600;
				font-size: 15px;
				line-height: 26px;
				color: #FFFFFF;
				@include adaptive(767) {
					font-size: 14px;
					line-height: 24px;
				}
			}
		}
	}

	&__list {
		display: flex;
		flex-direction: column;

		li {
			display: flex;
			align-items: center;

			&:not(:last-of-type) {
				padding-bottom: 15px;
			}

			> div {
				&:nth-of-type(1) {
					width: 40px;

					.icon-font {
						font-size: 10px;
						color: #00B94F;
					}
				}
				&:nth-of-type(2) {
					flex: 1;
					min-width: 1px;
					max-width: 100%;
				}
			}
		}

		p {
			font-family: 'Open Sans', sans-serif;
			font-size: 16px;
			line-height: 22px;
			color: #fff;
			@include adaptive(767) {
				font-size: 14px;
				line-height: 19px;
			}
		}

		&-wrapper {
			position: relative;
			display: flex;
			padding: 40px 30px;
			background-color: rgba(255, 255, 255, 0.04);
			margin-bottom: 50px;
			@include adaptive(1023) {
				padding: 30px 20px;
			}
			@include adaptive(767) {
				left: -15px;
				flex-wrap: wrap;
				width: calc(100% + 30px);
				padding: 30px 15px;
			}

			> div {
				position: relative;
				width: 50%;
				@include adaptive(767) {
					width: 100%;
				}

				&:nth-of-type(1) {
					padding-right: 60px;
					@include adaptive(1023) {
						padding-right: 30px;
					}
					@include adaptive(767) {
						padding-right: 0;
						padding-bottom: 15px;
					}

					&:after {
						content: '';
						position: absolute;
						top: 30px;
						right: 0;
						width: 1px;
						height: calc(100% - 60px);
						background: rgba(#C4C4C4, 0.07);
						@include adaptive(767) {
							display: none;
						}
					}
				}
				&:nth-of-type(2) {
					padding-left: 60px;
					@include adaptive(1023) {
						padding-left: 30px;
					}
					@include adaptive(767) {
						padding-left: 0;
					}
				}
			}
		}
	}

	&__slider {
		&-wrapper {
			display: flex;
			flex-direction: column;
		}

		&-head {
			display: flex;
			align-items: center;
			justify-content: center;
			padding-bottom: 30px;
			@include adaptive(767) {
				overflow-x: auto;
				white-space: nowrap;
				position: relative;
				left: -15px;
				padding-left: 20px;
				padding-bottom: 20px;
				display: block;
				width: calc(100% + 30px);
			}

			a {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 50px;
				margin-left: 10px;
				margin-right: 10px;
				padding-left: 35px;
				padding-right: 35px;
				border-radius: 10px;
				background-color: rgba(255, 255, 255, 0.1);
				font-family: 'Open Sans', sans-serif;
				font-weight: bold;
				font-size: 14px;
				line-height: 19px;
				text-align: center;
				color: #FFFFFF;
				@include adaptive(1023) {
					padding-left: 25px;
					padding-right: 25px;
					margin-left: 5px;
					margin-right: 5px;
					font-size: 12px;
				}
				@include adaptive(767) {
					display: inline-block;
					height: 40px;
					margin-left: 4px;
					margin-right: 4px;
					padding-left: 20px;
					padding-right: 20px;
					line-height: 40px;

					&:last-of-type {
						margin-right: 11px;
					}
				}

				&.is-active {
					background-color: #00B94F;
				}
			}
		}

		&-body {
			display: flex;
			min-height: 100%;
			@include adaptive(767) {
				position: relative;
				flex-wrap: wrap;
			}

			> div {
				&:nth-of-type(1) {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 130px;
					@include adaptive(1279) {
						width: 100px;
					}
					@include adaptive(1023) {
						width: 75px;
					}
					@include adaptive(767) {
						order: 2;
						z-index: 10;
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						left: -15px;
						width: 45px;
						justify-content: flex-end;
						padding-right: 10px;
					}
				}

				&:nth-of-type(2) {
					display: flex;
					align-items: center;
					flex: 1;
					min-width: 1px;
					max-width: 100%;
					min-height: 100%;
					//padding: 60px;
					background-color: #FFFFFF;
					border-radius: 10px;
					@include adaptive(767) {
						order: 1;
						flex: 100%;
						flex-wrap: wrap;
					}

					> div {
						width: 50%;
						min-height: 100%;
						@include adaptive(767) {
							width: 100%;
							min-height: unset;
							height: auto;
						}

						&:nth-of-type(1) {
							padding: 60px 30px 60px 60px;
							@include adaptive(1279) {
								padding: 30px 15px 30px 30px;
							}
							@include adaptive(767) {
								padding: 30px 30px 0;
							}
						}
						&:nth-of-type(2) {
							padding: 60px 60px 30px 60px;
							@include adaptive(1279) {
								padding: 30px 30px 15px 30px;
							}
							@include adaptive(1023) {
								padding-left: 0;
							}
							@include adaptive(767) {
								padding: 0 30px 30px;
							}
						}
					}
				}

				&:nth-of-type(3) {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 130px;
					@include adaptive(1279) {
						width: 100px;
					}
					@include adaptive(1023) {
						width: 75px;
					}
					@include adaptive(767) {
						order: 3;
						z-index: 10;
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						right: -15px;
						width: 45px;
						justify-content: flex-start;
						padding-left: 10px;
					}
				}
			}
		}

		&-media {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
			@include adaptive(767) {
				padding-left: 50px !important;
				padding-right: 50px !important;
			}

			img {
				display: block;
				max-width: 100%;
				height: auto;
				max-height: 100%;
			}
		}

		&-footer {
			position: relative;
			transition: transform 1s;
			transform-style: preserve-3d;
		}

		&-btn {
			opacity: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 50px;
			height: 50px;
			box-shadow: 0 10px 30px 0 rgba(22, 58, 95, 0.05);
			border-radius: 50px;
			background-color: #00b94f;
			@include transition(background-color);
			@include adaptive(767) {
				width: 35px;
				height: 35px;
			}

			&.swiper-button-disabled {
				pointer-events: none;
				background-color: rgba(#FFFFFF, 0.2);
				@include transition(background-color);
				@include adaptive(767) {
					opacity: 0 !important;
				}
			}

			&--prev {
				padding-right: 4px;
			}

			&--next {
				padding-left: 4px;
				.icon-font {
					transform: scaleX(-1);
				}
			}

			.icon-font {
				color: #ffffff;
				@include adaptive(767) {
					font-size: 12px;
				}
			}
		}

		&-row {
			//z-index: 1;
			//pointer-events: none;
			//visibility: hidden;
			//transform-origin: 0 0;
			//transform-style: preserve-3d;
			//perspective: 1000px;
			//transition-property: transform;
			//transform: rotateX(0deg) rotateY(180deg) translate3d(880px, 0px, 880px);
			//backface-visibility: hidden;
			//z-index: 1;
			opacity: 0;
			visibility: hidden;
			//backface-visibility: hidden;
			//transform-style: preserve-3d;
			//transition-property: transform;
			//backface-visibility: hidden;
			//transform: translate3d(-(880 * 2)px, 0px, 0px) rotateX(0deg) rotateY(180deg);
			@include transition(opacity 0.55s, visibility);

			//& + .solution__slider-row {
			//	transform: rotateX(0deg) rotateY(90deg) translate3d(0px, 0px, 0px);
			//}

			&.is-show {
				//pointer-events: auto;
				//visibility: visible;
				//z-index: 6;
				opacity: 1;
				visibility: visible;
				//transform: rotateY(-180deg);
				//transform: translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg);
				//transition-duration: 1000ms;
				@include transition(opacity 0.55s, visibility);
				//transform: rotateX(0deg) rotateY(0deg) translate3d(0px, 0px, 0px);
			}

			&:not(:first-of-type) {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
	}

	.swiper {
		&-wrapper {
			align-items: center;
		}
		&-slide {
			position: relative;
			//display: flex;
			//align-items: center;
			height: auto;

			> div {
				width: 100%;

				&:nth-of-type(1) {
					//padding-right: 60px;

					h4 {
						padding-bottom: 20px;
						font-weight: 600;
						font-size: 30px;
						line-height: 45px;
						color: #121E31;
						@include adaptive(1279) {
							font-size: 26px;
							line-height: 42px;
						}
						@include adaptive(767) {
							padding-bottom: 10px;
							font-size: 20px;
							line-height: 30px;
						}
					}

					p {
						padding-bottom: 64px;
						font-size: 15px;
						line-height: 24px;
						color: #636B73;
						@include adaptive(1279) {
							font-size: 14px;
						}
						@include adaptive(1023) {
							padding-bottom: 32px;
						}
						@include adaptive(767) {
							padding-bottom: 20px;
							font-size: 13px;
							line-height: 20px;
						}
					}

					.c-btn {
						@include adaptive(1023) {
							width: 100%;
						}
						@include adaptive(767) {
							height: 45px;
						}

						&:not(:last-of-type) {
							margin-right: 10px;
							@include adaptive(1023) {
								margin-right: 0;
								margin-bottom: 8px;
							}
						}

						&__wrapper {
							@include adaptive(1023) {
								flex-direction: column;
							}
						}
					}
				}
			}
		}
	}
}

// SECTION :: END
