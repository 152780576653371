// SECTION :: START

.contacts {
	position: relative;
	padding-top: 0;
	padding-bottom: 50px;
	@include adaptive(1439) {
		padding-top: 0;
	}
	@include adaptive(767) {
		padding-top: 80px;
		padding-bottom: 0;
	}

	&__bg {
		z-index: 0;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		max-width: 100%;
		height: 700px;
		margin-left: auto;
		margin-right: auto;
		@include adaptive(1439) {
			top: 0;
			max-width: 100%;
		}
		@include adaptive(767) {
			top: 0;
			max-width: 100%;
			height: 360px;
		}

		&:after {
			content: '';
			z-index: 1;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(#121E31, 0.5);
		}

		img {
			@extend %imageResCoverAbs;
		}
	}

	&__wrapper {
		z-index: 2;
		position: relative;
		padding-top: 143px;

		&-1 {
			width: 100%;
			max-width: 615px;
			margin-left: auto;
			margin-right: auto;
			padding-top: 225px;
			padding-bottom: 137px;
			@include adaptive(1439) {
				padding-top: 175px;
			}
			@include adaptive(1023) {
				padding: 125px 50px;
			}
			@include adaptive(767) {
				padding: 50px 15px 38px;
			}
		}

		&-2 {
			padding: 50px 60px;
			box-shadow: 0 10px 80px 0 rgba(18, 30, 49, 0.05);
			background-color: #FFFFFF;
			@include adaptive(767) {
				padding: 20px;
			}

			p {
				@extend %fontOpenSans;
				font-size: 14px;
				line-height: 24px;
				color: #1D284A;
				@include adaptive(767) {
					font-size: 13px;
					line-height: 20px;
				}
			}
		}
	}

	&__title {
		padding-bottom: 30px;
		font-weight: bold;
		font-size: 72px;
		line-height: 80px;
		text-align: center;
		color: #FFFFFF;
		@include adaptive(1439) {
			font-size: 62px;
			line-height: 70px;
		}
		@include adaptive(1279) {
			font-size: 52px;
			line-height: 60px;
		}
		@include adaptive(1023) {
			font-size: 42px;
			line-height: 50px;
		}
		@include adaptive(767) {
			padding-bottom: 10px;
			font-size: 30px;
			line-height: 38px;
		}
	}

	&__slogan {
		@extend %fontOpenSans;
		font-weight: 600;
		font-size: 20px;
		line-height: 32px;
		text-align: center;
		color: #FFFFFF;
		@include adaptive(1023) {
			font-size: 18px;
			line-height: 26px;
		}
		@include adaptive(767) {
			font-size: 14px;
			line-height: 22px;
		}
	}
}

// SECTION :: END
